import React, { Component } from "react";
import { Row, Col, Button, Modal, Typography, Form, Tag, Input, InputNumber, Select, message, Upload, Checkbox, Spin, ColorPicker, } from 'antd';

import axios from "axios"

import SelectClientes from '../../Widgets/Inputs/SelectClientes'
import SelectProducto from "../../Widgets/Inputs/SelectProducto";
import SelectServicio from "../../Widgets/Inputs/SelectServicios";
import SelectSizes from "../../Widgets/Inputs/SelectSizes";
import { isString } from "antd/es/button";

import { renderEstatusVenta } from '../../Utils';

const { Title, Text } = Typography;
const { Option } = Select;


class ModalVentas extends Component {


    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            loadingClientInfo: false,
            disableEdit: false
        }
    }

    formRef = React.createRef();

    componentDidMount() {
        if (this.props.venta_id !== undefined) {
            this.getVenta()
        }
    }

    /**
     * @memberof ModalCategorias
     * @method getCategoria
     * @description Obtiene una Categoria de la DB
     *
     */
    getVenta = () => {
        this.setState({ loading: true })
        axios.get('/venta/' + this.props.venta_id).then(response => {
            let venta = response.data;
            console.log("venta", venta);
            //this.handleClientSelect(venta.cliente_id)
            this.formRef.current.setFieldsValue({
                ...venta,
                status: venta.status,
                cliente_id: venta.cliente_id?._id ? {
                    value: venta.cliente_id?._id,
                    label: `${venta.cliente_id?.nombre ?? '-'} ${venta?.cliente_id?.apellidos}`
                } : null,
                email: venta.cliente_id?.email,
                telefono: venta.cliente_id?.telefono,
                apellidos: venta.cliente_id?.apellidos,
                zip_code: venta.zip_code,
                customer_type: venta.customer_type,
                payment_status: venta.payment_status



            })

            if (venta.square_payment) {
                this.setState({ disableEdit: true })
            }
        }).catch(error => {
            message.error("There's been an error loading the sale")
            console.log(error)
        }).finally(() => this.setState({ loading: false }))
    }


    /**
     * @memberof ModalCategorias
     * @method onFinish
     * @description Se ejecuta al dar enter al formulario
     * 
     */
    onFinish = (values) => {

        if (this.props.venta_id) {
            this.update(values)
        } else {
            this.add(values)
        }

    }

    /**
     * @memberof ModalCategorias
     * @method add
     * @description Añade una categoria a la BD
     *
     */
    add = (values) => {
        axios.post('/ventas', {
            ...values,
        }).then(response => {
            message.success('Sale created!')
            this.props.onClose(true)
        }).catch(error => {
            message.error("There's been an error creating the sale")
            console.log(error)
        })
    }

    /**
     * @memberof ModalCategorias
     * @method update
     * @description Actualiza la información de una Categoria
     * 
     */
    update = (values) => {
        this.setState({ loading: true })
        axios.put('/ventas', {
            ...values,
            venta_id: this.props.venta_id
        }).then(response => {
            message.success('Sale updated!')
            this.props.onClose()
        }).catch(error => {
            message.error("There's been an error updating the sale")
            console.log(error)
        }).finally(() => this.setState({ loading: false }))
    }

    handleClientSelect = (client_id) => {
        console.log("client_id", client_id);
        if (client_id === 'new' || client_id == undefined || client_id == null) return
        this.setState({ loadingClientInfo: true })
        let id = client_id._id ?? client_id
        axios.get('/clientes/' + id).then(response => {
            this.formRef.current.setFieldsValue({
                apellidos: response.data.apellidos,
                email: response.data.email,
                telefono: response.data.telefono,
                zip_code: response?.data?.direcciones[0]?.codigo_postal ?? 0,
            })
        }).catch(error => {
            message.error("There's been an error loading the values")
            console.log(error)
        }).finally(() => this.setState({ loadingClientInfo: false }))
    }



    render() {

        return (
            <Form
                layout="vertical"
                name="form-categoria"
                id="form-categoria"
                ref={this.formRef}
                onFinish={this.onFinish}
                initialValues={{
                    primary_color: "#000000",
                    secondary_color: "#000000"
                }}

            >
                <Spin spinning={this.state.loading}>
                    <Row>
                        <Col xs={12}>
                            <Form.Item

                                name="status"
                                rules={[{
                                    required: true,
                                    message: "Please specify the status of the sale"
                                }]}
                            >
                                <Select options={[0, 1, 2, 3, 4, 5].map(e => ({ value: e, label: renderEstatusVenta(e, true).name }))}
                                    placeholder="Status"
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                        </Col>
                        <Col span={24}>
                            <Row justify={"center"} className="mb-1">
                                <Text>Customer Information</Text>
                            </Row>
                            <Spin spinning={this.state.loadingClientInfo}>
                                <Row gutter={0}>
                                    <Col span={24}>
                                        <Form.Item
                                            label="Name"
                                            name="cliente_id"
                                            rules={[{
                                                required: true,
                                                message: "Please select a client"
                                            }]}
                                        >
                                            <SelectClientes disabled={this.state.disableEdit} enableAdd={true} onSelect={(e) => this.handleClientSelect(e)} />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24}>
                                        <Form.Item
                                            label="Last Name"
                                            name="apellidos"
                                            rules={[{
                                                required: true,
                                                message: "Input the last name"
                                            }]}
                                        >
                                            <Input disabled={this.state.disableEdit}></Input>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24}>
                                        <Form.Item
                                            label="Email"
                                            name="email"
                                            rules={[{
                                                required: true,
                                                message: "Input an email"
                                            }]}
                                        >
                                            <Input disabled={this.state.disableEdit}></Input>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24}>
                                        <Form.Item
                                            label="Phone"
                                            name="telefono"
                                            rules={[{
                                                required: true,
                                                message: "Input a phone number"
                                            }]}
                                        >
                                            <Input disabled={this.state.disableEdit}></Input>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24}>
                                        <Form.Item
                                            label="Zip code"
                                            name="zip_code"
                                        // rules={[{
                                        //     required: true,
                                        //     message: "Input a zip code"
                                        // }]}
                                        >
                                            <Input disabled={this.state.disableEdit}></Input>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24}>
                                        <Form.Item
                                            label="Type of customer"
                                            name="customer_type"
                                            rules={[{
                                                required: true,
                                                message: "Select the type of cursomer"
                                            }]}
                                        >
                                            <Select

                                                disabled={this.state.disableEdit}
                                                options={[{
                                                    value: 1,
                                                    label: 'Customer'
                                                },
                                                {
                                                    value: 2,
                                                    label: 'Influencer'
                                                }]}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24}>
                                        <Form.Item
                                            name="payment_status"
                                            label="Payment status"
                                            rules={[{
                                                required: true,
                                                message: "Select the payment status"
                                            }]}
                                        >
                                            <Select
                                                disabled={this.state.disableEdit}
                                                placeholde="Payment Status"
                                                options={[{
                                                    value: 1,
                                                    label: 'Pending'
                                                },
                                                {
                                                    value: 2,
                                                    label: 'Paid'
                                                }]}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24}>
                                        <Form.Item
                                            name="notes"
                                            label="Notes"
                                        >
                                            <Input.TextArea row={3}/>
                                        </Form.Item>
                                    </Col>

                                </Row>
                            </Spin>


                        </Col>

                    </Row>
                </Spin>
            </Form>
        )
    }
}



export default function (props) {
    const { visible = false, onClose = () => { }, venta_id } = props

    return <Modal
        open={visible}
        onCancel={onClose}
        title={venta_id ? "Edit Sale" : "Create Sale"}
        destroyOnClose={true}
        cancelText="Cancel"
        okText="Save"
        okButtonProps={{ form: 'form-categoria', key: 'submit', htmlType: 'submit' }}
    >
        <ModalVentas {...props} />
    </Modal>

}