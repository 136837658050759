import React from 'react'
import { Route, Routes } from "react-router-dom";
import Direcciones from '../../Components/Customer/Direcciones/Direcciones'


/**
 * @export
 * @function PublicRoutes
 * @extends {Component}
 */
function RouterDirecciones(props) {

    

    return (
        <Routes>
            <Route path=""      element={<Direcciones {...props} />} />
        </Routes>
    )
}

export default RouterDirecciones;
