
import React, { Component, useState, useContext } from "react";
import { Drawer, List, message, Row, Col, Typography, Divider, Form, Checkbox, Modal } from "antd";
import axios from "axios";
import { Button } from "antd";
import { useCookies } from "react-cookie";
import { User, SetUser } from "../../../Hooks/Logged";

import CardItem from "./CartItem"
import StepPayment from "./StepPayment";
import StepResumen from "./StepResumen";
import StepLogin from "./StepLogin";
import StepAddress from "./StepAddress";
import ModalTerminosCondiciones from "./ModalTerminosCondiciones"

import '../../../Styles/Modules/cart.scss'

const { Text } = Typography;

class Cart extends Component {
    
    constructor(props) {
        super(props);
        this.state={
            loading: false
        }
    }


    /**
     *
     *
     * @memberof Cart
     */
    deleteItem = (id) => {
        this.setState({ loading: true })
        axios.delete('/carrito', {
            params: {
                venta_id: this.props.cookies.cart,
                detalle_id: id
            }
        }).then(({ data }) => {
            if (data.deleteCart) {
                this.props.setCookie("cart", null)
            }
            this.props.setCart(data)
        }).catch((error) => {
            console.log(error)
            message.error("Error delete")
        }).finally(()=>this.setState({loading: false}))
    }

    /**
     * @memberof Cart
     * @method returnTotal
     * @description retorna el total de la compra 
     */
    returnTotal = () => {
        let subTotal = this.props.cart?.subtotal ?? 0;
        let taxes = this.props.cart?.tax ?? 0;

        return (subTotal + taxes).toMoney(true)
    }

    /**
     * @memberof Cart
     * @method goToNextPage
     * @description indica a que step tiene que ir
     */
    goToNextPage = () => {
        let user = this.props.user
        let page = 0
        //si no esta logeado
        if(!user?._id){
            page = 1
        }else{
            //si esta logeado
            page = 2
            //veriicamos si tiene productos en el carrito
            let productos = false
            for(let detalle of this.props.cart?.detalles){
                if(detalle.variante_id) productos = true
            }

            if(productos) page = 2
        }
        console.log("page", page);
        this.props.next(page)
    }

    render() {
        return <>
            <List
                loading={this.state.loading}
                className="cart"
                dataSource={Array.isArray(this.props.cart?.detalles) ? this.props.cart?.detalles : []}
                locale={{ emptyText: "The cart is empty" }}
                header={
                    <>
                        <Row>
                            <Col span={15} className="">Product/Services</Col>
                            <Col span={5}  className="">Cost</Col>
                            <Col span={2} className="center ">Qty</Col>
                            <Col span={4}  className=""></Col>
                        </Row>
                    </>
                }
                footer={
                    <>
                        <Row align={"center"} >
                            <Col span={6} className="cart-resume-text">Subtotal</Col>
                            <Col span={18} className="cart-resume-amount">{(this.props.cart?.subtotal ?? 0).toMoney(true)}</Col>
                        </Row>
                        <Row align={"center"} >
                            <Col span={6} className="cart-resume-text">Taxes</Col>
                            <Col span={18} className="cart-resume-amount">{(this.props.cart?.tax ?? 0).toMoney(true)}</Col>
                        </Row>
                        <Row align={"center"} >
                            <Col span={6} className="cart-resume-text">Total</Col>
                            <Col span={18} className="cart-resume-amount">{this.returnTotal()}</Col>
                        </Row>
                    </>
                }
                renderItem={item => <List.Item>
                    <CardItem item={item} cantidad={item.cantidad} onDelete={this.deleteItem} />
                </List.Item>}
            />
            <Row>
                <Col span={24}>
                    <Form>
                        <Form.Item
                            name="remember"
                            valuePropName="checked"
                            rules={[
                                { required: true, message: 'Please, accept the terms and conditions' },
                            ]}
                        >
                            <Checkbox
                                onChange={(e)=>{this.setState({terminos_condiciones: e.target.checked})}}
                            > I have read and agree to the </Checkbox>
                            <Text
                                style={{color: "#207cff"}}
                                className="hover" 
                                underline 
                                onClick={(e)=>{
                                    e.stopPropagation()
                                    this.setState({modal_visible_terms: true})
                                }}
                            >terms and conditions</Text>
                        </Form.Item>
                    </Form>
                </Col>
                <Col span={24}>
                    <Button
                        loading={this.state.loading}
                        type="primary"
                        size="large"
                        block onClick={() => {
                            if(!this.state.terminos_condiciones){
                                Modal.warning({
                                    title: "Accept the terms and conditions",
                                    content: "Before proceeding, please read and accept our Terms and Conditions"
                                });
                            }else{

                                this.goToNextPage()
                            }


                        }}
                        disabled={ !this.props.cart?.detalles || this.props.cart?.detalles?.length === 0}
                        className="mt-2"
                    >Pay</Button>
                </Col>
            </Row>
            <ModalTerminosCondiciones
                visible={this.state.modal_visible_terms}
                onClose={()=>this.setState({modal_visible_terms: false})}
            />
        </>
    }
}



export default function DrawerCarrito(props) {
    let user = useContext(User)

    const [step, setStep] = useState(0);
    const [cookies, setCookie] = useCookies(['cart']);

    let next = (num = 1) => {
        setStep(step + num)
    }
   


    return <Drawer
        className="cart"
        open={props.open}
        onClose={props.close}
        destroyOnClose={true}
        title="Cart"
        //extra={<Button type="primary" onClick={() => next()}>Pay</Button>}
        width={600}
    >
        {step === 0 && <Cart {...props} next={next} user={user}/>}
        {step === 1 && <StepLogin {...props} next={next} />}
        {step === 2 && <StepAddress {...props} next={next} user={user}/>}
        {step === 3 && <StepPayment {...props} next={next} user={user} setCookie={setCookie}/>}

    </Drawer>

}