import React, { Component } from "react";
import { Row, Col, Button, Modal, Typography, Form, Tag, Input, InputNumber, Select, message, Upload, Checkbox, Spin, ColorPicker, Divider, } from 'antd';

import axios from "axios"
import ModalNewTerminal from "./ModalNewTerminal";
import { PlusOutlined } from "@ant-design/icons";  

const { Title, Text } = Typography;
const { Option } = Select;


class ModalVentas extends Component {


    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            loadingClientInfo: false,
            disableEdit: false,
            devices: []
        }
    }

    componentDidMount() {
        this.getDevices()
    }

    /**
     * @memberof ModalCategorias
     * @method getCategoria
     * @description Obtiene una Categoria de la DB
     *
     */
    getDevices = () => {
        this.setState({ loading: true })
        axios.get('/square/devices').then(response => {

            this.setState({ devices: response.data })
            console.log(response)

        }).catch(error => {
            message.error("There's been an error obtaining the devices")
            console.log(error)
        }).finally(() => this.setState({ loading: false }))
    }


    formRef = React.createRef();

    /**
     * @memberof ModalCategorias
     * @method onFinish
     * @description Se ejecuta al dar enter al formulario
     * 
     */
    onFinish = (values) => {
        this.setState({ loading: true })
        console.log(values)

        axios.post('/square/checkout', {
            ...values,
            venta_id: this.props.venta_id
        }).then(response => {
            message.success('Request sent to terminal')
            this.setState({ loading: false })
            this.props.onClose(true)
        }).catch(error => {
            message.error("There's been an error communicating with square")
            console.log(error)
            this.setState({ loading: false })
        })

    }

    render() {

        return (
            <Form
                layout="vertical"
                name="form-categoria"
                id="form-categoria"
                ref={this.formRef}
                onFinish={this.onFinish}


            >
                <Spin spinning={this.state.loading}>
                    <Row gutter={[12,12]}> 

                        <Col span={22}> 
                            <Form.Item
                                name="device_id"
                                rules={[{
                                    required: true,
                                    message: "Please specify the status of the sale"
                                }]}
                            >
                                <Select options={this.state.devices.map(e => ({ value: e.id, label: e?.attributes?.name }))}
                                    placeholder="Select a device"
                                />
                            </Form.Item>
                        </Col>
                        <Col span={2}> 
 
                                <Button icon={<PlusOutlined/>} onClick={()=>{this.setState({newDevice:true})}}/> 
                        </Col> 
                        <Divider /> 
                        <Col span={24}>
                            <Text italic> After submiting the sale you will have a period of 5 minutes to complete checkout on terminal, after this period the action will be canceled and you will need to re-submit</Text>
                        </Col>
                    </Row>
                </Spin>
                <ModalNewTerminal 
                    visible={this.state.newDevice} 
                    onClose={()=> { 
                        this.setState({newDevice: false}) 
                        this.getDevices() 
                    }} 
                /> 
            </Form>
        )
    }
}



export default function (props) {
    const { visible = false, onClose = () => { }, venta_id } = props

    return <Modal
        open={visible}
        onCancel={onClose}
        title={"Send sale to terminal"}
        destroyOnClose={true}
        cancelText="Cancel"
        okText="Submit"
        okButtonProps={{ form: 'form-categoria', key: 'submit', htmlType: 'submit' }}
    >
        <ModalVentas {...props} />
    </Modal>

}