
import React, { Component } from 'react'
import { Result, } from 'antd';



export default class StepFinal extends Component {
    constructor(props) {
        super(props)

    }
    onFinish = () => {
        this.props.onFinish()
    }
    render() {
        return (
            <Result
                status="success"
                title="Successfully Purchased Quotation"
                subTitle={
                    <>
                        `Order number: ${this.props.quotation.folio}`
                        <br />
                        Please click "Done" button to close the quotation.
                    </>}
            />
        )
    }
}