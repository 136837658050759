import React, { Component } from 'react';
import { Row, Col, Typography, Button, Popconfirm, Layout, Space, message, List, Card, Dropdown, Tag, Menu, Collapse, Select, Checkbox, Tooltip, Radio, Badge,ColorPicker, FloatButton } from 'antd'
import axios from 'axios';
import dayjs from 'dayjs';
import { Link, useNavigate, useLocation } from "react-router-dom";
import { DeleteOutlined, PlusOutlined, UnorderedListOutlined, MenuOutlined, CloseCircleOutlined, CheckCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { FaCashRegister } from 'react-icons/fa';
import User from '../../../Hooks/Logged'
import usePermissions from '../../../Hooks/usePermissions';
import useSearch from '../../../Hooks/useSearch';
import SquareLogo from '../../Widgets/Icons/Square';
import { renderSource, tiposServiciosVenta, getResponseError, paymentStatus, RenderSlot } from '../../Utils';

import FloatingButton from '../../Widgets/Floating Button/FloatingButton'
import { ButtonDelete, ButtonEdit, ButtonView } from '../../Widgets/Buttons';
import PageHeader from '../../Widgets/PageHeader/PageHeader';

//Modales
import ModalVentas from './ModalVentas';
import { MdOutlineLocalShipping } from "react-icons/md";
import ModalVentasDetalles from "./ModalVentasDetalles"
import { BsBox2 } from "react-icons/bs";
import '../../../Styles/Modules/Admin/clientes.scss'
import ModalTerminal from './ModalTerminal';
import DrawerProductosVenta from './DrawerProductosVenta';



const { Content } = Layout;
const { Text } = Typography
const { Option } = Select
/**
 *
 *
 * @export
 * @class Clientes
 * @extends {Component}
 * @description Vista donde se listan todos los Clientes
 */
class Clientes extends Component {



    /**
     *Creates an instance of Clientes.
     * @param {*} props
     * @memberof Clientes
     */
    constructor(props) {
        super(props)

        this.state = {
            loading: false,

            ventas: {
                data: [],
                skip: 0,
                limit: 50,
                filters: [],
                total: 0,
            },
            asesores: {
                data: [],
                page: 1,
                limit: 150,
                total: 0
            },
            panels: [0, 1, 2, 3, 4],
            seleccionados: {},
            clearCheckbox: false,
            modalCliente: false,
            search: this.props.search,
            visibleFilters: false,
            optionsVisible: false,
            first: true
        }
    }

    drawerFiltros = React.createRef()

    componentDidMount() {

        this.props.setShowSearch(true)

        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token')
        this.getVentas({ filters: this.state.filters });

        const self = this
        window.onscroll = function () {

           // console.log('Activating on scroll function',(Math.ceil(window.innerHeight + window.scrollY) >= (document.body.scrollHeight - 50) && !self.state.loading && self.state.ventas.skip < self.state.ventas.total) )
            // if (Math.ceil(window.innerHeight + window.scrollY) >= (document.body.scrollHeight - 50) && !self.state.loading && self.state.ventas.skip < self.state.ventas.total) {
            //     self.getVentas({ skip: (parseInt(self.state.ventas.skip) + parseInt(self.state.ventas.limit)) })
            // }
        }
    }

    // componentWillUnmount() {
    //     window.onscroll = null
    // }

    loadMoreItems(){
       
        console.log('getting more items...', this.state)
        if ( !this.state.loading && this.state.ventas.skip < this.state.ventas.total) {
            console.log('Calling getVentas')
            this.getVentas({ skip: (parseInt(this.state.ventas.skip) + parseInt(this.state.ventas.limit)) })
        }
    }

    
    componentDidUpdate(prevProps) {
        if (prevProps.search !== this.props.search) {
            this.getVentas({ search: this.props.search, skip: 0 })
        }
    }

    /**
    * @memberOf Clientes
    * @method getClientes
    * @description Obtiene ls lista de Clientes
    */
    getVentas = ({
        skip = this.state.ventas.skip,
        limit = this.state.ventas.limit,
        search = this.state.ventas.search,
        filters = this.state.ventas.filters,
        panels = this.state.panels,
    } = this.state.ventas, { } = this.state
    ) => {
        const params = { limit, skip }

        if (search) params.search = search
        if (filters && filters?.length > 0) params.filters = JSON.stringify(filters)
        let clearCheckbox = (skip === 0)
        console.log('sending items..', skip, limit)
        this.setState({ loading: true, panels, clearCheckbox,  })
        axios.get('/ventas', {
            params: {
                skip: this.state.first ? 0 : skip,
                limit: (this.state.first && skip > 0) ? skip : limit,
                panels,
                search: this.props.search,
                filters,
                group: "estatus",
                
            }
        })
            .then(({ data }) => {
                console.clear()
                console.log('ventas=>', data)
                this.setState({
                    ventas: {
                        ...data,
                        data: {
                            0: (skip == 0) ? (data.data[0] ?? []) : this.state.ventas.data[0].concat(data.data[0] ?? []),
                            1: (skip == 0) ? (data.data[1] ?? []) : this.state.ventas.data[1].concat(data.data[1] ?? []),
                            2: (skip == 0) ? (data.data[2] ?? []) : this.state.ventas.data[2].concat(data.data[2] ?? []),
                            3: (skip == 0) ? (data.data[3] ?? []) : this.state.ventas.data[3].concat(data.data[3] ?? []),
                            4: (skip == 0) ? (data.data[4] ?? []) : this.state.ventas.data[4].concat(data.data[4] ?? []),
                            5: (skip == 0) ? (data.data[5] ?? []) : this.state.ventas.data[4].concat(data.data[5] ?? []),
                        },
                        skip,
                        limit,
                        //filters: filters
                    },
                    first: false
                })
            })
            .catch(error => {
                console.log(error)
                message.error('Error getting information')
            })
            .finally(() => this.setState({ loading: false, clearCheckbox: false }))
    }

    /**
     *
     * @param {*} event
     * @param {*} filtro
     * @memberof Clientes
     * @description Para eliminar los filtros
     */
    deleteFilter = (event, filtro) => {
        event.preventDefault()
        try {
            let { filters } = this.state.ventas
            let { _id, name } = filtro

            filters = filters.filter(f => {
                if (f.name === name) {
                    if (f.name === 'fecha') return false
                    return (f._id !== _id)
                }
                return true
            })
            this.getClientes({ filters, skip: 0 })
        } catch (error) {
            console.log(error)
            this.setState({ visibleFilters: true })
        }
    }

    /**
     *
     *
     * @param {*} event
     * @param {*} filtro
     * @memberof Clientes
     * @description Renderizamos los filtros que estén en el arreglo.
     */
    renderFiltros = ({ filters } = this.state.ventas) => {
        return filters.map(filtro => {
            console.log(filtro)
            return <Tag
                closable
                closeIcon={<div className="point" onClick={event => this.deleteFilter(event, filtro)}></div>}
                style={{ lineHeight: '15px', paddingTop: '3px', paddingBottom: '3px' }}
            >
                <small>{filtro?.objectName?.toUpperCase().replace("_", " ")}<br></br></small>
                {filtro?.filter?.range?.join(' - ')}
                {filtro?.label || filtro?.nombre || filtro?.razon_social || filtro?.email || filtro?.folio}
            </Tag>
        });
    }


    /**
   * @memberOf Clientes
   * @method onChange
   * @description Cambia de vista de clientes de listados a agrupados
   */
    onChange = ({ target: { value } }) => {
        if (value === "list") this.props.navigate('/admin/sales/list')
    };

    render() {
        const {user} = this.props;
        const { ventas, loading } = this.state

        let estatus = [
            {
                _id: 0,
                name: 'pendiente',
                label: "Pending"
            },
            {
                _id: 1,
                name: 'recibido',
                label: "Order Received"
            },
            {
                _id: 2,
                name: 'lavado',
                label: "Cleanning in Progress"
            },
            {
                _id: 3,
                name: 'detallado',
                label: "Final Detailing Touches"
            },
            {
                _id: 4,
                name: 'empaquetado',
                label: "Order Ready"
            },
            {
                _id: 5,
                name: 'entregado',
                label: "Order Completed"
            }
        ]


        return (
            <>
                <PageHeader
                    className="admin-page-header"
                    title={"Sales"}
                    extra={<Space>
                        <Radio.Group
                            options={[
                                { label: <MenuOutlined />, value: "list" },
                                { label: <UnorderedListOutlined />, value: 'group' },
                            ]}
                            value={"group"}
                            onChange={this.onChange}
                            optionType="button"
                            buttonStyle="solid"
                        />
                    </Space>}
                />
                <Content
                    className="admin-content content-bg clientes pd-1"
                    id="clientes-layout"
                >
                    <Row gutter={[16, 16]}>
                        <Col xs={24}>
                            <Collapse
                                bordered={false}
                                className="clientes-collapse w-100"
                                activeKey={this.state.panels}
                                onChange={e => this.getVentas({ skip: 0, panels: e })}
                            >
                                {estatus.map(({ _id, name, label }) => {
                                    return <Collapse.Panel
                                        key={_id}
                                        header={label}
                                        className={`clientes-tab tab-${name}`}
                                    >
                                        <List
                                            loading={ventas.loading}
                                            className="component-list"
                                            itemLayout="horizontal"
                                            locale={{ emptyText: this.state.loading ? "Loading" : "Sales not found" }}
                                            dataSource={this.state.ventas.data[_id]}
                                            renderItem={(venta, index) => (
                                                <List.Item className="component-list-item" key={venta._id}>
                                                    <Card className="card-list">
                                                        <Row align="middle">

                                                            <Col flex="auto">
                                                                <Row className="width-100 " gutter={[0, 12]}>
                                                                    <Col xs={3} className="flex-left-column" >
                                                                        <Text strong> {venta.cliente?.nombre ?? "-"} {venta.cliente?.apellidos}</Text>
                                                                        <small> {venta.folio ?? "-"}</small>
                                                                    </Col>
                                                                    <Col xs={3} className="center" >
                                                                        <Text ellipsis >{venta?.detalles?.length ?? 0} Items </Text>
                                                                    </Col>
                                                                    <Col xs={3} className="center" >
                                                                        <Text ellipsis >{renderSource(venta.from_terminal)}</Text>
                                                                    </Col>
                                                                    <Col xs={1} className="flex-column" >
                                                                              <ColorPicker value={venta.detalles[0]?.primary_color ?? '#FFF'} disabled={true} />
                                                                        </Col>

                                                                    <Col xs={1} className="flex-column" >
                                                                        {tiposServiciosVenta(venta.servicios)}
                                                                    </Col>
                                                                    <Col xs={3} className="flex-column" >
                                                                            { (user?.rol_id?.tipo == 1)?  <Text ellipsis >{paymentStatus(venta.square_payment)}</Text> : null }
                                                                    </Col>
                                                                    <Col xs={3} className="center" >
                                                                            { (user?.rol_id?.tipo == 1)? <Text ellipsis >$ {venta.total?.toMoney(true) ?? 0}</Text>  : null }
                                                                    </Col>
                                                                    <Col xs={3} className="flex-column" >
                                                                        <Text ellipsis >{dayjs(venta.createdAt).format("MM/DD/YYYY HH:mm")}</Text>
                                                                        { venta.collection_date ? <> <small> Collection Date: {dayjs(venta?.collection_date).format('MM/DD/YYYY')} </small>
                                                                        <small> {RenderSlot(venta?.time_slot)} </small> </>: null}
                                                                    </Col>
                                                                    <Col xs={4} className="center" >
                                                                        <Space wrap>
                                                                            <Tooltip title={`This sale is ${venta.square_id ? "" : "not"} registered on square`}>
                                                                                <Badge count={venta.square_id ? <CheckCircleOutlined color='#31A078' /> : <CloseCircleOutlined color='red' />}>
                                                                                    <Button type='primary'
                                                                                        disabled={true}
                                                                                        icon={<SquareLogo size="20px" />}
                                                                                        size="small"
                                                                                    />
                                                                                </Badge>
                                                                            </Tooltip>

                                                                            <Tooltip title={`Checkout on terminal`}>

                                                                                <Button type='primary'
                                                                                    disabled={venta.square_payment == true || !this.props.canEdit }
                                                                                    icon={<FaCashRegister />}
                                                                                    size="small"
                                                                                    onClick={() => this.setState({ modal_terminal_visible: true, venta_id: venta._id })}
                                                                                />

                                                                            </Tooltip>


                                                                            {/* <Link to={`/admin/sales/detalle/${venta._id}`} >
                                                                                <Button type='primary'
                                                                                    disabled={venta.pick_and_deliver}
                                                                                    icon={<MdOutlineLocalShipping />}
                                                                                    size="small"
                                                                                />
                                                                            </Link> */}


                                                                            <Tooltip placement="topRight" title={'Add or edit items'}>
                                                                                <Button
                                                                                    size="small"
                                                                                    //disabled={venta.square_payment == true}
                                                                                    disabled = { !this.props.canEdit}
                                                                                    type='primary'
                                                                                    icon={<BsBox2 />}
                                                                                    onClick={() => this.setState({ modal_visible_drawer: true, venta_id: venta._id })}
                                                                                />
                                                                            </Tooltip>

                                                                            <ButtonView
                                                                                size="small"
                                                                                disabled={user?.rol_id?.tipo != 1} 
                                                                                onClick={() => this.setState({ modal_visible: true, venta_id: venta._id })}
                                                                            />
                                                                            <ButtonEdit
                                                                                size="small"
                                                                                disabled={!this.props.canEdit}
                                                                                onClick={() => this.setState({ modalVentas: true, venta_id: venta._id })} />
                                                                            <ButtonDelete
                                                                                size="small"
                                                                                disabled={!this.props.canDelete || venta.square_payment == true}
                                                                                onConfirm={() => {
                                                                                    console.log("is paid", venta)
                                                                                    this.setState({ loading: true })
                                                                                    axios.delete('/ventas', { params: { venta_id: venta._id } })
                                                                                        .then((response) => {
                                                                                            message.success("It was deleted correctly")
                                                                                            this.getVentas()
                                                                                        })
                                                                                        .catch((error) => {
                                                                                            message.error(getResponseError(error.response, "Delete error"));
                                                                                            this.getVentas()
                                                                                        })
                                                                                }} />

                                                                        </Space>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                    </Card>
                                                </List.Item>
                                            )}
                                        />
                                        < Button 
                                        className='btn_cargar'
                                        icon={<SearchOutlined/>}
                                        loading={this.state.loading}
                                        onClick={  ()=>this.loadMoreItems() }> Load More </Button>
                                    </Collapse.Panel>
                                })}
                            </Collapse>
                        </Col>
                    </Row>
                </Content>

                {this.props.canCreate && <FloatingButton title="New Sale" disabled={!this.props.canCreate} onClick={() => this.setState({ modalVentas: true, venta_id: undefined })} />}

                <ModalVentasDetalles
                    visible={this.state.modal_visible}
                    onClose={() => {
                        this.setState({ modal_visible: false, venta_id: undefined })
                    }}
                    venta_id={this.state.venta_id}
                />

                <ModalVentas
                    visible={this.state.modalVentas}
                    venta_id={this.state.venta_id}
                    onClose={(flag) => {
                        this.setState({ modalVentas: false, venta_id: undefined })
                        this.getVentas()
                    }}
                />

                <ModalTerminal
                    visible={this.state.modal_terminal_visible}
                    venta_id={this.state.venta_id}
                    onClose={(flag) => {
                        this.setState({ modal_terminal_visible: false, venta_id: undefined })
                        this.getVentas()
                    }}
                />

                <DrawerProductosVenta
                    visible={this.state.modal_visible_drawer}
                    venta_id={this.state.venta_id}
                    onClose={(flag) => {
                        this.setState({ modal_visible_drawer: false, venta_id: undefined })
                        this.getVentas()
                    }}
                />


            </>
        )
    }
}

export default function Vista(props) {
    const { setShow } = useSearch()

    const user = React.useContext(User)
    const permisos = usePermissions(user?.rol_id?.permisos, {
        canCreate: ["ventas", "create"],
        canEdit: ["ventas", "edit"],
        canDelete: ["ventas", "delete"],
        canViewDetails: ["ventas", "cliente-detalles"],
        canAssignAsesor: ["ventas", "assign-asesor"]
    })

    console.log("permisos", permisos)

    const navigate = useNavigate();
    const location = useLocation();


    return <Clientes {...props}
        user={user}
        {...permisos}
        setShowSearch={setShow}
        navigate={navigate}
        location={location}
    />
}