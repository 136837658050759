import React, { Component, useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  Avatar,
  Layout,
  Col,
  Row,
  Image,
  Space,
  Button,
  Card,
  Flex,
  Typography,
  Divider,
  message,
  Badge,
  Carousel,
} from "antd";

import "../../Styles/Modules/Landing.scss";
import { FacebookOutlined, InstagramOutlined } from "@ant-design/icons";
import { FaTiktok } from "react-icons/fa";
const { Title, Text } = Typography;

const Section = (props) => <section {...props}>{props.children}</section>;

export default (props) => {
  const navigate = useNavigate();

  return (
    <Section className="footer" id="footer">
      <div className="footer-container">
        <Row className=" footer-container">
          <Col xs={24} sm={24} md={8}>
            <img src="/img/logo.png" className="footer-logo" alt="sneacare" />
            <Text className="text">We Clean Because We Care</Text>
          </Col>

          <Col xs={24} sm={24} md={8}>
            <Title level={3} className="title"></Title>
            <Space direction="vertical" size={"middle"}>
              <Text
                className="text hover"
                onClick={() => navigate("/terms-conditions")}
              >
                Terms and Conditions
              </Text>
              <Text
                className="text hover"
                onClick={() => navigate("/privacy-policy")}
              >
                Privacy Policy
              </Text>
              <Text
                className="text hover"
                onClick={() => navigate("/cookie-policy")}
              >
                Cookie Policy
              </Text>
            </Space>
          </Col>

          <Col xs={24} sm={24} md={8}>
            <Title level={3} className="title">
              CONTACT US
            </Title>
            <Space direction="vertical" size={"middle"}>
              <Text className="text">   <a href="https://www.google.com/maps?ll=25.750544,-80.23398&z=9&t=m&hl=es&gl=US&mapclient=embed&q=Coral+Wy+Florida" target="_blank">Coral Way 2749 SW 22 St, Miami, FL </a> </Text>
              <Text className="text"> <a href="mailto:hello@sneacaremiami.com">hello@sneacaremiami.com </a></Text>
              <Text  className="text"> <a href="tel:+13058747711"> +1 (305) 874-7711 </a> </Text>
              <Text className="text">
                Monday - Friday : 10:00 AM - 17:00 PM
              </Text>
              <Text className="text">Saturday: 11:00 AM - 14:00 PM</Text>
            </Space>
          </Col>
          <Col xs={24} sm={24} md={24}>
            <iframe
              className="mapa"
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d229990.77623462133!2d-80.23398!3d25.750544!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88d9b8c70b9704b5%3A0x1c3ed13ed88ffb38!2sCoral%20Wy%2C%20Miami%2C%20FL%2033145!5e0!3m2!1ses!2sus!4v1704321091275!5m2!1ses!2sus"
              width="auto"
              height="auto"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </Col>
          <Col xs={24} sm={24} md={24}>
            <Space direction="horizontal" wrap style={{ marginTop: 26 }}>
              <a
                target="_blank"
                href="https://www.instagram.com/sneacaremiami/"
              >
                <InstagramOutlined style={{ fontSize: 20 }} />
              </a>
              <a target="_blank" href="https://www.tiktok.com/@sneacaremiami">
                {" "}
                <FaTiktok style={{ fontSize: 20 }} />
              </a>
              <a
                target="_blank"
                href="https://www.facebook.com/SayHelloToCleanShoes"
              >
                {" "}
                <FacebookOutlined style={{ fontSize: 20, color: "#fff" }} />
              </a>
            </Space>
          </Col>
        </Row>
      </div>
    </Section>
  );
};
