import React, { Component } from 'react';
import { Row, Col, Typography, Layout, Space, message, List, Card, Radio, Tooltip, Button, Badge,ColorPicker } from 'antd'
import axios from 'axios';
import moment from 'moment';
import { Link, useNavigate } from "react-router-dom";
import { UnorderedListOutlined, MenuOutlined, CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { MdMoney, MdOutlineLocalShipping, MdShop } from "react-icons/md";
import { FaCashRegister } from "react-icons/fa";


//componentes
import User from '../../../Hooks/Logged'
import useSearch from '../../../Hooks/useSearch';
import usePermissions from '../../../Hooks/usePermissions';
import PageHeader from '../../Widgets/PageHeader/PageHeader';
import { getResponseError, paymentStatus, renderEstatusVenta, renderSource, tiposServiciosVenta, RenderSlot } from '../../Utils';
import FloatingButton from '../../Widgets/Floating Button/FloatingButton';
import { ButtonDelete, ButtonEdit, ButtonView } from '../../Widgets/Buttons';

//Modales
import ModalVentas from "./ModalVentas"
import ModalVentasDetalles from "./ModalVentasDetalles"
import DrawerProductosVenta from './DrawerProductosVenta';

import { BsBox2 } from "react-icons/bs";
import SquareLogo from '../../Widgets/Icons/Square';
import ModalTerminal from './ModalTerminal';


const { Content } = Layout;
const { Text } = Typography


/**
 *
 *
 * @export
 * @class VentasList
 * @extends {Component}
 * @description Vista donde se listan todas las ventas
 */
class VentasList extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,

            ventas: {
                data: [],
                page: 1,
                limit: 20,
                filters: [],
                total: 0,
            },
        }
    }

    drawerFiltros = React.createRef()

    componentDidMount() {
        this.props.setShowSearch(true)
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token')
        this.getVentas()
    }


    /**
   * @memberOf Clientes
   * @method getClientes
   * @description Obtiene ls lista de Clientes
   */
    getVentas = ({
        page = this.state.ventas.page,
        limit = this.state.ventas.limit,
        search = this.state.ventas.search
    } = this.state.ventas) => {

        this.setState({loading: true})
        axios.get('/ventas', {
            params: {
                page: page,
                limit: limit,
                search: this.props.search
            }
        })
            .then(({ data }) => {
                console.log('ventas=>', data)
                this.setState({ ventas: data })
            })
            .catch(error => {
                console.log(error)
                message.error(error?.response?.data?.message ?? "Error when obtaining the information")
            })
            .finally(() => this.setState({ loading: false }))
    }

    /**
    * @memberOf Clientes
    * @method onChange
    * @description Cambia de vista de clientes de listados a agrupados
    */
    onChange = ({ target: { value } }) => {
        this.props.navigate('/admin/sales')
    };

    render() {
        const { ventas, loading } = this.state
        return (
            <>
                <PageHeader
                    className="admin-page-header"
                    title={<>Sales</>}
                    extra={<Space>
                        <Radio.Group
                            options={[
                                { label: <MenuOutlined />, value: "list" },
                                { label: <UnorderedListOutlined />, value: 'group' },
                            ]}
                            value={"list"}
                            onChange={this.onChange}
                            optionType="button"
                            buttonStyle="solid"
                        />
                    </Space>}
                />
                <Content className="admin-content content-bg  pd-1">
                    <Row gutter={[24, 24]}>
                        <Col xs={24} className="">
                            <List
                                loading={this.state.loading}
                                className="component-list"
                                itemLayout="horizontal"
                                locale={{ emptyText: "Sales not found" }}
                                dataSource={ventas.data}
                                pagination={{
                                    current: ventas.page,
                                    pageSize: ventas.limit,
                                    total: ventas.total,
                                    pageSizeOptions: [10, 20, 50, 100, 500, 1000],
                                    position: 'bottom',
                                    className: "flex-left ml-1",
                                    showSizeChanger: true,
                                    onChange: (page, limit) => {
                                        this.getVentas({ page, limit })
                                    }
                                }}
                                header={
                                    <Row align="middle">

                                        <Col flex="auto">
                                            <Row className="width-100 " align="middle" gutter={[0, 12]}>
                                                <Col xs={3} className="center" style={{ color: "gray" }} >
                                                    <Text ellipsis strong>Folio / Client</Text>
                                                </Col>
                                                <Col xs={3} className="center" >
                                                    <Text ellipsis strong>Items</Text>
                                                </Col>
                                                <Col xs={1} className="center" >
                                                    <Text ellipsis strong>Color Tag</Text>
                                                </Col>
                                                <Col xs={3} className="center" >
                                                    <Text ellipsis strong>Status</Text>
                                                </Col>
                                                <Col xs={3} className="center" >
                                                    <Text ellipsis strong>Source</Text>
                                                </Col>
                                                <Col xs={3} className="center" >
                                                    <Text ellipsis strong>Total</Text>
                                                </Col>
                                                <Col xs={4} className="center" >
                                                    <Text ellipsis strong>Date</Text>
                                                </Col>
                                                <Col xs={3} className="center" >
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                }

                                renderItem={item => (
                                    <List.Item className="component-list-item">
                                        <Card className="card-list">
                                            <Row align="middle">
                                                <Col flex="auto">
                                                    <Row className="width-100 " align="middle" gutter={[0, 12]}>
                                                        <Col xs={3} className="flex-left-column" >
                                                            <Text strong>{item.folio ?? "-"}</Text>
                                                            <small>{item.cliente_id?.nombre ?? "-"} {item.cliente_id?.apellidos}</small>
                                                        </Col>
                                                        <Col xs={3} className="flex-column" >
                                                            <Text ellipsis >{item.detalles?.length ?? 0} Items</Text>
                                                           
                                                            <Space>{tiposServiciosVenta(item.servicios)}</Space>
                                                        </Col>
                                                        <Col xs={1} className="flex-column" >
                                                               <ColorPicker value={item.detalles[0]?.primary_color ?? '#FFFFFF'} disabled={true} />
                                                        </Col>
                                                        <Col xs={3} className="center" >
                                                            <Text ellipsis >{renderEstatusVenta(item.status)}</Text>
                                                            <Text ellipsis >{paymentStatus(item.square_payment)}</Text>
                                                        </Col>
                                                        <Col xs={3} className="center" >
                                                            <Text ellipsis >{renderSource(item.from_terminal)}</Text>
                                                        </Col>
                                                        <Col xs={3} className="center" >
                                                            <Text ellipsis>$ {item.total?.toMoney(true)}</Text>
                                                        </Col>
                                                        <Col xs={4} className="flex-column" >
                                                            <Text ellipsis >{moment(item.createdAt).format("MM/DD/YYYY HH:mm")}</Text>
                                                            { item.collection_date ? <> <small> Collection Date: {moment(item?.collection_date).format('MM/DD/YYYY')} </small>
                                                             <small> {RenderSlot(item?.time_slot)} </small> </>: null}
                                                        </Col>
                                                        <Col xs={4} className="center" >
                                                            <Space wrap>
                                                                <Tooltip title={`This sale is ${item.square_id ? "" : "not"} registered on square`}>
                                                                    <Badge count={item.square_id ? <CheckCircleOutlined color='#31A078' /> : <CloseCircleOutlined color='red' />}>
                                                                        <Button type='primary'
                                                                            disabled={true}
                                                                            icon={<SquareLogo size="20px" />}
                                                                            size="small"
                                                                        />
                                                                    </Badge>
                                                                </Tooltip>


                                                                <Tooltip title={`Checkout on terminal`}>

                                                                    <Button type='primary'
                                                                        disabled={item.square_payment == true}
                                                                        icon={<FaCashRegister />}
                                                                        size="small"
                                                                        onClick={() => this.setState({ modal_terminal_visible: true, venta_id: item._id })}
                                                                    />

                                                                </Tooltip>

                                                                {/* <Link to={`/admin/sales/detalle/${item._id}`} >
                                                                    <Button type='primary'
                                                                        disabled={item.pick_and_deliver}
                                                                        icon={<MdOutlineLocalShipping />}
                                                                        size="small"
                                                                    />
                                                                </Link> */}


                                                                <Tooltip placement="topRight" title={'Add or edit items'}>
                                                                    <Button
                                                                        size="small"
                                                                        disabled={item.square_payment == true}
                                                                        type='primary'
                                                                        icon={<BsBox2 />}
                                                                        onClick={() => this.setState({ modal_visible_drawer: true, venta_id: item._id })}
                                                                    />
                                                                </Tooltip>

                                                                <ButtonView
                                                                    size="small"
                                                                    onClick={() => this.setState({ modal_visible: true, venta_id: item._id })}
                                                                />
                                                                <ButtonEdit
                                                                    size="small"
                                                                    disabled={!this.props.canEdit}
                                                                    onClick={() => this.setState({ modal_visible_venta: true, venta_id: item._id })} />
                                                                <ButtonDelete
                                                                    size="small"
                                                                    disabled={!this.props.canDelete || item.square_payment == true}
                                                                    onConfirm={() => {
                                                                        console.log("is paid", item)
                                                                        this.setState({ loading: true })
                                                                        axios.delete('/ventas', { params: { venta_id: item._id } })
                                                                            .then((response) => {
                                                                                message.success("It was deleted correctly")
                                                                                this.getVentas()
                                                                            })
                                                                            .catch((error) => {
                                                                                message.error(getResponseError(error.response, "Delete error"));
                                                                                this.getVentas()
                                                                            })
                                                                    }} />

                                                            </Space>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Card>
                                    </List.Item>
                                )}
                            />
                        </Col>
                    </Row>
                </Content>
                {/* </Spin> */}

                {this.props.canCreate && <FloatingButton title="New Sale" disabled={!this.props.canCreate} onClick={() => this.setState({ modal_visible_venta: true, venta_id: undefined })} />}

                <ModalVentasDetalles
                    visible={this.state.modal_visible}
                    onClose={() => {
                        this.setState({ modal_visible: false, venta_id: undefined })
                    }}
                    venta_id={this.state.venta_id}
                />

                <ModalVentas
                    visible={this.state.modal_visible_venta}
                    venta_id={this.state.venta_id}
                    onClose={(flag) => {
                        this.setState({ modal_visible_venta: false, venta_id: undefined })

                        this.getVentas()
                    }}
                />

                <ModalTerminal
                    visible={this.state.modal_terminal_visible}
                    venta_id={this.state.venta_id}
                    onClose={(flag) => {
                        this.setState({ modal_terminal_visible: false, venta_id: undefined })
                        this.getVentas()
                    }}
                />

                <DrawerProductosVenta
                    visible={this.state.modal_visible_drawer}
                    venta_id={this.state.venta_id}
                    onClose={(flag) => {
                        this.setState({ modal_visible_drawer: false, venta_id: undefined })
                        this.getVentas()
                    }}
                />
            </>
        )
    }
}



export default function (props) {
    const { setShow } = useSearch()

    const user = React.useContext(User)
    const permisos = usePermissions(user?.rol_id?.permisos, {
        canCreate: ["ventas", "create"],
        canEdit: ["ventas", "edit"],
        canDelete: ["ventas", "delete"],
    })

    const navigate = useNavigate();


    return <VentasList {...props}
        {...permisos}
        setShowSearch={setShow}
        navigate={navigate}
    />
}