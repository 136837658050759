
import React, { Component, useContext } from 'react'
import { Form, Input, Button, Row, Col, message, Spin, Typography, Modal, Radio, Space, Checkbox, DatePicker, Select } from 'antd';
import axios from "axios";

import { User, SetUser } from '../../../Hooks/Logged';
import { SetCarrito, Carrito } from '../../../Hooks/Carrito';
import ModalDirecciones from '../../Customer/Direcciones/ModalDirecciones';

const { Title, Text } = Typography;
const { Option } = Select;

class StepAddress extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            direcciones: {
                data: [],
                limit: 20,
                page: 1,
            },
            continue: false,
            pick_deliver: false,
            disabledTime: true,
        }
    }

    refForm = React.createRef();

    componentDidMount() {
        if (this.props.user.cliente_id) {
            this.getDirecciones()
        } else {
            message.error("Error")
        }

        if (this.props.carrito?._id) {
            this.getCarrito()
        }
    }

    getDirecciones = () => {
        this.setState({ loading: true })
        axios.get('/customer/direcciones', {
            params: {
                cliente_id: this.props.user.cliente_id
            }
        })
            .then(({ data }) => {
                this.setState({
                    direcciones: {
                        ...data,
                    }
                })
            })
            .catch(res => {
                console.log("res", res);
            })
            .finally(() => this.setState({ loading: false }))
    }

    /**
     * @method getCarrito
     * @description  Envia los datos del formulario al Servidor
     **/
    getCarrito = (values) => {
        axios.get('/carrito/' + this.props.carrito?._id)
            .then(({ data }) => {
                console.log('carrito HEADER', data)
                this.setState({ carrito: data })
                this.getZipCodes()
            }).catch((error) => {
                console.log(error)
            })
    }

    /**
     * @memberof StepDireccion
     * @method selectAddress
     * @description Al seleccionar una direccion previa o nueva se actualiza el props y el formulario
     */
    selectAddress = ({ target }) => {

        if (target.value && this.props.carrito?._id) {

            let direccion = this.state.direcciones?.data?.find(e => e._id.toString() === target.value)
            if (direccion) {
                let zip_code = this.state.zipCodes?.find((e) => e === direccion.codigo_postal)
                if (zip_code) {
                    this.setState({ zip_code, continue: true })
                } else {
                    this.refForm.current.setFieldsValue({ pick_and_deliver: null })
                    this.setState({ zip_code: undefined, continue: true })
                }

            } else {
                this.refForm.current.setFieldsValue({ pick_and_deliver: null })
                this.setState({ zip_code: undefined, continue: true })
            }

        } else {
            message.error("Address no valid")
        }
    }

    /**
     * @memberof StepDireccion
     * @method getZipCodes
     * @description Obtiene las direcciones del cliente
     */
    getZipCodes = () => {
        axios.get('/zip-codes')
            .then(({ data }) => {
                console.log("data", data);
                let zipCodes = data.one_pair;

                //el carrito tiene servicios?
                let detallesConServicioId = this.state.carrito?.detalles.filter(e => e.servicio_id?.tipo === 0);
                let servicios_total = 0

                for (let servicio of detallesConServicioId) {
                    servicios_total = servicios_total + servicio.cantidad
                }

                if(servicios_total > 1) zipCodes = data.two_pair

                this.setState({ zipCodes: zipCodes, zipCodesTwo: data.two_pair,  num_servicios: servicios_total })
            })
            .catch(res => {
                console.log("res", res);
            })
    }

    renderText = (codigo_postal) => {
        let zip_code = this.state.zipCodes?.find((e) => e === codigo_postal)
        let amount = "$12.00";
        let add = "You are missing 2 pairs for free collection"
        if(this.state.num_servicios == 2){
            add = "Add another pair for free collection"
        }

        if (this.state.num_servicios > 2) {
            amount = "FREE"
        }

        if (zip_code) return <div className="flex-column-left" style={{width: "475px"}}> 
                <Text strong underline italic className="text-right"> Pick Up & Delivery available for {amount} </Text> <br/>
                {this.state.num_servicios < 3 ? <small>{add}</small> : null}
            </div>

        zip_code = this.state.zipCodesTwo?.find((e) => e === codigo_postal)

        if(zip_code && this.state.num_servicios == 1){
            return <div className="flex-column-left" style={{width: "485px"}}> 
                <Text strong underline italic className="text-right"> Add another pair for Pick Up & Delivery</Text> <br/>
            </div>
        }
    }

    /**
     * @memberof StepDireccion
     * @method onFinish
     * @description Actualiza la venta con la direccion seleccionada
     */
    onFinish = (values) => {
        this.setState({ loading: true })
        axios.put('/customer/venta', {
            ...values,
            venta_id: this.props.carrito?._id
        }).then(response => {
            console.log("response", response.data);
            this.props.next()
        }).catch(error => {
            message.error(error?.response?.data?.message ?? "Error updating sale")
        }).finally(() => { this.setState({ loading: false }) })
    }

    /**
     * @memberof StepDireccion
     * @method disabledDates
     * @description desabilita los dias anteriores a hoy y los domingos
     */
    disabledDates = current => {
        const hoy = new Date();
        hoy.setHours(0, 0, 0, 0);
        return current && current < hoy || ( current.day() != 1 && current.day() != 3 && current.day() != 5) ;
    };

    /**
     * @memberof StepDireccion
     * @method onValuesChange
     * @description En caso de que el dia seleccionado se una sabado desabilita un horario
     */
    onValuesChange = (changedValues, allValues) => {

        if(allValues.collection_date){

            this.setState({disabledTime: false})

            if (changedValues?.collection_date?.day() == 6) { 
                this.setState({ isSaturday: true })  
                this.refForm.current.setFieldsValue({ time_slot: null })
            }else this.setState({ isSaturday: false })
        }else{
            this.setState({disabledTime: true})
            this.refForm.current.setFieldsValue({ time_slot: null })
        }
    }

    render() {
        return (
            <Spin spinning={this.state.loading}>
                <Form
                    layout={"vertical"}
                    ref={this.refForm}
                    onFinish={this.onFinish}
                    onValuesChange={this.onValuesChange}
                >
                    {this.state.direcciones.data.length > 0 ?
                        <Row gutter={[16, 16]} justify={'start'}>
                            <Col span={24}>
                                <Title level={5}>Choose address</Title>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    name="direccion_id"
                                >
                                    <Radio.Group
                                        name="direccion_selected"
                                        className='width-100'
                                        onChange={this.selectAddress}
                                    >
                                        {this.state.direcciones.data?.map(item => {
                                            return <Col span={24} className='width-100 radio-group-address'>
                                                <Radio value={item._id} rootClassName='radio-card-item width-100'>
                                                    <div className="flex-left-column">
                                                        <Text ellipsis>{item.localizacion?.place_name}</Text>
                                                        <div className="width-100 flex-between">
                                                            <Text strong className="text-left">Zip Code: {item.codigo_postal}</Text>
                                                            {this.renderText(item.codigo_postal)}
                                                        </div>
                                                    </div>
                                                </Radio>
                                            </Col>
                                        })}
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                            {this.state.num_servicios > 0 && this.state.zip_code ? <Col span={22} className="flex-left">
                                <Form.Item
                                    name="pick_and_deliver"
                                    valuePropName='checked'
                                    className="m-0"
                                >
                                    <Checkbox onChange={(check) => {
                                        this.setState({pick_deliver: check?.target?.checked})
                                    }}>
                                        <Text>By Selecting This Box You Agree To Use The Pick Up& Delivery Service (Otherwise You Will Have To Drop Off Your Shoes Directly At Our Store)</Text>
                                    </Checkbox>
                                </Form.Item>
                            </Col> : null}
                        </Row >
                        : null
                    }
                    { this.state.pick_deliver ? <> 
                        <Form.Item
                            className="mt-2"
                            name="collection_date"
                            label="Collection Date"
                            rules={[
                                {
                                    required: true,
                                    message: 'Date to recollect is required',
                                },
                            ]}
                        >
                            <DatePicker className='width-100' disabledDate={this.disabledDates}/>
                        </Form.Item>

                        <Form.Item
                            name="time_slot"
                            label="Time Slot"
                            rules={[
                                {
                                    required: true,
                                    message: 'Select the time to slot  is required',
                                },
                            ]}
                        >
                            <Select disabled={this.state.disabledTime}>
                                <Option value={1}>9:00 am - 11:00 am</Option>
                                <Option value={2}>11:00 am - 2:00 pm</Option>
                                <Option value={3}>3:00 pm - 7:00 pm</Option>
                                {/* {!this.state.isSaturday ? <Option value={2}>03:00 pm-6:00 pm</Option>: null} */}
                            </Select>
                        </Form.Item>
                    </> : null }
                </Form>

                <Row justifiy="center" className="mt-1">
                    <Col span={24}>
                        <Button
                            //type="secondary" 
                            block
                            onClick={() => this.setState({ modal_visible: true })}
                        >Add Address</Button>
                    </Col>
                </Row>

                <Row justifiy="center" className="mt-2">
                    <Col span={24}>
                        <Button
                            loading={this.state.loading}
                            disabled={!this.state.continue}
                            type="primary"
                            block
                            onClick={() => {
                                if (this.state.num_servicios > 0 && !this.state.zip_code) {
                                    Modal.warning({
                                        message: "Please read before continuing",
                                        content: <>
                                            <Row gutter={[12,12]}>
                                                <Col span={24}>
                                                    Pick up and delivery is not available in your area, please contact us to arrange shipping:
                                                </Col>
                                                <Col span={24}>
                                                <Space direction='vertical'>
                                                    <Text className='text'>Coral Way 2749 SW 22 St, Miami, FL</Text>
                                                    <Text className='text'>hello@sneacaremiami.com</Text>
                                                    <Text className='text'>+1 (305) 874-7711</Text>
                                                    <Text className='text'>Weekdays 10:00 - 17:00</Text>
                                                    <Text className='text'>Saturday 11:00 - 14:00</Text>
                                                    <Text className='text'>Sunday CLOSED</Text>
                                                </Space>
                                            </Col>
                                            </Row>
                                            
                                        </>,
                                        onOk: this.refForm.current.submit
                                    })
                                } else {
                                    this.refForm.current.submit()
                                }

                            }}
                        >Continue</Button>
                    </Col>
                </Row>
                <ModalDirecciones
                    visible={this.state.modal_visible}
                    cliente_id={this.props.user?.cliente_id}

                    onClose={() => {
                        this.setState({ modal_visible: false, direccion_id: undefined })
                        this.getDirecciones()
                    }}
                />
            </Spin>
        )
    }
}


export default function Vista(props) {
    const setUser = useContext(SetUser)
    const user = useContext(User)
    const carrito = useContext(Carrito)

    return <StepAddress {...props} setUser={setUser} user={user} carrito={carrito} />

}