import React, { Component, createRef } from 'react';
import { Row, Col, Typography, Layout, message, Breadcrumb, Space, Tag, Card, Descriptions, Button } from 'antd'
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { WarningFilled, CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';

import { CardSimpleInfo } from '../../Widgets/Cards';
import usePermissions from "../../../Hooks/usePermissions";
import PageHeader from '../../Widgets/PageHeader/PageHeader';
import EnviosConfiguracion from '../../Admin/Quotations/Envios';
import { renderEstatusVenta } from '../../Utils';
import TableOrdenCompra from './TableCompra';


const { Content } = Layout;
const { Title, Text } = Typography
const moment = require('moment')
moment.locale('es');



/**
 *
 *
 * @export
 * @class VentaDetalle
 * @extends {Component}
 * @description Vista donde se muestra la informacion de una venta para el envio del paquete
 */
class VentaDetalle extends Component {

    back = () => {
        this.props.navigate(-1);
    }

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            venta_id: this.props.params.venta_id,
            venta: {},
            
        }
    }


    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token')
        if (this.props.params.venta_id) {
            this.getVenta()
        }
    }


    /**
     *
     * @memberof DetalleInversion
     * 
     * @method getVenta
     * @description Obtiene informacion del Cliente
     */
    getVenta = () => {
        this.setState({ loading: true })
        axios.get('/customer/venta', {
            params: {
                venta_id: this.props.params.venta_id
            }
        }).then(response => {
            console.log('get venta', response)
            this.setState({ venta: response.data })
        }).catch(error => {
            message.error("Error getting customer information")
        }).finally(() => { this.setState({ loading: false })})
    }


    render() {

        const { cliente } = this.state
        let refTableOrdenCompra = createRef()

        return (
            <>
                <PageHeader
                    className="admin-page-header"
                    title={<Breadcrumb
                        className="breadcrumb-header"
                        items={[
                            {
                                title: "Sale extended detail",
                                //onClick: () => this.props.navigate('/admin/sales'),
                                //className: "link"
                            },
                        ]}
                    />}
                >
                </PageHeader>
                <Content className="pd-1">
                    <Row gutter={[16, 16]}>
                        <Col xs={24} md={12} className="grid">
                            <CardSimpleInfo
                                title="Total"
                                info={`$ ${(this.state?.venta.total ?? 0).toMoney(true)} USD`}
                            />
                        </Col>
                        <Col xs={24} md={12} className="grid">
                            <CardSimpleInfo
                                title="Status "
                                info={<div className="flex-left-column">
                                    {renderEstatusVenta(this.state.venta.status)}
                                    {this.state.venta.pick_and_deliver ? <Tag color="#f22eef" className="mt-1"> Pick & Delivery </Tag> : null}
                                </div>
                                }
                            />
                        </Col>
                        <Col span={24}>
                            <EnviosConfiguracion
                                cotizacion_id={this.state.venta.cotizacion_id}
                                venta={this.state.venta}
                                label_id={this.state.venta?.label_ship}
                                refresh={this.getVenta}
                                can_view={true}
                                disabled_configuration={true} />
                        </Col >
                        <Col span={24}>
                            <Card>
                                <TableOrdenCompra
                                    ref={refTableOrdenCompra}
                                    {...this.props}
                                    folio={this.state.folio}
                                    setFolio={e => this.setState({ folio: e })}
                                    venta_id={this.props.params.venta_id}
                                />
                            </Card>
                            
                        </Col>
                    </Row>

                </Content>
            </>
        )
    }
}


export default function (props) {

    const params = useParams()
    const navigate = useNavigate()


    return <VentaDetalle  {...props} params={params} navigate={navigate}
        {...usePermissions(null, {
            editarCliente: ["ventas", "edit"],
            deleteCliente: ["ventas", "delete"],
            createCliente: ["ventas", "create"],
        })}
    />
}