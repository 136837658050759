import React, { useEffect } from "react";

import ImgCrop from "antd-img-crop";

import { Upload } from "antd";
import { UploadOutlined, InboxOutlined } from "@ant-design/icons";


function Uploader(props) {

    const {
        children,
        value = props.fileList,
        onChange = () => { },
        updateOne = () => { },
    } = props;


    let [fileList, setFileList] = React.useState(value);

    const triggerChange = (changedValue) => {

        let currentFileList = []
        if (fileList)
            currentFileList = [...fileList, changedValue]
        else
            currentFileList = [changedValue]


        if (onChange)
            onChange(currentFileList);

        if (updateOne)
            updateOne(changedValue);

        setFileList(currentFileList);
    }

    useEffect(() => {
        if (fileList?.length !== value?.length)
            setFileList(value)
    }, [value])

    return <Upload
        {...props}
        fileList={fileList}
        customRequest={(custom) => {
            let { file } = custom
            file.status = "done"
            triggerChange(file);
        }}
    >
        {children}
    </Upload>
}

function AvatarLoader(props) {


    const {
        value = null,
        onChange = () => { },
        onRemove = () => { },
        _id,
        url,
        remove = false,
        uploaderProps = {},
        imageCrop = false,
        buttonDelete = true,
        uploadContent = <div>
            <UploadOutlined />
            <div className="ant-upload-text">Upload Image</div>
        </div>
    } = props;

    let [image, setImage] = React.useState(null);

    const triggerChange = (changedValue) => {
        if (onChange) {
            onChange(changedValue)
        }
        setImage(changedValue)
    };

    const returnFileList = (image) => {
        let list = []

        if (image instanceof File) {
            list = [{
                uid: 1,
                url: URL.createObjectURL(image),
                status: 'done',
                name: image?.name
            }]
        } else if (image) {
            list = [{
                ...image
            }]
        }

        return list
    }

    useEffect(() => {
        if (value) {
            setImage(value)
        }

    }, [value]);

    const upload = <Upload
        listType="picture-card"
        showUploadList={true}
        accept="image/*"
        customRequest={(custom) => {
            const { file } = custom;
            triggerChange(file);
        }}
        maxCount={1}
        fileList={returnFileList(image)}
        onRemove={() => {
            onRemove()
            triggerChange(null);
        }}
    >
        {!image ? uploadContent : null}
    </Upload>


    return imageCrop ? (
        <ImgCrop
            modalTitle="Edit image"
            modalOk="OK"
            modalCancel="Cancel"
            rotationSlider
        >
            {upload}
        </ImgCrop>
    ) : upload
}

function ImageDragger(props) {


    const {
        value = null,
        onChange = () => { },
        onRemove = () => { },
        beforeUpload = () => true,
        _id,
        url,
        remove = false,
        uploaderProps = {},
        imageCrop = false,
        buttonDelete = true,
        uploadContent = <div>
            <p className="ant-upload-drag-icon">
                <InboxOutlined />
            </p>
            <p className="ant-upload-text">Click or drag</p>
        </div>
    } = props;

    let [image, setImage] = React.useState(null);

    const triggerChange = (changedValue) => {
        if (onChange) {
            onChange(changedValue)
        }
        setImage(changedValue)
    };

    const returnFileList = (image) => {
        let list = []

        if (image instanceof File) {
            list = [{
                uid: 1,
                url: URL.createObjectURL(image),
                status: 'done',
                name: image?.name
            }]
        } else if (image) {
            list = [{
                ...image
            }]
        }

        return list
    }

    useEffect(() => {
        console.log("value", value);
        if (value) {
            setImage(value)
        }

    }, [value]);


    let ruta = "/img/recientes-1.png"
    if (image instanceof File) ruta = URL.createObjectURL(image)
    if (image?.url) ruta = image?.url

    const upload = <Upload.Dragger
        beforeUpload={(file) => beforeUpload(file) || Upload.LIST_IGNORE}
        showUploadList={true}
        accept="image/*"
        customRequest={(custom) => {
            const { file } = custom;
            triggerChange(file);
        }}
        maxCount={1}
        fileList={returnFileList(image)}
        onRemove={() => {
            onRemove()
            triggerChange(null);
        }}
    >
        {!image ? uploadContent : <div className="image-backgroung" style={{ backgroundImage: `url("${ruta}")` }}></div>}
    </Upload.Dragger>


    return imageCrop ? (
        <ImgCrop
            modalTitle="Edit Image"
            modalOk="OK"
            modalCancel="Cancel"
            rotate
        >
            {upload}
        </ImgCrop>
    ) : upload
}

function ImagesUploader(props) {

    const {
        children = <div>
            <p className="ant-upload-drag-icon">
                <InboxOutlined />
            </p>
            <p className="ant-upload-text">Click or drag the images</p>
        </div>,
        value = props.fileList,
        onChange = () => { },
        updateOne = () => { },
        onRemove = () => { },
        listType = "text",
        showUploadList = false,
        imageCrop = false,

    } = props;


    let [fileList, setFileList] = React.useState(value);

    const triggerChange = (changedValue) => {

        let currentFileList = []
        if (fileList)
            currentFileList = [...fileList, changedValue]
        else
            currentFileList = [changedValue]


        if (onChange)
            onChange(currentFileList);

        if (updateOne)
            updateOne(changedValue);

        setFileList(currentFileList);
    }

    const deleteFile = (e) => {
        let temp = [...fileList]
        let index = temp.findIndex(img => img.uid === e.uid)
        if (index !== -1) {
            temp.splice(index, 1)
            setFileList(temp)
            if (onChange)
                onChange(temp);
        }
    }

    useEffect(() => {
        if (fileList?.length != value?.length)
            setFileList(value)
    }, [value])

    const upload = <Upload.Dragger
        listType={listType}
        showUploadList={showUploadList}
        fileList={fileList}
        customRequest={(custom) => {
            let { file } = custom
            file.status = "done"
            triggerChange(file);
        }}
        onRemove={(e) => {
            onRemove(e)
            deleteFile(e);
        }}
    >
        {children}
    </Upload.Dragger>
    return imageCrop ? (
        <ImgCrop
            modalTitle="Edit Image"
            modalOk="OK"
            modalCancel="Cancel"
            rotate
        >
            {upload}
        </ImgCrop>
    ) : upload
}

export {
    Uploader,
    AvatarLoader,
    ImageDragger,
    ImagesUploader
}


