import React, { Component } from 'react';
import { Row, Col, Typography, Spin, Layout, Space, message, Card, List, Tag } from 'antd'
import dayjs from 'dayjs';
import axios from 'axios';
import { useNavigate } from "react-router-dom";

//Componentes
import { ButtonView } from '../../Widgets/Buttons';
import Logged, { User } from "../../../Hooks/Logged";
import PageHeader from '../../Widgets/PageHeader/PageHeader';
import { MoneyFormat, FolioFormat, renderEstatusQuotation } from "../../Utils"
import useSearch from '../../../Hooks/useSearch';

const { Content } = Layout;
const { Text, Title } = Typography

/**
 *
 *
 * @export
 * @class Quotations
 * @extends {Component}
 * @description Vista donde se listan todos los quotations
 */
class Quotations extends Component {
    static contextType = Logged;

    /**
     *Creates an instance of Quotations.
     * @param {*} props
     * @memberof Quotations
     */
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            quotations: {
                data: [],
                limit: 10,
                page: 1,
                total: 0,
                pages: 0,
                filters: []
            }
        }
    }

    componentDidMount() {

        this.get()
    }

    componentDidUpdate(prevProps) {
        if (this.props.search != prevProps.search) {
            this.get()
        }
    }

    /**
    *
    * @memberof Quotations
    * @method get
    * @description Obtiene la lista de quotations
    */
    get = async ({
        page = this.state.quotations.page,
        limit = this.state.quotations.limit,
        search = this.props.search,
    } = this.state.quotations) => {

        this.setState({ loading: true })
        axios.get('/customer/cotizaciones', {
            params: {
                limit,
                page,
                search
            }
        })
            .then(({ data }) => {
                this.setState({ quotations: data })
            })
            .catch(res => {
                console.log("res", res);
                message.error(res.response?.data?.message ?? "Error")
            })
            .finally(() => this.setState({ loading: false }))
    }

    renderCosto = (item) => {
        if (item.costo && item.status == 0)
            return `$ ${(item.costo).toMoney(true)} usd`
        if (!item.costo && item.status == 0)
            return 'Pending'
        if (item.costo && item.status == 1)
            return `$ ${(item.costo).toMoney(true)} usd`
        if (item.status == 2)
            return 'N/A'
    }


    render() {

        return (
            <>
                <Spin spinning={this.state.loading}>
                    <PageHeader
                        className="admin-page-header"
                        title="Quotations"
                    >

                    </PageHeader>

                    <Content className="quotations-content content-bg pd-1">
                        <List
                            className="component-list responsive"
                            itemLayout="horizontal"
                            locale={{ emptyText: "Quotations not found" }}
                            dataSource={this.state.quotations.data}
                            pagination={{
                                current: this.state.quotations.page,
                                pageSize: this.state.quotations.limit,
                                total: this.state.quotations.total,
                                hideOnSinglePage: false,
                                position: 'bottom',
                                onChange: (page, limit) => this.get({ page, limit })

                            }}

                            renderItem={item => (
                                <List.Item className="component-list-item">
                                    <Card className="card-list">
                                        <Row className="width-100" gutter={[8, 8]}>
                                            <Col xs={{ span: 16, order: 1 }} md={{ span: 3, order: 1 }} lg={{ span: 5, order: 1 }}>
                                                <Text strong ellipsis>{FolioFormat(item.folio)}</Text>
                                            </Col>
                                            <Col xs={{ span: 24, order: 6 }} md={{ span: 3, order: 2 }} lg={{ span: 3, order: 2 }}>
                                                <Text strong ellipsis>{dayjs(item.fecha).format('MM/DD/YYYY')}</Text>
                                            </Col>
                                            <Col xs={{ span: 8, order: 5 }} md={{ span: 5, order: 3 }} lg={{ span: 4, order: 3 }} >
                                                <Text ellipsis>{item.servicio_id?.nombre}</Text>
                                            </Col>
                                            <Col xs={{ span: 24, order: 3 }} md={{ span: 5, order: 4 }} lg={{ span: 5, order: 4 }}>
                                                <Space align="center" direction={"horizontal"} wrap>
                                                    {renderEstatusQuotation(item.status)}
                                                    <Text ellipsis> {item.venta?.square_payment ? <Tag color='#00cd98'>Paid</Tag> : <Tag color='#F2802E'>Waiting for Payment</Tag>}</Text>
                                                </Space>

                                            </Col>
                                            <Col xs={{ span: 16, order: 4 }} md={{ span: 5, order: 5 }} lg={{ span: 3, order: 5 }} >
                                                <Text ellipsis>{this.renderCosto(item)}</Text>
                                            </Col>
                                            <Col xs={{ span: 8, order: 2 }} md={{ span: 2, order: 6 }} lg={{ span: 2, order: 6 }} className="flex-right">
                                                <ButtonView
                                                    onClick={() => this.props.navigate('/customer/quotations/' + item._id)}
                                                />
                                            </Col>
                                        </Row>
                                    </Card>
                                </List.Item>
                            )}
                        />
                    </Content>
                </Spin>
            </>
        )
    }
}

export default function (props) {

    const user = React.useContext(User)

    const { search, setShow } = useSearch()
    return <Quotations {...props}
        navigate={useNavigate()}
        setShowSearch={setShow}
        search={search} />
}