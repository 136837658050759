import React, { Component, useContext } from 'react'
import { Row, Col, Form, Input, Button, Typography, Modal, Select, Checkbox, Spin } from 'antd';
import { Navigate, Link, useNavigate } from 'react-router-dom';
import { User, SetUser } from '../../../Hooks/Logged';
import axios from 'axios'

//css
import '../../../Styles/Global/auth.scss';
import PhoneInput from '../../Widgets/Inputs/PhoneInput';

//Modales
import ModalAvisoPrivacidad from "../../Auth/ModalAvisoPrivacidad"

const { Title, Text } = Typography

/**
 * 
 * @class Register
 * @extends {Component}
 * @description Componente para registrarse
 */
class Register extends Component {

	constructor(props) {
		super(props);
		this.state = {
			loading: false
		}
	}

	/**
	 * @memberof Register
	 *
	 * @method handleSubmit
	 * @description  Envia los datos del formulario al Servidor
	 **/
	handleSubmit = (values) => {

		if(this.state.privacy_checked === false){
			Modal.warning({
                title: "Accept the privacy policy",
                content: "Before register, please read and accept our privacy policy"
            });

            return;
		}

		this.setState({ loading: true })
		axios.post('/register', values)
			.then(({ data, headers }) => {
				axios.defaults.headers.post["Authorization"] = headers.authorization;
				sessionStorage.setItem('token', headers.authorization);
				axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
				this.props.setUser(data.user);
				this.goToNextPage()
			})
			.catch((error) => {
				console.log("error", error);
				Modal.error({
					title: "An error occurred in the registry",
					content: error?.response?.data?.message ?? "Try later"
				})
				this.setState({ loading: false });
			})
	};

	/**
     * @memberof Cart
     * @method goToNextPage
     * @description indica a que step tiene que ir
     */
    goToNextPage = () => {
        this.props.next()
    }


	render() {
		return (
			<>
				<Spin spinning={this.state.loading}>
					<Form
						onFinish={this.handleSubmit}
						layout={"vertical"}
						requiredMark={false}
						className='login-form'
					>
						<Title level={3} className="h3-title mb-1">Register</Title>
						<Row gutter={[8, 0]}>
							<Col span={12}>
								<Form.Item
									name="nombre"
									label="Name"
									rules={[
										{ required: true, message: 'Please, enter your name' },
										{
											max: 50,
											message: "Maximum 50 characters"
										}
									]}>
									<Input size="large" className="input-login" />
								</Form.Item>
							</Col>
							<Col span={12}>
								<Form.Item
									name="apellidos"
									label="Last Name"
									rules={[
										{ required: true, message: 'Please, enter your last name.' },
										{
											max: 50,
											message: "Maximum 50 characters"
										}
									]}>
									<Input size="large" className="input-login " />
								</Form.Item>
							</Col>
							<Col span={24}>
								<Form.Item
									name="email"
									label="Email"
									rules={[
										{
											type: 'email',
											message: 'Email is not valid',
										},
										{
											required: true,
											message: 'Enter your email',
										}, {
											max: 50,
											message: "Maximum 50 characters"
										}
									]}
								>
									<Input placeholder="example@mail.com" size="large" className="input-login" />
								</Form.Item>
							</Col>
							<Col span={24}>
								<Form.Item
									name="telefono"
									label="Phone"
									rules={[
										{
											required: true,
											message: 'Please, enter your phone',
										}
									]}
								>
									<PhoneInput size="large"/>
								</Form.Item>
							</Col>
							<Col span={24}>
								<Form.Item
									label="Password"
									name="password"
									hasFeedback
									rules={[
										{ required: true, message: 'Please, enter your password' },
										{
											max: 50,
											message: "Maximum 50 characters"
										}
									]}
								>
									<Input.Password placeholder="********" size="large" />
								</Form.Item>
							</Col>
							<Col span={24}>
								<Form.Item
									name="confirm"
									label="Password Confirm"
									dependencies={['password']}
									hasFeedback
									rules={[
										{
											required: true,
											message: 'Please, confirm your password',
										},
										{
											max: 50,
											message: "Maximum 50 characters"
										},
										({ getFieldValue }) => ({
											validator(_, value) {
												if (!value || getFieldValue('password') === value) {
													return Promise.resolve();
												}
												return Promise.reject(new Error('Passwords do not match'));
											},
										}),
									]}
								>
									<Input.Password placeholder="********" size="large" />
								</Form.Item>
							</Col>
							<Col span={24}>
									<Form.Item
										name='referal'
										label='How did you find Us?'
										rules={[
											{required: true, message:"Please tell us, how did you find about our site?"}
										]}
									>
										<Select
											size="large"
											options={[
												{
													value: 1,
													label: "Instagram"
												},
												{
													value: 2,
													label: "Facebook"
												},
												{
													value: 3,
													label: "Google"
												},
												{
													value: 4,
													label: "Influencer"
												},
												{
		                                            value: 5,
		                                            label: "Other"
		                                        }
											]}
										/>
											
									</Form.Item>
							</Col>
							<Col span={24}>
								<Form.Item
									name="remember"
									valuePropName="checked"
								>
									<Checkbox
										onChange={({target})=>this.setState({privacy_checked: target.checked})}
									> I have read and agree to the </Checkbox>
									<Text
										style={{color: "#207cff"}}
										className="hover" 
										underline 
										onClick={(e)=>{
											e.stopPropagation()
											this.setState({modal_visible_privacidad: true})
										}}
									>privacy policy</Text>
								</Form.Item>
							</Col>
						</Row>
						<Form.Item>
							<Button type="primary" htmlType="submit" block size="large" >Create Account</Button>
						</Form.Item>
					</Form>
				</Spin>
				<ModalAvisoPrivacidad
					visible={this.state.modal_visible_privacidad}
					onClose={()=>this.setState({modal_visible_privacidad: false})}
				/>
			</>
		)
	}
}

export default function View(props) {

	let user = useContext(User)
	const setUser = useContext(SetUser)

	return <Register {...props} user={user} setUser={setUser} navigate={useNavigate()} />
}