import React, { Component } from 'react';
import { Typography, Spin, Layout, message, List, } from 'antd'
import { useNavigate } from "react-router-dom";
import axios from 'axios';

import Logged, { User } from "../../../Hooks/Logged";

import PageHeader from '../../Widgets/PageHeader/PageHeader';
import '../../../Styles/Modules/Customer/Direcciones.scss'
import { PlusOutlined } from '@ant-design/icons';
import ModalDirecciones from './ModalDirecciones';
import { CardAddress } from '../../Widgets/Cards';

const { Content } = Layout;
const { Text, } = Typography

/**
 *
 *
 * @export
 * @class Direcciones
 * @extends {Component}
 * @description Vista donde se listan todos los direcciones
 */
class Direcciones extends Component {
    static contextType = Logged;

    /**
     *Creates an instance of Direcciones.
     * @param {*} props
     * @memberof Direcciones
     */
    constructor(props) {
        super(props)
        this.state = {

            loading: false,
            direcciones: {
                data: [],
                limit: 11,
                page: 1,
                total: 0,
                pages: 0,
                filters: []
            }
        }
    }

    componentDidMount() {

        this.get()
    }

    componentDidUpdate(prevProps) {
        if (this.props.search != prevProps.search) {
            this.get()
        }
    }

    /**
    *
    * @memberof Direcciones
    * @method get
    * @description Obtiene la lista de direcciones
    */
    get = async ({
        page = this.state.direcciones.page,
        limit = this.state.direcciones.limit,
        search = this.props.search,
    } = this.state.direcciones) => {

        this.setState({ loading: true })
        axios.get('/customer/direcciones', {
            params: {
                limit,
                page,
                search
            }
        })
            .then(({ data }) => {
                this.setState({
                    direcciones: {
                        ...data,
                        limit,
                        data: [...data.data, { add: true }]
                    }
                })
            })
            .catch(res => {
                console.log("res", res);
                message.error(res.response?.data?.message ?? "Error")
            })
            .finally(() => this.setState({ loading: false }))
    }

    /**
    *
    * @memberof Direcciones
    * @method delete
    * @description Elimina una direccion del cliete
    */
    delete = (id) => {
        this.setState({ loading: true })
        axios.delete('/direcciones', {
            params: {
                id
            }
        })
            .then(({ data }) => {
                message.success("Address deleted!")
                this.get()
            })
            .catch(res => {
                message.error(res.response?.data?.message ?? "Error")
            }).finally(() => this.setState({ loading: false }))
    }

    renderButton = () => (
        <button className='direcciones-add' onClick={() => this.setState({ modalVisible: true, direccion_id: undefined })}>
            <PlusOutlined style={{ fontSize: 24, color: "white", margin: "8px" }} />
            <Text className="text-white">Add new address</Text>
        </button>
    )


    render() {

        return (
            <>
                <Spin spinning={this.state.loading}>
                    <PageHeader
                        className="admin-page-header"
                        title="Addresses"
                    >

                    </PageHeader>

                    <Content className="direcciones-content content-bg pd-1">
                        <List
                            grid={{
                                gutter: 24,
                                column: 6,
                                xs: 1,
                                sm: 1,
                                md: 2,
                                lg: 3,
                                xl: 4,
                                xxl: 6
                            }}
                            className='direcciones-list'
                            locale={{ emptyText: "No Addresses" }}
                            dataSource={this.state.direcciones.data}
                            pagination={{
                                current: this.state.direcciones.page,
                                pageSize: this.state.direcciones.limit,
                                total: this.state.direcciones.total,
                                hideOnSinglePage: false,
                                position: 'bottom',
                                onChange: (page, limit) => this.get({ page, limit })

                            }}

                            renderItem={item => (
                                <List.Item className='direcciones-list-item'>
                                    {
                                        item.add ? (
                                            this.renderButton()
                                        ) : (
                                            <CardAddress
                                                item={item}
                                                onEdit={() => this.setState({ modalVisible: true, direccion_id: item._id })}
                                                onDelete={() => this.delete(item._id)}
                                            />
                                        )
                                    }
                                </List.Item>
                            )}
                        />
                    </Content>
                </Spin>
                <ModalDirecciones
                    visible={this.state.modalVisible}
                    direccion_id={this.state.direccion_id}
                    cliente_id={this.props.user?.cliente_id}
                    onClose={() => {
                        this.setState({ modalVisible: false, direccion_id: undefined })
                        this.get()
                    }}
                />
            </>
        )
    }
}

export default function (props) {

    const user = React.useContext(User)

    return <Direcciones {...props} navigate={useNavigate()} user={user} />
}