
import React, { Component } from 'react'
import { Form, Select, DatePicker, Flex, Progress, Checkbox } from 'antd';

const { Option } = Select

export default class StepCollection extends Component {
    constructor(props) {
        super(props)
        this.state = {
            collection: {}
        }
    }
    formRef = React.createRef();
    componentDidMount() {
        if (Object.keys(this.props.collection).length > 0)
            this.formRef.current.submit()
        console.log('se monto collection step')
    }
    onValuesChange = (changedValues, allValues) => {
        console.log(allValues)
        if (allValues.collection_date.day() == 6) { this.setState({ isSaturday: true }) } else this.setState({ isSaturday: false })
        this.setState({ collection: allValues })

        this.props.updateQuotationData('collection', allValues)
    }

    handleFieldsChange = (_, allFields) => {
        // Verificar si todos los campos requeridos están llenos
        const isFilled = allFields?.every((field) => field.errors.length == 0);

        console.log(allFields)

        this.props.setDisabled(!isFilled);
    };

    disabledDates = current => {
        const hoy = new Date();
        hoy.setHours(0, 0, 0, 0);
        return current && current < hoy || current.day() == 0;
    };
    render() {
        return (
            <Form
                layout={"vertical"}
                onValuesChange={this.onValuesChange}
                ref={this.formRef}
                initialValues={this.props.collection}
                onFieldsChange={this.handleFieldsChange}
                validateTrigger="onChange"
            >
                <Form.Item
                    name="collection_date"
                    label="Collection Date"
                    rules={[
                        {
                            required: true,
                            message: 'Date to recollect is required',
                        },
                    ]}
                >
                    <DatePicker className='width-100' disabledDate={this.disabledDates} />
                </Form.Item>

                <Form.Item
                    name="time_slot"
                    label="Time Slot"
                    rules={[
                        {
                            required: true,
                            message: 'Select the time to slot  is required',
                        },
                    ]}
                >
                    <Select>
                        <Option value={1}>11:00 am-2:00 pm</Option>
                        {!this.state.isSaturday ? <Option value={2}>03:00 pm-6:00 pm</Option>: null}
                    </Select>
                </Form.Item>


            </Form>

        )
    }
}