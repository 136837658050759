import React, { Component } from "react";
import { Row, Col, Space, Modal, List, Upload, Button, Typography, Form, DatePicker, Input, InputNumber, Select, message, Spin, Cascader, Tag, AutoComplete, Divider } from 'antd';


import { FaFileCsv } from "react-icons/fa";

import { IconTemplate } from "../../Widgets/Iconos";


import axios from "axios"
const { Title, Text, Paragraph } = Typography;

/**
 *
 *
 * @class ModalCSV
 * @extends {Component}
 * @description Agregar multiples transacciones 
 */
class ModalCSV extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false
        }
    }


    /**
     *
     *
     * @param {*} info
     * @memberof ModalCSV
     * @description Cuando el archivo se sube o hay un error, queda almacenado aquí
     */
    onChangeUpload = (info) => {
        const { status } = info.file;
        if (status === 'done' || status === 'error') {
            if (status === 'done')
                Modal.success({
                    title: "The file has been uploaded successfully.",
                    content: "The products have been saved successfully",
                    onOk: (...event) => {
                        this.props.onSuccess(true)
                    }
                })
            else 
                Modal.warning({
                    title: "The file has been uploaded with some errors.",
                    content: "Some of the products could not be saved successfully, check the document",
                    okText: "Cerrar",
                    onOk: () => {
                        this.props.onSuccess(false)
                    }
                })
        } 
    }

    render() {


        return (
            <Form
                layout="vertical"

                name="form-productos"
                id="form-productos"
                ref={this.modalRef}
                onFinish={this.onFinish}
            >
                <Spin spinning={this.state.loading}>
                    <Title className="mb-2 mt-1" level={5} style={{ textAlign: "center" }}> Take into account the following considerations.</Title>
                    <List
                        bordered={false}
                        size="small"
                        dataSource={[
                            "Make sure that the data is written correctly as in the system.",
                            "Download the CSV template to upload multiple products.",
                            "Responses for the 'Active' and 'Hot Sale' fields should be limited to 'Yes' or 'No'",
                        ]}
                        renderItem={item => <List.Item>{item}</List.Item>}
                    />
                    <div style={{ marginTop: "2em", textAlign: "center" }}>
                        <a download href="/files/template_productos.csv"> 

                            <IconTemplate />
                        </a>
                    </div>

                    <Row style={{ width: "100%" }} className="mt-2 mb-2">
                        <Upload.Dragger
                            className="width-100"
                            {...{
                                name: 'file',
                                multiple: false,
                                maxCount: 1,
                                action: axios.defaults.baseURL + "/productos/csv",
                                headers: {
                                    Authorization: sessionStorage.getItem("token")
                                },
                                onChange: this.onChangeUpload
                            }}>
                            <div style={{ margin: "0 1em" }}>
                                <p className="ant-upload-drag-icon">
                                    <FaFileCsv style={{ fontSize: "3em" }} />
                                </p>
                                <p className="ant-upload-text">Click or drag the CSV file to this area to upload it</p>
                                <p className="ant-upload-hint">It is only possible to upload CSV type files</p>
                            </div>
                        </Upload.Dragger>
                    </Row>
                </Spin>
            </Form>
        )
    }
}



export default function (props) {
    const { visible = false, onClose = () => { }, transaccion_id, clasificacion } = props

    return <Modal
        open={visible}
        onCancel={onClose}
        title={"Add Multiple Products"}
        closable={true}
        maskClosable={true}
        destroyOnClose={true}
        zIndex={1000}
        okText="Add Products"
        okButtonProps={{ form: 'form-transaccion', key: 'submit', htmlType: 'submit' }}
    >
        <ModalCSV {...props} />
    </Modal>

}