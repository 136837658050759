import React, { Component, useContext } from 'react';
import { Row, Col, Layout, Card, List, Typography, Space, Image, Tabs, message, Modal } from 'antd'
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import useSearch from '../../../Hooks/useSearch';
import PageHeader from '../../Widgets/PageHeader/PageHeader';
import { CardQuotationInformation, CardQuotationProgress, CardQuotationAddress } from "../../Widgets/Cards"

import Socket from '../../../Hooks/Socket';

import { FolioFormat, getResponseError } from '../../Utils';
import '../../../Styles/Modules/Admin/quotations.scss'
import EnviosConfiguracion from '../../Admin/Quotations/Envios';
import PaymentModal from './PaymentModal';

const { Content } = Layout;
const { Text, Paragraph } = Typography;

/**
 *
 *
 * @export
 * @class Quotation
 * @extends {Component}
 * @description Vista donde se muestra un resumen de las estadisticas del sistema
 */
class Quotation extends Component {

    /**
     *Creates an instance of Usuarios.
     * @param {*} props
     * @memberof Usuarios
     */
    constructor(props) {
        super(props)
        this.state = {
            quotation: {},
            editable: false,
            paymentModalVisible: false
        }
    }


    componentDidMount() {
        this.props.setShowSearch(false)
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token')
        this.get()
    }

    componentWillUnmount() {
        this.props.setShowSearch(true)
    }

    get = () => {
        this.setState({ loading: true })
        axios.get(`/customer/cotizacion/${this.props.params.id}`, {
            params: {
                venta: true
            }
        })
            .then(({ data }) => {
                console.log("quotations", data)
                this.setState({ quotation: data })
            })
            .catch(error => {
                console.log('error', error)
                message.error("Error getting the quotation")
            })
            .finally(() => {
                this.setState({ loading: false })
            })
    }

    renderFiles = (item, id) => {
        return (
            <List.Item className="quotation-files-item">
                <Row className="width-100" gutter={[6, 8]} align={"middle"}>
                    <Col xs={10} lg={2}>
                        <Image
                            src={`${axios.defaults?.baseURL}/customer/cotizacion/${id}?imagen=${item.imagen.file}&Authorization=${sessionStorage.getItem('token')}`}
                            placeholder
                            className='item-image'
                        />
                    </Col>
                    <Col xs={14} lg={22}>
                        <Space size={4} direction='vertical'>
                            <Text strong>Description</Text>
                            <Paragraph ellipsis={{ rows: 2 }}>{item.descripcion}</Paragraph>
                        </Space>
                    </Col>
                </Row>
            </List.Item>
        )
    }

    handlePayment = () => {
        this.setState({ paymentModalVisible: true })
    }



    render() {

        const { quotation } = this.state
        const { direccion_id } = quotation


        let address = null
        if (direccion_id?.localizacion) address = direccion_id.localizacion.place_name.split(',')
        if (direccion_id?.codigo_postal && !address) address = [direccion_id.nombre, direccion_id.codigo_postal]

        console.log('quotation', quotation)

        return (
            <>
                <PageHeader
                    backIcon={true}
                    className="admin-page-header"
                    title="Quotation"
                    subTitle={FolioFormat(quotation.folio)}
                />
                <Content className="customer-content quotations-details content-bg pd-1">
                    <Row gutter={[16, 16]}>
                        <Col xs={24} lg={8}>
                            <CardQuotationInformation
                                quotation={quotation}
                                can_view={false}
                                loading={this.state.loading}
                                onConfirm={() => this.setState({ modalAccept: true })}
                                updateQuotation={this.updateStatus}
                            />
                        </Col>
                        <Col xs={24} lg={8}>
                            <CardQuotationAddress
                                address={address}
                                can_track={false}
                                status={quotation.status}
                                venta={quotation.venta}
                                collection_date={quotation.collection_date}
                                time_slot={quotation.time_slot}
                                entrega_local={quotation.entrega_local}


                            />
                        </Col>
                        <Col xs={24} lg={8}>
                            <CardQuotationProgress
                                status={this.state.quotation.status}
                                can_pay={true}
                                is_pay={this.state.quotation.venta?.square_payment}
                                onPayment={this.handlePayment} />
                        </Col>
                        <Col xs={24}>


                            <Tabs
                                items={[
                                    {
                                        label: 'Files',
                                        key: '1',
                                        children: <Card title={'Files Uploaded'}>
                                            <List
                                                loading={this.state.loading}
                                                className="quotations-files-list"
                                                itemLayout="horizontal"
                                                locale={{ emptyText: "Files not found" }}
                                                dataSource={quotation.imagenes}
                                                renderItem={item => this.renderFiles(item, quotation._id)}
                                            />
                                        </Card>
                                    },
                                    {
                                        label: 'Shipment',
                                        key: '2',
                                        children: <EnviosConfiguracion
                                            cotizacion_id={quotation._id}
                                            venta={quotation.venta}
                                            label_id={quotation.venta?.label_ship}
                                            refresh={this.get}
                                            can_view={false}
                                            disabled_configuration={true} />,
                                        disabled: quotation.status != 1 || (quotation.entrega_local || quotation.pick_and_deliver) || (quotation.venta?.square_payment == false),
                                    }

                                ]}
                            >  </Tabs>
                        </Col>
                    </Row>
                </Content>
                <PaymentModal
                    visible={this.state.paymentModalVisible}
                    venta_id={this.state.quotation?.venta?._id}
                    onClose={() => {
                        this.setState({ paymentModalVisible: false })
                        this.get()
                    }}
                />

            </>
        )
    }
}

export default props => {
    const { setShow } = useSearch()
    const navigate = useNavigate()
    const params = useParams();
    let socket = useContext(Socket)


    console.log('socket', socket)

    return <Quotation
        {...props}
        setShowSearch={setShow}
        navigate={navigate}
        params={params}
        socket={socket}
    />
}
