import React from 'react'
import { Route, Routes } from "react-router-dom";

import Servicios from '../../Components/Admin/Servicios/Servicios';

/**
 * 
 * @export
 * @function RouterServicios
 * @description Router for Servicios routes 
 */
function RouterServicios(props) {
  return (
    <Routes>
      <Route path="" element={<Servicios {...props} />} />
    </Routes>
  )
}

export default RouterServicios