
import React, { Component } from 'react'
import { List, Avatar } from 'antd';


export default class StepResumen extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <List
                itemLayout="horizontal"
                dataSource={this.props.images}
                renderItem={(item, index) => (
                    <List.Item>
                        <List.Item.Meta
                            avatar={<Avatar shape="square" src={URL.createObjectURL(item.image)} />}
                            description={item.description}
                        />
                    </List.Item>
                )}
            />
        )
    }
}