import React, { Component } from 'react';
import { Row, Col, Typography, Space, message, List, Card, Tag } from 'antd'
import { Link, useNavigate } from "react-router-dom";
import axios from 'axios';

import User from '../../../../../Hooks/Logged'
import { getResponseError, renderEstatusQuotation } from "../../../../Utils";

import usePermissions from '../../../../../Hooks/usePermissions';
import useSearch from '../../../../../Hooks/useSearch';
import dayjs from "dayjs";
import { ButtonView } from "../../../../Widgets/Buttons";

import '../../../../../Styles/Modules/Admin/clientes.scss'

const { Text, Title } = Typography


/**
 *
 *
 * @export
 * @class Clientes
 * @extends {Component}
 * @description Vista donde se listan todos los Clientes
 */
class QuotationsClient extends Component {



    /**
     *Creates an instance of Clientes.
     * @param {*} props
     * @memberof Clientes
     */
    constructor(props) {
        super(props)
        this.state = {
            loading: false,

            quotations: {
                data: [],
                page: 1,
                limit: 10,
                filters: [],
                total: 0,
                sort: {
                    createdAt: -1
                },
            },
        }
    }

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.get()
    }

    /**
    *
    * @memberof Quotations
    * @method get
    * @description Obtiene la lista de quotations
    */
    get = async ({
        page = this.state.quotations.page,
        limit = this.state.quotations.limit,
        search = this.props.search,
    } = this.state.quotations) => {

        this.setState({ loading: true })
        axios.get('/cotizaciones', {
            params: {
                limit,
                page,
                search,
                cliente_id: this.props.cliente_id
            }
        })
            .then(({ data }) => {
                this.setState({ quotations: data })
            })
            .catch(error => {
                message.error(getResponseError(error.response, "Error"))
            })
            .finally(() => this.setState({ loading: false }))
    }

    renderCosto = (item) => {
        if (item.costo && item.status == 0)
            return `$ ${(item.costo).toMoney(true)} usd`
        if (!item.costo && item.status == 0)
            return 'Pendient'
        if (item.costo && item.status == 1)
            return `$ ${(item.costo).toMoney(true)} usd`
        if (item.status == 2)
            return 'N/A'
    }
    render() {
        const { quotations, loading } = this.state
        return (
            <>
                <Row gutter={[24, 24]}>
                    <Col xs={24} className="">
                        <List
                            className="component-list responsive"
                            itemLayout="horizontal"
                            loading={loading}
                            locale={{ emptyText: "Quotations not found" }}
                            dataSource={quotations.data}
                            pagination={{
                                current: quotations.page,
                                pageSize: quotations.limit,
                                total: quotations.total,
                                hideOnSinglePage: false,
                                position: 'bottom',
                                onChange: (page, limit) => this.get({ page, limit })

                            }}

                            renderItem={item => (
                                <List.Item className="component-list-item">
                                    <Card className="card-list">
                                        <Row className="width-100" gutter={[8, 8]}>
                                            <Col xs={{ span: 16, order: 1 }} md={{ span: 3, order: 1 }} lg={{ span: 5, order: 1 }}>
                                                <Text strong ellipsis>{dayjs(item.fecha).format('MM/DD/YYYY')}</Text>
                                            </Col>
                                            <Col xs={{ span: 24, order: 6 }} md={{ span: 3, order: 2 }} lg={{ span: 3, order: 2 }}>
                                                <Text ellipsis>{item.servicio_id?.nombre}</Text>
                                            </Col>
                                            <Col xs={{ span: 8, order: 5 }} md={{ span: 5, order: 3 }} lg={{ span: 4, order: 3 }} >
                                                <Text ellipsis>{item.servicio_id?.descripcion ?? "- "}</Text>
                                            </Col>
                                            <Col xs={{ span: 24, order: 3 }} md={{ span: 5, order: 4 }} lg={{ span: 5, order: 4 }}>
                                                <Space align="center" direction={"horizontal"} wrap>
                                                    {renderEstatusQuotation(item.status)}
                                                    <Text ellipsis> {item.venta?.square_payment ? <Tag color='#00cd98'>Paid</Tag> : <Tag color='#F2802E'>Waiting for Payment</Tag>}</Text>
                                                </Space>
                                            </Col>
                                            <Col xs={{ span: 16, order: 4 }} md={{ span: 5, order: 5 }} lg={{ span: 3, order: 5 }} >
                                                <Text ellipsis>{this.renderCosto(item)}</Text>
                                            </Col>
                                            <Col xs={{ span: 8, order: 2 }} md={{ span: 2, order: 6 }} lg={{ span: 2, order: 6 }} className="flex-right">
                                                <Space>
                                                    <ButtonView
                                                        onClick={() => this.props.navigate(`/admin/quotations/${item._id}`)}
                                                    />
                                                </Space>
                                            </Col>
                                        </Row>
                                    </Card>
                                </List.Item>
                            )}
                        />
                    </Col>
                </Row>
            </>
        )
    }
}



export default function (props) {
    const { setShow } = useSearch()

    const user = React.useContext(User)
    const permisos = usePermissions(user?.rol_id?.permisos, {
        canCreate: ["clientes", "create"],
        canEdit: ["clientes", "edit"],
        canDelete: ["clientes", "delete"],
        canViewDetails: ["clientes", "cliente-detalles", "access"],
    })

    const navigate = useNavigate();


    return <QuotationsClient {...props}
        {...permisos}
        setShowSearch={setShow}
        navigate={navigate}
    />
}       