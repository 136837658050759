import React from 'react';
import { Card, Space, Typography, Button, Layout, Row, Col, Divider } from 'antd';
import { renderImage } from '../Utils';

const { Text, Paragraph, Title } = Typography;




function ServicesCard({ service = {}, addtoCart }) {

    return (<Card className='card-services'
        cover={<img className='img-cover'
            alt={service.nombre}
            src={renderImage(service._id, service.imagenes,'service')}
        />}

    >
        <Row>
            <Col span={24}>
                <Title className='title-card' level={4}>{service.nombre}</Title>
            </Col>
            <Col span={24} className="center">
                <Text type="secondary" strong className='amount'>{service.precio ? <> $ {service.precio} {<small></small>}</> : null}  </Text>
            </Col>
        </Row>
        <Divider className='divider-card'/>
        <Paragraph style={{ color: "white" }} className='descripcion'  ellipsis={{ rows: 8, expandable: false }} >
            {service.descripcion}
        </Paragraph>
        <Row className='choose-btn center'>
            <Col span={24} >
                <Button  
                    type='primary' 
                    className='button'  
                    onClick={() => addtoCart(service, null)}
                    style={{ margin:'auto',
                        minHeight: '64px',minWidth: '80%',
                        backgroundColor: '#FFF',color: '#426cb4',
                        fontWeight: '700',fontSize: '1.25rem'
                }}
                >Choose</Button>
            </Col>
        </Row>
    </Card>
    )
};

export default ServicesCard;