import React, { Component } from "react";
import { Row, Col, Modal, Typography, Form, Input, Select, message, Spin, Switch } from 'antd';

import axios from "axios"
import SelectCategoria from "../../Widgets/Inputs/SelectCategoria";
import { ImagesUploader } from "../../Widgets/Uploaders";

const { Title } = Typography;
const { Option } = Select;


class ModalServicios extends Component {


    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            tipo: 0
        }
    }

    formRef = React.createRef();

    componentDidMount() {
        if (this.props.servicio_id !== undefined) {
            this.get()
        }
    }

    /**
     * @memberof ModalServicios
     * @method get
     * @description Obtiene una Servicios de la DB
     *
     */
    get = () => {
        this.setState({ loading: true })
        axios.get('/servicio/' + this.props.servicio_id).then(response => {
            let servicio = response.data;
            this.formRef.current.setFieldsValue({
                nombre: servicio.nombre,
                descripcion: servicio.descripcion,
                sku: servicio.sku,
                activo: servicio.activo,
                precio: servicio.precio,
                tipo: servicio.tipo,
                categoria_id: servicio.categoria_id ? {
                    label: servicio.categoria_id?.nombre,
                    value: servicio.categoria_id?._id
                } : null,
                imagenes: servicio.imagenes ? servicio.imagenes.map(f => ({
                    uid: f.file,
                    name: f.name,
                    status: 'done',
                    fromDB: true,
                    url: axios.defaults.baseURL + "/servicios/" + servicio._id + "?imagen=" + f.file + "&Authorization=" + sessionStorage.getItem("token")
                })) : null
            })
            this.setState({ tipo: servicio.tipo, disabled: servicio.tipo === 3 || servicio.tipo === 4 })
        }).catch(error => {
            message.error('Error getting the service')
            console.log(error)
        }).finally(() => this.setState({ loading: false }))
    }


    /**
     * @memberof ModalServicios
     * @method onFinish
     * @description Se ejecuta al dar enter al formulario
     * 
     */
    onFinish = (values) => {

        const formData = new FormData()

        formData.appendMultiple({
            ...values,
            id: this.props.servicio_id,
            imagenes: undefined
        })

        if (values.imagenes) {
            values.imagenes.forEach(img => {
                if (!img.fromDB) formData.append('imagenes', img, img.name)
            })
        }

        if (this.props.servicio_id) {
            this.update(formData)
        } else {
            this.add(formData)
        }

    }

    /**
     * @memberof ModalServicios
     * @method add
     * @description Añade una servicio a la BD
     *
     */
    add = (values) => {
        axios.post('/servicios', values, {
            headers: {
                "Content-Type": 'multipart/form-data'
            }
        }).then(response => {
            message.success('Service created!')
            this.props.onClose()
        }).catch(error => {
            message.error('Error creating service')
            console.log(error)
        })
    }

    /**
     * @memberof ModalServicios
     * @method update
     * @description Actualiza la información de una Servicios
     * 
     */
    update = (values) => {
        this.setState({ loading: true })
        axios.put('/servicios/', values,
         {
            headers: {
                "Content-Type": 'multipart/form-data'
            }
        }).then(response => {
            message.success('Service updated!')
            this.props.onClose()
        }).catch(error => {
            message.error('Error updating service')
            console.log(error)
        }).finally(() => this.setState({ loading: false }))
    }


    render() {

        return (
            <Form
                layout="vertical"
                name="form-servicio"
                id="form-servicio"
                ref={this.formRef}
                onFinish={this.onFinish}

            >
                <Spin spinning={this.state.loading}>
                    <Row gutter={[16, 16]}>
                        <Col xs={24}>
                            <Form.Item
                                label="Name"
                                name="nombre"
                                rules={[{
                                    required: true,
                                    message: "Enter the name"
                                }]}
                            >
                                <Input placeholder="Name" disabled={this.state.disabled}></Input>
                            </Form.Item>
                        </Col>
                        <Col xs={24}>
                            <Form.Item
                                label="SKU"
                                name="sku"
                                rules={[{
                                    required: true,
                                    message: "Enter the SKU"
                                }]}
                            >
                                <Input placeholder="SKU" disabled={this.state.disabled}></Input>
                            </Form.Item>
                        </Col>

                        <Col xs={24}>
                            <Form.Item
                                label="Description"
                                name="descripcion"
                            >
                                <Input.TextArea placeholder="Description" rows={4} disabled={this.state.disabled}/>
                            </Form.Item>
                        </Col>

                        <Col xs={24} md={12}>
                            <Form.Item
                                label="Category"
                                name="categoria_id"
                                rules={[{
                                    required: true,
                                    message: "Select the category"
                                }]}
                            >
                                <SelectCategoria disabled={this.state.disabled}/>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                            <Form.Item
                                label="Type"
                                name="tipo"
                            >
                                <Select onChange={(value) => this.setState({ tipo: value })} value={this.state.tipo} disabled={this.state.disabled}>
                                    <Option value={0}>Cleanning</Option>
                                    <Option value={1}>Repair</Option>
                                    { this.state.tipo === 3 ? <Option value={3}>Shipping</Option> : null }
                                    { this.state.tipo === 4 ? <Option value={4}>PickUp & Delivey</Option> : null }
                                </Select>
                            </Form.Item>
                        </Col>
                        {this.state.tipo === 0 || this.state.tipo === 4 ?
                            <Col xs={24} md={12}>
                                <Form.Item
                                    label="Price"
                                    name="precio"
                                >
                                    <Input type="number" />
                                </Form.Item>
                            </Col> : null}

                        <Col xs={24} md={12}>
                            <Form.Item
                                label="Active"
                                name="activo"
                                valuePropName="checked"
                            >
                                <Switch disabled={this.state.disabled}/>
                            </Form.Item>
                        </Col>

                        <Col xs={24}>
                            <Form.Item
                                name="imagenes"
                                label="Images"
                            >
                                <ImagesUploader
                                disabled={this.state.disabled}
                                   listType="picture-card"
                                   showUploadList={true}
                                   imageCrop={false}
                                    onRemove={({ name, fromDB }) => {
                                        if (fromDB) axios.put(`/servicios`, {
                                            id: this.props.servicio_id,
                                            delete_image: name
                                        })
                                    }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Spin>
            </Form>
        )
    }
}



export default function (props) {
    const { visible = false, onClose = () => { }, servicio_id } = props

    return <Modal
        open={visible}
        onCancel={onClose}
        title={servicio_id ? "Edit Service" : "Create Service"}
        destroyOnClose={true}
        cancelText="Cancel"
        okText="Save"
        okButtonProps={{ form: 'form-servicio', key: 'submit', htmlType: 'submit' }}
    >
        <ModalServicios {...props} />
    </Modal>

}