import React, { Component } from "react";
import { Row, Col, Modal, Typography, Form, Input, Select, message, Spin, } from 'antd';

import axios from "axios"

class ModalAceptar extends Component {


    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            is_clinning: false
        }
    }

    formRef = React.createRef();


    /**
     * @memberof ModalAceptar
     * @method onFinish
     * @description Se ejecuta al dar enter al formulario
     * 
     */
    onFinish = (values) => {
        this.props.onSuccess(values)
    }

    render() {

        return (
            <Form
                layout="vertical"
                name="form-aceptar"
                id="form-aceptar"
                ref={this.formRef}
                onFinish={this.onFinish}

            >
                <Spin spinning={this.state.loading}>
                    <Row gutter={[16, 16]}>
                        <Col xs={24}>
                            <Form.Item
                                label="Cost"
                                name="costo"
                                rules={[{
                                    required: true,
                                    message: 'Enter the cost'
                                }]}
                            >
                                <Input type="number" />
                            </Form.Item>
                        </Col>
                    </Row>
                </Spin>
            </Form>
        )
    }
}



export default function (props) {
    const { visible = false, onClose = () => { } } = props

    return <Modal
        open={visible}
        onCancel={onClose}
        title={"Accept Quotation"}
        destroyOnClose={true}
        cancelText="Cancel"
        okText="Save"
        centered={true}
        okButtonProps={{ form: 'form-aceptar', key: 'submit', htmlType: 'submit' }}
    >
        <ModalAceptar {...props} />
    </Modal>

}