import React, { useState, useEffect } from 'react';
import { Select, message } from 'antd'
import axios from "axios"
import ModalClientes from '../../Admin/Clientes/ModalClientes'

const { Option } = Select

/**
 * @const SelectClientes
 * @description Select para los clientes registrados en el sistema
 */
const SelectClientes = (props) => {

    const {
        value,
        onChange,
        placeholder = "Select a client",
        onSelect = () => { },
        disabled = false,
        className = "",
        params = {},
        bordered,
        allowClear = true,
        onClear = () => { },
        mode = null,
        labelInValue,
        enableAdd
    } = props

    const [clientes, setClientes] = useState({
        data: [],
        page: 1,
        limit: 10,
        total: 0,
        search: null,
    })

    const [modalClientes, setModalClientes] = useState(false)


    /**
     * @const getClientes
     * @description Obitiene los usaurios
     */
    const getClientes = ({
        page,
        limit,
        search,
    } = clientes) => {

        axios.get('/clientes', {
            params: {
                page,
                limit,
                search,
                ...params,
            },
            headers: { Authorization: sessionStorage.getItem('token') }
        }).then(({ data }) => {
            setClientes(data)
        }).catch(error => {
            console.log("ERR, error", error)
            message.error(error?.response?.data?.message ?? 'Error getting clients')
        })
    }

    //componentDidMount
    useEffect(() => {
        getClientes()
    }, [])

    useEffect(() => {
        if (value)
            onChange(value?.value ?? value)
    }, [value])


    return (

        <>
            <Select
                bordered={bordered}
                className={className}
                disabled={disabled}
                placeholder={placeholder}
                allowClear={allowClear}
                showSearch
                filterOption={false}
                labelInValue
                mode={mode}
                onSearch={(search) => getClientes({ search })}
                onSelect={(cliente) => {

                    console.log(cliente)

                    if (cliente.value == "new" && enableAdd) {
                        setModalClientes(true)
                    }

                    if (mode === null && !labelInValue) {
                        onChange(cliente.value)
                        onSelect(cliente.value)
                    }
                    if (mode === null && labelInValue) {
                        onChange(cliente)
                        onSelect(cliente)
                    }
                }}
                onChange={(values) => {
                    if (mode === "multiple" && !labelInValue) {
                        let arr = values.map(e => e.value)
                        onChange(arr)
                        onSelect(arr)
                    }

                    if (mode === "multiple" && labelInValue) {
                        onChange(values)
                        onSelect(values)
                    }
                }}
                value={value}
                onClear={() => {
                    onClear()
                    onChange(undefined)
                    onSelect(undefined)
                }}
            >
                {enableAdd ? <Option value={"new"}>Add new client</Option> : null}
                {
                    clientes?.data?.map(({ _id, nombre, apellidos, apellido_materno }) => <Option value={_id} key={_id}>{nombre ?? "-"} {apellidos ?? ""}</Option>)
                }
            </Select>
            {
                enableAdd ? <ModalClientes
                    visible={modalClientes}
                    onClose={() => {
                        setModalClientes(false)
                        getClientes()
                        
                    }}
                /> : null
            }

        </>
    );
}



export default SelectClientes;