
import React, { Component } from 'react'
import { DatePicker, Divider, Form, Input, Select, Row, Col, message, Button } from 'antd';
import { getResponseError } from '../../../Utils';

import axios from "axios";
import Checkbox from 'antd/es/checkbox/Checkbox';
const { Option } = Select

/**
 *
 *
 * @export
 * @class StepPackage
 * @extends {Component}
 */
export default class StepPackage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            disabled: false,
            packages: [
                {
                    weight: {
                        "value": 1.1,
                        "unit": "pound"
                    },
                    dimensions: {
                        "height": 14,
                        "width": 10,
                        "length": 5,
                        "unit": "inch"
                    }
                }
            ]
        }
    }
    formRef = React.createRef();


    handleFieldsChange = (_, allFields) => {
        // Verificar si todos los campos requeridos están llenos
        const isFilled = allFields?.every((field) => field.errors.length == 0);
        this.props.setDisabled(!isFilled);
    };
    onValuesChange = (changedValues, allValues) => {
        this.props.update('package', allValues)
    }
    onChangeDefault = ({ target }) => {
        this.setState({ disabled: target.checked })
    }

    render() {
        return (
            <>
                <Form
                    layout={"vertical"}
                    ref={this.formRef}
                    initialValues={this.props.data.package}
                    onFieldsChange={this.handleFieldsChange}
                    validateTrigger="onChange"
                    onValuesChange={this.onValuesChange}
                    onFinish={this.props.sendData}
                >
                    <Row>
                        <Form.Item
                            name="default_package"
                            valuePropName='checked'
                        >
                            <Checkbox onChange={this.onChangeDefault}>Use Configuration default</Checkbox>
                        </Form.Item>
                    </Row>
                    <Row gutter={[8, 8]}>
                        <Divider>Weight</Divider>

                        <Col span={12}>
                            <Form.Item
                                name="weight"
                                label="Weight"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter the Weight ',
                                    },
                                ]}
                            >
                                <Input disabled={this.state.disabled} className="width-100" />
                            </Form.Item>

                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="weight_unit"
                                label="Unit"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter the weight Unit',
                                    },
                                ]}
                            >
                                <Select disabled={this.state.disabled}>
                                    <Option value="ounce">ounce</Option>
                                    <Option value="pound">pound</Option>
                                    <Option value="gram">gram</Option>
                                    <Option value="kilogram">kilogram</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={[8, 8]}>
                        <Divider>Dimensions</Divider>
                        <Col span={6}>
                            <Form.Item
                                name="height"
                                label="Height"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter the height ',
                                    },
                                ]}
                            >
                                <Input disabled={this.state.disabled} className="width-100" />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                name="width"
                                label="Width"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter the width',
                                    },
                                ]}
                            >
                                <Input disabled={this.state.disabled} className="width-100" />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                name="lenght"
                                label="Lenght"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter the length',
                                    },
                                ]}
                            >
                                <Input disabled={this.state.disabled} className="width-100" />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                name="dimension_unit"
                                label="Unit"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter the dimension Unit',
                                    },
                                ]}
                            >
                                <Select disabled={this.state.disabled} >
                                    <Option value="inch">inch</Option>
                                    <Option value="centimeter">centimeter</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>


                </Form>
            </>

        )
    }
}