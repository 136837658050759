import React, { Component, useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
	Avatar,
	Layout,
	Col,
	Row,
	Image,
	Space,
	Button,
	Card,
	Flex,
	Typography,
	Divider,
	message,
	Badge,
	Carousel,
	Spin,
} from "antd";

import { ShoppingOutlined } from "@ant-design/icons";
import ModalCotizacion from "./Cotizacion/index";
import ServicesItem from "./ServicesItem";
import { SiHotjar } from "react-icons/si";
import { FaCalendarCheck, FaTruck, FaSoap, FaSmileWink } from "react-icons/fa";

import { useCookies } from "react-cookie";
import { User } from "../../Hooks/Logged";
import { Carrito, SetCarrito } from "../../Hooks/Carrito";
import FloatingButton from "../Widgets/Floating Button/FloatingButton";
import axios from "axios";
import { renderImage } from "../Utils";
import "../../Styles/Modules/Landing.scss";
import MyHeader from "./Header";
import FooterSne from "./Footer";
import ProductCard from "./ProductItem";

import WOW from "wowjs";

import { ElfsightWidget } from "react-elfsight-widget";

const { Content, Footer } = Layout;
const { Title, Text } = Typography;

const Section = (props) => <section {...props}>{props.children}</section>;

class LandingPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			serviciosLoading: false,
			visible: false,
			menu_active: 0,
			visible_cart: false,
			services: [],
			productos: {
				data: [],
				limit: 10,
				page: 1,
				total: 0,
				pages: 0,
				filters: [],
			},
			servicio_cotizar: {},
			productosHot: [],
		};
	}

	componentDidMount() {
		new WOW.WOW({
			live: true,
		}).init();

		this.getServicios();
		this.getProductos();
		this.getProductosHot();
		window.scrollTo({ top: 0 });

		//const videoElement = document.getElementById("video-banner");
		//const videoText = document.getElementById("text-banner");

		// Verifica si el elemento de video existe
		// if (videoElement) {
		//   // Comienza la reproducción del video
		//   videoElement.addEventListener("mouseenter", () => {
		//     videoElement
		//       .play()
		//       .then(() => {
		//         videoElement.muted = true;

		//         console.log("Video empezó a reproducirse exitosamente");
		//       })
		//       .catch((error) => {
		//         console.error("Error al intentar reproducir el video:", error);
		//       });
		//   });

		//   videoElement.addEventListener("timeupdate", () => {
		//     const currentTime = videoElement.currentTime;

		//     if (currentTime > 0 && currentTime < 5)
		//       videoText.classList.add("fadeInUp");
		//     // Eliminar la clase 'wow' cuando el video no está entre 0 y 5 segundos
		//     else videoText.classList.remove("fadeInUp");

		//     // Añadir otra clase 'wow' para hacer efecto de entrada y salida a los 5 segundos
		//     if (currentTime >= 5 && currentTime >= 45)
		//       videoText.classList.add("fadeOutDown");
		//     // Eliminar la clase 'wow-entrada-salida' cuando el video no está entre 5 y 10 segundos
		//     else videoText.classList.remove("fadeOutDown");
		//   });
		// }

		//codigo para ocultar el boton de widget
		setTimeout(()=>console.log( document.querySelector(".WidgetBackground__Content-sc-1ho7q3r-2.ciCnpO>a").style.display='none' ) ,3000 )
		
	}
	setVisible = () => {
		this.setState({ visible_cart: true });
	};
	onClickMenu = (e) => {
		this.setState({ menu_active: e.key });
	};

	/**
	 *
	 *
	 * @memberof LandingPage
	 * @description Obtiene los servicios activos para ser mostrados en la pagina
	 */
	getServicios = () => {
		axios
			.get("/services", {
				params: {
					active: true,
				},
			})
			.then((res) => {
				this.setState({ services: res.data.data });
			})
			.catch((error) => {});
	};
	/**
	 *
	 * @memberof Productos
	 * @method getProductos
	 * @description Obtiene la lista de productos
	 */
	getProductos = async (
		{
			page = this.state.productos.page,
			limit = this.state.productos.limit,
		} = this.state.productos,
	) => {
		axios
			.get("/products", {
				params: {
					limit,
					page,
					store: true,
				},
			})
			.then(({ data }) => {
				this.setState({ productos: data });
			})
			.catch((res) => {
				console.log("res", res);
			});
	};

	/**
	 *
	 * @memberof Productos
	 * @method getProductosHot
	 * @description Obtiene la lista de productos
	 */
	getProductosHot = () => {
		axios
			.get("/products", {
				params: {
					limit: 3,
					page: 1,
					hot: true,
					store: true,
				},
			})
			.then(({ data }) => {
				this.setState({ productosHot: data.data });
			})
			.catch((res) => {
				console.log("res", res);
			});
	};

	/**
	 *
	 *
	 * @memberof LandingPage
	 * @description Añade un elemento al carrito
	 */
	addToCart = (servicio = {}) => {
		if (Object.keys(servicio).length > 0) {
			//abrir cotizador
			if (servicio.tipo == 1) {
				this.setState({ servicio_cotizar: servicio, visible: true });
			} else {
				this.sendQuote(servicio);
			}
		}
	};

	/**
	 * @memberof LandingPage
	 * @method sendQuote
	 * @description Envia un elemento al carrtio de compra y lo guarda en la BD
	 */
	sendQuote = (servicio) => {
		this.setState({ serviciosLoading: true });
		const cart = this.props.cookies.cart;
		axios
			.post("/carrito", {
				venta_id: cart,
				detalles: {
					servicio_id: servicio,
					cantidad: 1,
				},
				cliente_id: this.props.user?.cliente_id,
			})
			.then(({ data }) => {
				message.success("Item added to cart");
				this.props.setCookie("cart", data._id);
				this.props.setCart(data);
			})
			.catch((error) => {
				console.log(error);
				message.error(error?.response?.data?.message ?? "Error adding to cart");
			})
			.finally(() => this.setState({ serviciosLoading: false }));
	};

	render() {
		return (
			<>
				<Layout className="landing " style={{ height: "100vh" }}>
					<MyHeader
						{...this.props}
						openDrawer={this.state.openDrawer}
						setOpenDrawer={(value) => this.setState({ openDrawer: value })}
					/>
					<Content>
						<Section className="content-bg-shoe" id="main">
							<Row gutter={[12,12]} justify="start" align={"right"} className="mt-3 pd-2 video-text">
								<Col xs={24} sm={24} md={24} lg={12} className="">
									<Title className="banner-title">
										Professional <br/> Shoe Cleanning <br/>services
									</Title>
									<Title className="banner-text" >
										Say Hello To Clean Shoes
									</Title>
									<Space className="banner-text mt-1 mb-1">
										<Button 
											size="large" 
											type="primary" 
											style={{width: "150px", fontWeight: "bold", height: "52px"}}
											onClick={() => {
										        const section = document.getElementById("services");
										        if (section) section.scrollIntoView({ behavior: "smooth", block: "start" });       
										    }}
										>
											Discover Now
										</Button>
										<Button 
											size="large" 
											style={{border: "1px solid gray" ,width: "150px", fontWeight: "bold", height: "52px"}}
											onClick={() => {
										        const section = document.getElementById("process");
										        if (section) section.scrollIntoView({ behavior: "smooth", block: "start" });       
										    }}
										>
											Learn More
										</Button>
									</Space>
								</Col>
								<Col xs={24} sm={24} md={24} lg={12} className="">
									<img
										className="img"
										style={{ width:'100%', height:'100%' ,  borderRadius: '5%', maxHeight: "900px"}}
										src="./img/bannerimg.jpg"
									></img>
								</Col>
							</Row>

						</Section>

						<div className="bg-gradient-color width-100">

							{/* lets talk */}
							<Section className="section section-reduce clearfix " id="about" >
								<div className="section-container" style={{ paddingTop:'1.5rem', position: "relative" }} >
									<Row className="center">
										{this.state.productosHot.length > 0 ? (
											<Card className="hot-banner">
												<Card.Grid className="hs-grid hs-hot" hoverable={false}>
													<Row>
														<Col span={12}>
															<SiHotjar style={{ fontSize: 50, color: "#fff" }} />
														</Col>
														<Col span={12}>
															<Row className="hs-big-text">
																<Col span={24}>hot</Col>
																<Col span={24}>sale</Col>
															</Row>
														</Col>
													</Row>
												</Card.Grid>
												{this.state.productosHot.map((e) => (
													<Card.Grid
														className={`hs-grid prod-${this.state.productosHot.length}`}
														hoverable={false}
													>
														<Row>
															<Col span={12}>
																<Image
																	width={60}
																	preview={false}
																	src={renderImage(e._id, e.imagenes, "product")}
																/>
															</Col>
															<Col span={12}>
																<Row>
																	<Col span={24} className="hs-title">
																		<Text ellipsis>{e.nombre}</Text>
																	</Col>
																	<Col span={24} className="hs-amount">
																		$ {e.variante?.precio?.toMoney() ?? "-"}
																	</Col>
																</Row>
															</Col>
														</Row>
													</Card.Grid>
												))}
											</Card>
										) : null}
									</Row>

									<Row
										gutter={[16, 16]}
										justify="center"
										align={"middle"}
										className="width-100 "
									>
										<Col
											sm={{ span: 24, order: 2 }}
											xs={{ span: 24, order: 2 }}
											md={{ span: 24, order: 2 }}
											lg={{ span: 12, order: 1 }}
											style={{position:'unset', minHeight: "230px"}}
											
										>
											<img
												className="img-middle "
												//style={{ position:"absolute" , bottom:'0'}}
												src="../../../img/foto-max-pagina-2.png"
											></img>
										</Col>
										<Col
											sm={{ span: 24, order: 1 }}
											xs={{ span: 24, order: 1 }}
											md={{ span: 24, order: 1 }}
											lg={{ span: 12, order: 2 }}
											className=""
										>
											
											<Title level={2} className='section-title-lucky'>
												Lets Talk about Us
											</Title>
											
											<Text className="section-description  flex-right mb-1">
												Miami's #1 shoe cleaning company that is commited to make
											 	cleaner and cooler steps by bringing the best service to you shoes, sneakers or anything
											 	to walk on.
											</Text>
											<Text className="section-description  flex-right mb-1">
												Our team has been professionally trained to clean any
												type of materials and shoes. More than 1,000 pairs
												cleaned.
											</Text>
										</Col>
									</Row>
								</div>
							</Section>

							{/* <img className='bubble bubble-pink' src="../../../img/background/bubble-pink.svg"></img> */}
							
							
							{/* cleaning areas */}
							<Section
								className="section section-reduce clearfix p-1"
								id="diagram"
							>
								<Row span={12} offset={12}>
									<Title level={1} className="title">
										Types of shoes we clean
									</Title>
								</Row>
								<div className="section-container" style={{backgroundColor: "#446cb2"}}>
									<Row
										gutter={[16, 16]}
										justify={"center"}
										className="width-100"
										
									>
										<Col xxl={6} xl={6} lg={8} md={12} xs={24} className="center wow pulse" data-wow-duration="2s" data-wow-delay="0.2s">
											<Card
												size="small"
												className="card-shoe bg-transparent"
												cover={
													<img
														className="img-6"
														src="../../../img/shoes/1.png"
													></img>
												}
											>
												<Text className="img-title">Sneakers</Text>
											</Card>
										</Col>
										<Col xxl={6} xl={6} lg={8} md={12} xs={24} className="center">
											<Card
												className="card-shoe bg-transparent"
												cover={
													<img
														className="img-6"
														src="../../../img/shoes/5.png"
													></img>
												}
											>
												<Text className="img-title">Running Shoes</Text>
											</Card>
										</Col>
										<Col xxl={6} xl={6} lg={8} md={12} xs={24} className="center wow">
											<Card
												className="card-shoe bg-transparent"
												cover={
													<img
														className="img-6"
														src="../../../img/shoes/2.png"
													></img>
												}
											>
												<Text className="img-title">Loafers</Text>
											</Card>
										</Col>
										<Col
											xxl={6}
											xl={6}
											lg={8}
											md={12}
											xs={24}
											className="center wow pulse"
											data-wow-duration="2s"
											data-wow-delay="0.25s"
										>
											<Card
												className="card-shoe bg-transparent"
												cover={
													<img
														className="img-6"
														src="../../../img/shoes/4.png"
													></img>
												}
											>
												<Text className="img-title">dressing Shoes</Text>
											</Card>
										</Col>
										<Col
											xxl={6}
											xl={6}
											lg={8}
											md={12}
											xs={24}
											className="center"
										>
											<Card
												className="card-shoe bg-transparent"
												cover={
													<img
														className="img-6"
														src="../../../img/shoes/3.png"
													></img>
												}
											>
												<Text className="img-title">Heels</Text>
											</Card>
										</Col>

										<Col
											xxl={6}
											xl={6}
											lg={8}
											md={12}
											xs={24}
											className="center wow pulse"
											data-wow-duration="2s"
											data-wow-delay="0.3s"
										>
											<Card
												className="card-shoe bg-transparent"
												cover={
													<img
														className="img-6"
														src="../../../img/shoes/6.png"
													></img>
												}
											>
												<Text className="img-title">Boots </Text>
											</Card>
										</Col>
										<Col
											xxl={6}
											xl={6}
											lg={8}
											md={12}
											xs={24}
											className="center"
										>
											<Card
												className="card-shoe bg-transparent"
												cover={
													<img
														className="img-6"
														src="../../../img/shoes/7.png"
													></img>
												}
											>
												<Text className="img-title">Sandals</Text>
											</Card>
										</Col>
									</Row>
								</div>
							</Section>


							{/* Our Services */}
							<Section className="section" id="services">
								<Row span={12} offset={12}>
									<Title level={1} className="title">
										Our Services
									</Title>
								</Row>
								<div className="section-container services">

									<Spin spinning={this.state.serviciosLoading}>
										<Row gutter={[12, 12]} justify={"center"}>
											{this.state.services.map((service) => {
												return service.tipo === 0 ? (
													<Col
														xxl={6}
														xl={8}
														lg={8}
														md={12}
														xs={24}
														
													>
														<ServicesItem
															service={service}
															addtoCart={() => this.addToCart(service)}
														/>
													</Col>
												) : null;
											})}
										</Row>
									</Spin>
								</div>
							</Section>

							{this.state.productos.length > 0 ? (
								<Section className="section section-reduce">
									<div className="section-container">
										<Divider orientation="left">PRODUCTS</Divider>
										<Row
											gutter={[16, 16]}
											align={"middle"}
											justify={"center"}
											className="width-100"
										>
											{this.state.productos?.data?.map((producto) => (
												<Col xl={6} lg={8} md={12} xs={24} className="center">
													<ProductCard
														producto={producto}
														onClick={() =>
															this.props.navigate(
																"product/detail/" + producto?._id,
															)
														}
													/>
												</Col>
											))}
										</Row>
										<Row
											justify={"center"}
											align={"center"}
											className="mb-2 mt-2"
										>
											<Button
												type="primary"
												className="button"
												onClick={() => this.props.navigate("/store")}
											>
												{" "}
												View more
											</Button>
										</Row>
									</div>
								</Section>
							) : null}

							{/* how does it work  */}
							<Section className=" pd-1" id="process">
								<div className="section-container section-how-work" style={{maxWidth: "1400px", margin: "auto"}}>
									<Row gutter={[16,16]}>
										<Col xs={24} md={14} className="">
											<Title level={1} className="main-title-how">
												How does it <br/> work
											</Title>
											<div className="main-text-how"> Create an account or login to start <br/> booking</div>
											<Button 
												className="mb-1"
												size="large"
												type="primary"
												style={{width: "200px", fontWeight: "bold", height: "52px"}}
												onClick={() => {
											        this.props.navigate("/login")   
											    }}
											>
												Join Now
											</Button>
											<img src="../../../img/6.png" className="image-how-shoe"></img>
										</Col>
										<Col xs={24} md={9} className="">
											<Row>
												<Col span={24}>
													<Title level={1} className="title-how">
														Step 1
														<Avatar
															size={42}
															style={{ marginLeft:'25px',backgroundColor: "#446cb2" }}
															icon={ <FaCalendarCheck style={{ position: "inline", top: 3 }}/>}
														/>
													</Title>
													<Text className="text-how">
														Create an order and schedule the pick-up date and time of your preference through our App or Website.
													</Text>
													<Divider style={{ borderColor:'#E7E7E9' }} className="divider-how"/>
												</Col>
												<Col span={24}>
													<Title level={1} className="title-how">
														Step 2
														<Avatar
															size={42}
															style={{ marginLeft:'25px',backgroundColor: "#446cb2" }}
															icon={<FaTruck style={{ position: "inline", top: 3 }} />}
														/>
													</Title>
													<Text className="text-how">
														A specialist will collect your service in detail. Your shoes are then transported to our SneaCare Center.
													</Text>
													<Divider style={{ borderColor:'#E7E7E9' }} className="divider-how"/>
												</Col>
												<Col span={24}>
													<Title level={1} className="title-how">
														Step 3
														<Avatar
															size={42}
															style={{ marginLeft:'25px',backgroundColor: "#446cb2" }}
															icon={<FaSoap style={{ position: "inline", top: 3 }} />}
														/>
													</Title>
													<Text className="text-how">
														Our four-step cleaning process includes diagnosis, preparation, cleaning, and detailing. All shoes are hand washed by trained SneaCare technicians using 
														only specialized products and finished with a final quality check by our service technician.
													</Text>
													<Divider style={{ borderColor:'#E7E7E9' }} className="divider-how"/>
												</Col>
												<Col span={24}>
													<Title level={1} className="title-how">
														Step 4
														<Avatar
															size={42}
															style={{ marginLeft:'25px',backgroundColor: "#446cb2" }}
															icon={<FaSmileWink style={{ position: "inline", top: 3 }} />}
														/>
													</Title>
													<Text className="text-how">
														our clean, fresh sneakers are photographically documented and packaged for our delivery team to deliver to you. You will be notified with a message to your cell phone.
													</Text>
													
												</Col>

											</Row>
													
										</Col>
									</Row>
								</div>
							</Section>

							<Section className="pd-1 section section-reduce" id="services">
								<Title level={1} className="title mt-3 mb-2">
									Turn Around Time<br/>
									3-4 Business Days
								</Title>
								<div className="section-container" style={{maxWidth: "1400px", margin: "auto"}}>
									<Title level={1} className="title p-0 mb-2" >
										Repair Quote
									</Title>
									
									<Spin spinning={this.state.serviciosLoading}>
										<Row gutter={[12, 12]} justify={"center"}>
											{this.state.services.map((service) => {
												return service.tipo === 1 ? (
													<Col
														span={6}
														xxl={6}
														xl={6}
														lg={8}
														md={12}
														xs={24}
														className="center"
													>
														<ServicesItem
															service={service}
															addtoCart={() => this.addToCart(service)}
														/>
													</Col>
												) : null;
											})}
										</Row>
									</Spin>
								</div>
							</Section>


							{/*Your Shoes Our Priority*/}
							<Section className="section section-reduce clearfix pd-1" id="about">
								<Row
									
									
									className="width-100"
								>
									<Col sm={{ span: 24, order: 2 }} xs={{ span: 24, order: 2 }} md={{ span: 12, order: 1 }} lg={{ span: 12, order: 1 }}>
										<img
											className="img"
											style={{ width: '100% ' }}
											src="../../../img/taller.jpg"
										></img>
									</Col>
									<Col sm={{ span: 24, order: 1 }} xs={{ span: 24, order: 1 }} md={{ span: 12, order: 2 }} lg={{ span: 10, order: 2 }}>
										
										<Title level={1} className='section-title-lucky pl-1' >
										 	Your shoes, <br/>
										 	our priority
										</Title>
										
										<Text className="section-description  flex-right mb-1 pl-1">
											Located in the center of Miami at 2749 Coral Way,
											Miami, FL, 33145.
									 	</Text>
										<Text className="section-description  flex-right mb-1 pl-1">
											You can drop off your shoes directly at the store from 10am-6pm Mon-Fri or
											choose our Pick up & Delivery service.
										 </Text>
									</Col>
								</Row>
							</Section>


							<Section
								className="width-100 hover "
								style={{ marginBottom: "-7px" }}
								onClick={() => {
									window.open(
										"https://www.instagram.com/sneacaremiami/?igshid=OGQ5ZDc2ODk2ZA%3D%3D",
										"_blank",
									);
								}}
							>
								<Carousel
									infinite={true}
									slidesPerRow={4}
									dots={false}
									autoplay
									autoplaySpeed={3000}
								>
									<img src="/img/carousel/shoe1.jpg" />
									<img src="/img/carousel/shoe2.jpg" />
									<img src="/img/carousel/shoe3.jpg" />
									<img src="/img/carousel/shoe4.jpg" />
									<img src="/img/carousel/shoe6.jpg" />
									<img src="/img/carousel/shoe5.jpg" />
									<img src="/img/carousel/shoe7.jpg" />
									<img src="/img/carousel/shoe8.jpg" />
								</Carousel>
							</Section>


								{/*Google REVIEWS*/}
							<Section  className="width-100 hover ">
									<Divider orientation="center"
												
											>
												<div style={{fontFamily:"Roboto", textTransform:'none',color:'#446cb2'}}>
														Customer Reviews
												</div>
												<div style={{fontFamily:"Roboto", textTransform:'none', fontWeight:'500',fontSize: '1.25rem'}}>
													 More than 100 google Reviews with 5 stars
												</div>
										</Divider>
									<Row   style={{textAlign: 'center', justifyContent: 'center'}} >
										<ElfsightWidget   className="widget-reviews" widgetID="6b226c19-c2aa-4d20-85bd-fa58ec2a649b"/>
									</Row>
							</Section>
						</div>
						<FooterSne />
					</Content>

					{this.props.cart?.detalles?.length > 0 ? (
						<FloatingButton
							title="Nuevo registro"
							onClick={() => this.setState({ openDrawer: true })}
							icon={
								<Badge
									className={`badge-cart`}
									count={this.props.cart?.detalles?.length}
								>
									<ShoppingOutlined
										style={{ color: "white", fontSize: "40px" }}
									/>
								</Badge>
							}
						/>
					) : null}
				</Layout>
				<ModalCotizacion
					visible={this.state.visible}
					onFinish={(data) => {
						this.addToCart(this.state.servicio_cotizar);
						this.setState({ visible: false, servicio_cotizar: {} });
						window.location.reload();
					}}
					servicio={this.state.servicio_cotizar}
					onCancel={() => this.setState({ visible: false })}
				/>
			</>
		);
	}
}

export default (props) => {
	const navigate = useNavigate();

	let cart = useContext(Carrito);
	let setCart = useContext(SetCarrito);

	let user = useContext(User);
	const [cookies, setCookie] = useCookies(["cart"]);

	return (
		<LandingPage
			{...props}
			cart={cart}
			setCart={setCart}
			cookies={cookies}
			setCookie={setCookie}
			user={user}
			navigate={navigate}
		/>
	);
};
