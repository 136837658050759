import React, { Component } from 'react';
import { Row, Col, Typography, Layout, message, Breadcrumb, Tabs } from 'antd'
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { WarningFilled, CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';


import Pagos from "./Tabs/Pagos";
import OrdenesList from "./Tabs/OrdenesList";
import Direcciones from "./Tabs/Direcciones";
import ModalCliente from '../../Clientes/ModalClientes';
import { CardSimpleInfo } from '../../../Widgets/Cards';
import usePermissions from "../../../../Hooks/usePermissions";
import PageHeader from '../../../Widgets/PageHeader/PageHeader';


//css
import '../../../../Styles/Modules/Admin/CRM.scss'


const { Content } = Layout;
const { Title, Text } = Typography
const moment = require('moment')
moment.locale('es');



/**
 *
 *
 * @export
 * @class ClientesDetalles
 * @extends {Component}
 * @description Vista donde se muestra la informacion de un cliente
 */
class ClientesDetalles extends Component {

    back = () => {
        this.props.navigate(-1);
    }

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            cliente_id: this.props.params.cliente_id,
            cliente: {

            },

        }
    }


    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token')
        if (this.props.params.cliente_id) {
            this.getCliente()
        }
    }


    /**
     *
     * @memberof DetalleInversion
     * 
     * @method getCliente
     * @description Obtiene informacion del Cliente
     */
    getCliente = () => {
        this.setState({ loading: true })
        axios.get('/clientes/' + this.state.cliente_id, {
            params: {
                crm: true,
                pais_id: true,
                estado_id: true,
            }
        }).then(response => {

            let cotizaciones_pendiente = (response.data?.cotizaciones?.find(e => e._id === 0))?.total ?? 0
            let cotizaciones_aceptadas = (response.data?.cotizaciones?.find(e => e._id === 1))?.total ?? 0
            let cotizaciones_rechazadas = (response.data?.cotizaciones?.find(e => e._id === 2))?.total ?? 0

            this.setState({
                cliente: response.data,
                total_ventas: response.data?.total_ventas?.total ?? 0,
                cotizaciones_pendiente,
                cotizaciones_aceptadas,
                cotizaciones_rechazadas,

            })

        }).catch(error => {
            console.log("error", error);
            message.error("Error getting customer information")
        }).finally(() => this.setState({ loading: false }))
    }


    render() {

        const { cliente } = this.state

        return (
            <>
                <PageHeader
                    className="admin-page-header"
                    title={<Breadcrumb
                        className="breadcrumb-header"
                        items={[
                            {
                                title: "Customer Detail",
                                onClick: () => this.props.navigate('/admin/crm/clientes/listado'),
                                className: "link"
                            },
                            {
                                title: `Detail of client ${cliente?.nombre}`,
                            },
                        ]}
                    />}
                >
                </PageHeader>
                <Content className="pd-1">
                    <Row gutter={[16, 16]}>
                        <Col xs={24} md={8} className="grid">
                            <CardSimpleInfo
                                title="Client"
                                content={
                                    <Row className="mt-1">
                                        <Col span={24}>
                                            <Text strong>Client since {moment(cliente.createdAt).format("MM/DD/YYYY")}</Text>
                                        </Col>
                                        <Col span={24}>
                                            <Text>{cliente.nombre} {cliente.apellido}</Text>
                                        </Col>
                                        <Col span={24}>
                                            <Text>{cliente.telefono}</Text>
                                        </Col>
                                        <Col span={24}>
                                            <Text>{cliente.email}</Text>
                                        </Col>
                                    </Row>
                                }
                            />
                        </Col>
                        <Col xs={24} md={8} className="grid">
                            <CardSimpleInfo
                                title="Quotations"
                                content={
                                    <Row gutter={[8, 8]} className="mt-1">
                                        <Col span={4} className="center">
                                            <WarningFilled style={{ fontSize: "28px", color: "#faad14" }} />
                                        </Col>
                                        <Col span={10}>
                                            <Text>In Progress</Text>
                                        </Col>
                                        <Col span={10} className="center">
                                            <Text>{(this.state.cotizaciones_pendiente ?? 0)?.toMoney(true, false)}</Text>
                                        </Col>
                                        <Col span={4} className="center">
                                            <CheckCircleFilled style={{ fontSize: "28px", color: "#52c41a" }} />
                                        </Col>
                                        <Col span={10}>
                                            <Text>Finished/Delivered</Text>
                                        </Col>
                                        <Col span={10} className="center">
                                            <Text>{(this.state.cotizaciones_aceptadas ?? 0)?.toMoney(true, false)}</Text>
                                        </Col>
                                        <Col span={4} className="center">
                                            <CloseCircleFilled style={{ fontSize: "28px", color: "#ff4d4f" }} />
                                        </Col>
                                        <Col span={10}>
                                            <Text>Canceled</Text>
                                        </Col>
                                        <Col span={10} className="center">
                                            <Text>{(this.state.cotizaciones_rechazadas ?? 0)?.toMoney(true, false)}</Text>
                                        </Col>

                                    </Row>
                                }
                            />
                        </Col>
                        <Col xs={24} md={8} className="grid">
                            <CardSimpleInfo
                                title="Total Sales"
                                info={`$ ${(this.state?.total_ventas ?? 0).toMoney(true)} USD`}
                            />
                        </Col>
                    </Row>

                    <Tabs
                        defaultActiveKey="1"
                        items={[
                            {
                                key: '1',
                                label: 'Quotations',
                                children: <OrdenesList cliente_id={this.props.params.cliente_id} />,
                            },
                            {
                                key: '2',
                                label: 'Addresses',
                                children: <Direcciones cliente_id={this.props.params.cliente_id} />,
                            },
                            {
                                key: '3',
                                label: 'Sales',
                                children: <Pagos cliente_id={this.props.params.cliente_id} />,
                            },
                        ]}
                    />

                </Content>
                <ModalCliente
                    visible={this.state.modal_visible}
                    cliente_id={this.state.cliente_id}
                    onClose={() => {
                        this.setState({ modal_visible: false })
                        this.getCliente()
                    }}

                />

            </>
        )
    }
}


export default function (props) {

    const params = useParams()
    const navigate = useNavigate()


    return <ClientesDetalles  {...props} params={params} navigate={navigate}
        {...usePermissions(null, {
            editarCliente: ["clientes", "edit"],
            deleteCliente: ["clientes", "delete"],
            createCliente: ["clientes", "create"],
        })}
    />
}