import React, { Component } from 'react';
import { Row, Col, Typography, Button, Spin, Layout, Space, message, Card, List, Breadcrumb, Badge, Tooltip } from 'antd'
import axios from 'axios';
import { useNavigate, useParams } from "react-router-dom";
import { CloseCircleFilled, CheckCircleFilled, CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import SquareLogo from '../../Widgets/Icons/Square';
import Logged, { User } from "../../../Hooks/Logged";
import FloatingButton from "../../Widgets/Floating Button/FloatingButton";

//componentes
import ModalVariantes from "./ModalVariantes"
import PageHeader from '../../Widgets/PageHeader/PageHeader';
import usePermissions from '../../../Hooks/usePermissions';
import { ButtonDelete, ButtonEdit } from '../../Widgets/Buttons';
import { getResponseError } from '../../Utils';



const { Content } = Layout;
const { Text, Title } = Typography

/**
 *
 *
 * @export
 * @class Variantes
 * @extends {Component}
 * @description Vista donde se listan todos los productos
 */
class Variantes extends Component {
    static contextType = Logged;

    back = () => {
        this.props.history.goBack();
    }


    /**
     *Creates an instance of Productos.
     * @param {*} props
     * @memberof Productos
     */
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            producto_id: this.props.params.producto_id,
            producto: {},
            variantes: {
                data: [],
                limit: 10,
                page: 1,
                total: 0,
                pages: 0,
                filters: []
            },
            producto_id: null
        }
    }

    componentDidMount() {

        if (this.props.params.producto_id) {
            this.getProducto()
            this.getVariantes()
        }

    }

    componentDidUpdate(prevProps) {
        if (this.props.search != prevProps.search) {
            this.getVariantes()
        }
    }

    /**
    * @method getProducto
    * @description Obtener al producto
    */
    getProducto = () => {
        axios.get('/productos/' + this.props.params.producto_id)
            .then(({ data }) => {
                this.setState({ producto: data })
            }).catch(error => {
                message.error(getResponseError(error.response, 'Error getting the product'))
            })
    }

    /**
    *
    * @memberof Variantes
    * @method getVariantes
    * @description Obtiene una lista de las variantes del producto
    */
    getVariantes = async ({
        page = this.state.variantes.page,
        limit = this.state.variantes.limit,
        search = this.props.search,
    } = this.state.variantes) => {

        this.setState({ loading: true })
        axios.get('/variantes', {
            params: {
                limit,
                page,
                search,
                producto_id: this.props.params.producto_id
            }
        })
            .then(({ data }) => {
                this.setState({ variantes: data })
            })
            .catch(res => {
                message.error(res.response?.data?.message ?? "Error")
            })
            .finally(() => this.setState({ loading: false }))
    }




    render() {

        let { producto } = this.state;

        return (
            <>
                <Spin spinning={this.state.loading}>
                    <PageHeader
                        className="admin-page-header"
                        title={<Breadcrumb
                            className="breadcrumb-header"
                            items={[
                                {
                                    title: "Products",
                                    onClick: () => this.props.navigate('/admin/productos'),
                                    className: "link"
                                },
                                {
                                    title: `Variants of ${producto.nombre}`,
                                },
                            ]}
                        />}
                    >

                    </PageHeader>

                    <Content className="admin-content content-bg pd-1">
                        <List
                            className="component-list responsive"
                            itemLayout="horizontal"
                            locale={{ emptyText: "Variants not found" }}
                            dataSource={this.state.variantes.data}
                            pagination={{
                                current: this.state.variantes.page,
                                pageSize: this.state.variantes.limit,
                                total: this.state.variantes.total,
                                hideOnSinglePage: false,
                                position: 'bottom',
                                onChange: (page, limit) => this.getProductos({ page, limit })

                            }}
                            header={<Row className="component-list-header width-100 pr-1 pl-1" >
                                <Col xs={12} md={6} className="center">
                                    <Text strong>Name</Text>
                                </Col>
                                <Col xs={12} md={7} className="center">
                                    <Text strong>Description</Text>
                                </Col>
                                <Col xs={12} md={5} className="center">
                                    <Text strong>SKU</Text>
                                </Col>
                                <Col xs={12} md={3} className="center">
                                    <Text strong>Active</Text>
                                </Col>

                            </Row>
                            }

                            renderItem={item => (
                                <List.Item className="component-list-item">
                                    <Card className="card-list">
                                        <Row className="width-100" gutter={[8, 8]}>
                                            <Col xs={12} md={6} className="center">
                                                <Text ellipsis>{item.nombre}</Text>
                                            </Col>
                                            <Col xs={12} md={7} className="center">
                                                <Text ellipsis>{item.descripcion}</Text>
                                            </Col>
                                            <Col xs={12} md={5} className="center">
                                                <Text ellipsis>{item.sku_variante}</Text>
                                            </Col>
                                            <Col xs={12} md={3} className="center">
                                                <Text>{item.activo ? <CheckCircleFilled style={{ color: "#52c41a", fontSize: "22px" }} /> : <CloseCircleFilled style={{ color: "#ff4d4f", fontSize: "22px" }} />}</Text>
                                            </Col>
                                            <Col xs={12} md={3} className="flex-right">
                                                <Space>
                                                    <Tooltip title={`This variant is ${item.square_id ? "" : "not"} registered on square`}>
                                                        <Badge count={item.square_id ? <CheckCircleOutlined color='#31A078' /> : <CloseCircleOutlined color='red' />}>
                                                            <Button type='primary'
                                                                disabled={true}
                                                                icon={<SquareLogo size="20px" />}
                                                            />
                                                        </Badge>
                                                    </Tooltip>
                                                    <ButtonEdit
                                                        disabled={!this.props.canEdit}
                                                        onClick={() => this.setState({ modal_visble: true, variante_id: item._id })}
                                                    />
                                                    <ButtonDelete
                                                        disabled={!this.props.canDelete}
                                                        onConfirm={() => axios.delete('/variantes/', { params: { id: item._id } }).then((response) => {
                                                            message.success('Deleted variant!')
                                                            this.getVariantes()
                                                        })
                                                            .catch((error) => {
                                                                message.error(error?.response?.data?.message ?? "Error deleting variant");

                                                            })
                                                        } />

                                                </Space>
                                            </Col>
                                        </Row>
                                    </Card>
                                </List.Item>
                            )}
                        />
                    </Content>

                    {this.props.canCreate && <>
                        <FloatingButton title="Nuevo registro" onClick={() => this.setState({ modal_visble: true })} />
                    </>}

                    <ModalVariantes
                        visible={this.state.modal_visble}
                        producto_id={this.props.params.producto_id}
                        variante_id={this.state.variante_id}
                        onClose={(flag) => {
                            this.setState({ modal_visble: false, variante_id: undefined })
                            if (flag === true)
                                this.getVariantes()
                        }}
                    />

                </Spin>
            </>
        )
    }
}

export default function (props) {

    const params = useParams()

    const user = React.useContext(User)
    const permisos = usePermissions(user?.rol_id?.permisos, {
        canEdit: ['productos', 'edit'],
        canDelete: ['productos', 'delete'],
        canCreate: ['productos', 'create']
    })

    return <Variantes {...props} {...permisos} navigate={useNavigate()} params={params} />
}