import React, { Component } from "react";
import { Row, Col, Modal, Form, message, Spin,  InputNumber, Select } from 'antd';
import axios from "axios"

import { getResponseError } from "../../Utils";

const { Option } = Select;

/**
 * @class ModalMovimientos
 * @description Modal para el CRUD de Movimientos
 */
class ModalMovimientos extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,

        }
    }

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token')
        if (this.props.movimiento_id)
            this.getMovimiento()

    }

    formRef = React.createRef();

    /**
    * @method getMovimiento
    * @description Obtener al producto
    */
    getMovimiento = () => {
        this.setState({ loading: true })
        axios.get('/movimiento/' + this.props.movimiento_id)
            .then(({ data }) => {
                console.log("data", data);
                this.formRef.current.setFieldsValue({
                    ...data,
                })
            }).catch(error => {
                console.log("error", error);
                message.error('Error getting the movement')
            }).finally(() => this.setState({ loading: false }))
    }


    /**
    * @method add
    * @description Añade un nuevo registro de producto
    */
    add = (values) => {
        this.setState({ loading: true })
        axios.post('/movimientos', values)
            .then(response => {
                message.success('Movement created!')
                this.props.onClose(true)
            }).catch(error => {
                console.log("error", error);
                message.error(getResponseError(error.response, 'Error creating movement'))
            }).finally(() => this.setState({ loading: false }))
    }

    /**
    * @method updateUser
    * @description Añade un nuevo registro de producto
    */
    update = (values) => {

        this.setState({ loading: true })
        axios.put('/inventarios', values, {
            headers: {
                "Content-Type": 'multipart/form-data'
            }
        })
            .then(response => {
                message.success('Updated inventory!')
                this.props.onClose(true)
            }).catch(error => {
                console.log("error", error);
                message.error(getResponseError(error.response, 'Error updating the Inventory'))
            }).finally(() => this.setState({ loading: false }))
    }

    /**
   * @method onFinish
   * @description Se ejecuta al hacer submit al formulario
   */
    onFinish = (values) => {
        values.inventario_id = this.props.inventario_id

        if (this.props.movimiento_id) {
            this.update(values)
        } else {
            this.add(values)
        }
    }

    render() {
        return (
            <Spin spinning={this.state.loading}>
                <Form
                    id="form-movimiento"
                    layout={"vertical"}
                    onFinish={this.onFinish}
                    ref={this.formRef}
                    className="mt-3"
                    initialValues={{
                        activo: true
                    }}
                >
                    <Row justify="center" className="center">
                        <Col span={20}>
                            <Form.Item
                                name="tipo"
                                label="Type Movement"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Type is required',
                                    }
                                ]}
                            >
                                <Select>
                                    <Option value={1}>Entry</Option>
                                    <Option value={2}>Egress</Option>
                                </Select>
                            </Form.Item>


                            <Form.Item
                                name="cantidad"
                                label="Amount"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Amount is required',
                                    }
                                ]}
                            >
                                <InputNumber
                                    className="width-100"
                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                    min={0.01}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        )
    }


}



export default function Modals(props) {

    const { open = false, onClose = () => { }, inventario_id } = props

    return <Modal
        open={open}
        onCancel={onClose}
        title={inventario_id ? "Edit Movement" : "Create Movement"}
        closable={true}
        destroyOnClose={true}
        zIndex={1000}
        cancelText="Cancel"
        okText="Save"
        okButtonProps={{ form: 'form-movimiento', key: 'submit', htmlType: 'submit' }}
    >
        <ModalMovimientos  {...props} />
    </Modal>

}