import React, { useState, useEffect } from 'react';
import { Avatar, Popover } from 'antd'
import { TwitterPicker } from "react-color";


let arrayColores =
    ['#000000', '#438EC3', '#7C5F45', '#89B850', '#808080',
        '#E2733B', '#E07990', '#83469A', '#D54637', '#FFFFFF', '#F7D757',
        ]

const ColorPicker = (props) => {

    const { value, onChange, icon = undefined } = props

    const [color, setColor] = useState(value)

    const onChangeColor = (color) => {
        setColor(color.hex)
        onChange(color.hex)
    }

    useEffect(() => {
        setColor(value)
    }, [value])



    return (
        <div>
            <Popover
                trigger={["click"]}
                content={<TwitterPicker
                    colors={arrayColores}
                    onChange={onChangeColor} />}
            >
                <Avatar className="status-avatar"
                    style={{ backgroundColor: color, marginTop: "5px", cursor: "pointer" }}
                    color={color}
                    icon={icon}
                />
            </Popover>
        </div>
    );
}

export default ColorPicker;