import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { PlusOutlined } from '@ant-design/icons';
import { Divider, Input, Select, Space, Button, message } from 'antd';


let index = 0;

const SelectSize = (props) => {

	const { 
        value, 
        onChange, 
        placeholder = "Select size", 
        onSelect = () => {}, 
        disabled = false, 
        className = "", 
        params = {}, 
        bordered, 
        allowClear = true, 
        onClear = () => {},
        mode = null,
        labelInValue,
        dropdownRender = false
    } = props


  	const [ sizes, setSizes ] = useState({
        data: [],
        page: 1,
        limit: 100,
        total: 0,
        search: null,
    })
  	const [name, setName] = useState('');
  	const inputRef = useRef(null);

  	const onNameChange = (event) => {
    	setName(event.target.value);
  	};


  	const addItem = (item) => {
    	setSizes(prevState => {
    		return {
    			...prevState,
    			data: [...prevState.data, item]
    		}
    	});
    	setName('');
    	setTimeout(() => {
      		inputRef.current?.focus();
    	}, 0);
  	};


  	const addSize = (e) => {
  		e.preventDefault();
  		if(!name) return;


  		axios.post('/sizes',{
  			nombre: name,
  		}).then(({data}) => {
  			addItem(data)
  		}).catch(e => {
  			message.error("Error creating size")
  			setName('');
	    	setTimeout(() => {
	      		inputRef.current?.focus();
	    	}, 0);
  		})
  	}

  	/**
     * @const getSizes
     * @description Obitiene los usaurios
     */
    const getSizes = ({
        page, 
        limit, 
        search, 
    } = sizes) => {

        axios.get('/sizes', {
            params: {
                page,
                limit,
                search,
                ...params,
            },
            headers: { Authorization: sessionStorage.getItem('token') }
        }).then(({ data }) => {
            setSizes(data)
        }).catch(error => {
            console.log("error?.response", error);
            message.error(error?.response?.data?.message ?? 'Error getting sizes')
        })
    }

    //componentDidMount
    useEffect(() => {
        getSizes()
    }, [])

  	return (
	    <Select
	      	bordered={bordered}
            className={className}
            disabled={disabled}
            placeholder={placeholder}
            allowClear={allowClear}
            showSearch
            filterOption={false}
            labelInValue
            mode={mode}
            onSearch={(search)=> getSizes({search})}
            onSelect={(area)=> {
                if(mode === null && !labelInValue){
                    onChange(area.value)
                    onSelect(area.value)
                }
                if(mode === null && labelInValue){
                    onChange(area)
                    onSelect(area)
                }
            }}
            onChange={(values)=>{
                if(mode === "multiple" && !labelInValue){
                    let arr = values.map(e => e.value)
                    onChange(arr)
                    onSelect(arr)
                }

                if(mode === "multiple" && labelInValue){
                    onChange(values)
                    onSelect(values)
                }
            }}
            value={value}
            onClear={()=>{
                onClear()
                onChange(undefined)
                onSelect(undefined)
            }}
	      	dropdownRender={dropdownRender ? (menu) => (
	        	<>
	          		{menu}
	          		<Divider
	            		style={{ margin: '8px 0' }}
	          		/>
		          		<Space
                            className=""
		            		style={{ padding: '0 8px 4px' }}
		          		>
		            		<Input
                                style={{width: "100%"}}
		              			placeholder="Please enter size"
		              			ref={inputRef}
		              			value={name}
		              			onChange={onNameChange}
		              			onKeyDown={(e) => e.stopPropagation()}
		            		/>
	            			<Button type="text" size="small" onClick={addSize} className="">
	              				Add
	            			</Button>
	          			</Space>
	        		</>
	    	) : null}
	      	options={sizes.data.map((item) => ({
	        	label: item.nombre,
	        	value: item._id,
	      	}))}
	    />
  	);
};
export default SelectSize;
