import React, { Component } from 'react';
import { Row, Col, Layout,  } from 'antd'
import axios from 'axios';
import ReactECharts from 'echarts-for-react';

import { renderEstatusVenta } from '../../Utils';
import { CardSimpleInfo } from './../../Widgets/Cards'
import PageHeader from '../../Widgets/PageHeader/PageHeader';
import { useNavigate, useSearchParams } from 'react-router-dom';

import '../../../Styles/Modules/Admin/CRM.scss'

const { Content } = Layout;

/**
 *
 *
 * @export
 * @class Usuarios
 * @extends {Component}
 * @description Vista donde se listan todos los usuarios
 */
class Dashboard extends Component {

    back = () => {
        this.props.navigate(-1)
    }


    /**
     *Creates an instance of Usuarios.
     * @param {*} props
     * @memberof Usuarios
     */
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            clientes_count: 0,
            normales: 0,
            influencers: 0,
            clientes_mas_ventas: [],
            ventas_por_estatus: [],
        }
    }

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token')
        this.getDashboard()
    }

    /**
     *
     *
     * @memberof Usuarios
     * @method getDashboard
     * @description Obtiene los datos necesarios para la analitica
     */
    getDashboard = () => {
        this.setState({loading: true})
        axios.get('/crm')
        .then(({ data }) => {
            console.log("data", data);

            let normales = (data?.clientes_tipos?.find(e => e._id === 1))?.total ?? 0
            let influencers = (data?.clientes_tipos?.find(e => e._id === 2))?.total ?? 0

            this.setState({
                clientes_count: data.clientes_count,
                normales,
                influencers,
                clientes_mas_ventas: data.clientes_mas_ventas,
                ventas_por_estatus: data.ventas_por_estatus
            })
        })
        .catch(error => {
            console.log(error)
        }).finally(()=>this.setState({loading: false}))
    }

    render() {

        return (
            <>
                <PageHeader
                    className="admin-page-header"
                    title="CRM"
                />
                <Content className="admin-content  crm content-bg pd-1">
                    <Row gutter={[16, 16]}>
                        <Col xs={24} className="">
                            <Row gutter={[16, 16]}>
                                <Col xs={24} md={8} className="grid">
                                    <CardSimpleInfo
                                        title="Total Clients"
                                        info={(this.state.clientes_count ?? 0).toMoney(true, false)}
                                    />
                                </Col>
                                <Col xs={24} md={8} className="grid">
                                    <CardSimpleInfo
                                        title="Preferential clients"
                                        info={(this.state.influencers ?? 0).toMoney(true, false)}
                                    />
                                </Col>
                                <Col xs={24} md={8} className="grid">
                                    <CardSimpleInfo
                                        title="Normal Clients"
                                        info={(this.state.normales ?? 0).toMoney(true, false)}
                                    />
                                </Col>
                            </Row>    
                        </Col>
                        <Col xs={24}>
                            <Row gutter={[16, 16]}>
                                <Col xs={24} lg={16}>
                                    <CardSimpleInfo
                                        title="Sales by clients"
                                        info={<ReactECharts
                                            option={{
                                                grid: {
                                                    containLabel: true,
                                                },
                                                title: {
                                                    text: ''
                                                },
                                                tooltip: {},

                                                xAxis: {
                                                    data: this.state.clientes_mas_ventas?.map(e => `${e.cliente_id.nombre} ${e.cliente_id.apellidos}`),
                                                    axisLabel: {
                                                        show: true,
                                                        interval: 0,
                                                        rotate: 0,
                                                    },

                                                },
                                                yAxis: {},
                                                series: [{
                                                    name: 'Sales',
                                                    type: 'bar',
                                                    data: this.state.clientes_mas_ventas?.map(e => e.total)
                                                }]
                                            }}
                                            style={{ height: 400 }}
                                            opts={{ renderer: 'svg' }}
                                        />}
                                    />
                                </Col>
                                <Col xs={24} lg={8} className="grid">
                                    <CardSimpleInfo
                                        title="Status Quotes"
                                        info={<ReactECharts
                                            option={{
                                                tooltip: {
                                                    trigger: 'item'
                                                },
                                                series: [
                                                    {
                                                        name: 'Access From',
                                                        type: 'pie',
                                                        radius: ['40%', '70%'],
                                                        avoidLabelOverlap: false,
                                                        itemStyle: {
                                                            borderRadius: 0,
                                                            borderColor: '#fff',
                                                            borderWidth: 2
                                                        },
                                                        label: {
                                                            show: false,
                                                            position: 'center'
                                                        },
                                                        labelLine: {
                                                            show: false
                                                        },
                                                        data: this.state.ventas_por_estatus.map(e => ({
                                                            value: e.total, 
                                                            name: renderEstatusVenta(e._id, true)?.name,
                                                            itemStyle: {
                                                                color: renderEstatusVenta(e._id, true)?.color,
                                                            }
                                                        }))
                                                    }
                                                ]
                                            }}
                                            style={{ maxHeight: 400 }}
                                            opts={{ renderer: 'svg' }}
                                        />}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Content>

            </>
        )
    }
}

export default props => {

    const navigate = useNavigate()
    const [params, setParams] = useSearchParams();

    return <Dashboard {...props} navigate={navigate} params={params} />
}