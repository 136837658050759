import React, { Component } from 'react';
import { Row, Col, Typography, Spin, Layout, Space, message, Card, List, Tag, Button } from 'antd'
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { MenuOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

//componentes
import { renderEstatusVenta } from '../../Utils';
import Logged, { User } from "../../../Hooks/Logged";
import PageHeader from '../../Widgets/PageHeader/PageHeader';
import { ButtonView } from '../../Widgets/Buttons';

//Modales
import ModalVentaDetalles from './ModalVentaDetalles'


const { Content } = Layout;
const { Text, Title } = Typography
const moment = require("moment")

/**
 * @export
 * @class Ventas
 * @extends {Component}
 * @description Vista donde se listan todos los transacciones
 */
class Ventas extends Component {
    static contextType = Logged;

    /**
     *Creates an instance of Ventas.
     * @param {*} props
     * @memberof Ventas
     */
    constructor(props) {
        super(props)
        this.state = {

            loading: false,
            ventas: {
                data: [],
                limit: 10,
                page: 1,
                total: 0,
                pages: 0,
                filters: []
            }
        }
    }

    componentDidMount() {

        this.get()
    }

    componentDidUpdate(prevProps) {
        if (this.props.search != prevProps.search) {
            this.get()
        }
    }

    /**
    *
    * @memberof Ventas
    * @method get
    * @description Obtiene la lista de ventas
    */
    get = async ({
        page = this.state.ventas.page,
        limit = this.state.ventas.limit,
        search = this.props.search,
    } = this.state.ventas) => {

        this.setState({ loading: true })
        axios.get('/customer/ventas', {
            params: {
                limit,
                page,
                search,
            }
        })
            .then(({ data }) => {
                console.log("data", data);
                this.setState({ ventas: data })
            })
            .catch(res => {
                console.log("res", res);
                message.error(res.response?.data?.message ?? "Error")
            })
            .finally(() => this.setState({ loading: false }))
    }

    render() {

        return (
            <>
                <Spin spinning={this.state.loading}>
                    <PageHeader
                        className="admin-page-header"
                        title="Sales"
                    >

                    </PageHeader>

                    <Content className="transacciones-content content-bg pd-1">
                        <List
                            className="component-list responsive"
                            itemLayout="horizontal"
                            locale={{ emptyText: "Sales not found" }}
                            dataSource={this.state.ventas.data}
                            pagination={{
                                current: this.state.ventas.page,
                                pageSize: this.state.ventas.limit,
                                total: this.state.ventas.total,
                                hideOnSinglePage: false,
                                position: 'bottom',
                                onChange: (page, limit) => this.get({ page, limit })

                            }}

                            renderItem={item => (
                                <List.Item className="component-list-item">
                                    <Card className="card-list">
                                        <Row className="width-100" gutter={[8, 8]} justify={"space-around"}>
                                            <Col xs={{ span: 20, order: 1 }} md={{ span: 5, order: 1 }} lg={{ span: 5, order: 1 }} >
                                                <Text ellipsis>{item.folio ?? "-"}</Text>
                                            </Col>
                                            <Col xs={{ span: 24, order: 6 }} md={{ span: 5, order: 2 }} lg={{ span: 5, order: 2 }} >
                                                <Text strong ellipsis>{moment(item.createdAt).format("MM/DD/YYYY HH:mm")}</Text>
                                            </Col>
                                            <Col xs={{ span: 8, order: 4 }} md={{ span: 4, order: 3 }} lg={{ span: 4, order: 3 }} >
                                                <Text ellipsis>{item.detalles?.length ?? 0} Items</Text>
                                            </Col>
                                            <Col xs={{ span: 8, order: 3 }} md={{ span: 3, order: 4 }} lg={{ span: 3, order: 4 }} >
                                                <Text ellipsis>{renderEstatusVenta(item.status)}</Text>
                                            </Col>
                                            <Col xs={{ span: 8, order: 4 }} md={{ span: 5, order: 5 }} lg={{ span: 5, order: 5 }} >
                                                <Text ellipsis>$ {item.total?.toMoney(true) ?? 0}</Text>
                                            </Col>
                                            <Col xs={{ span: 4, order: 2 }} md={{ span: 2, order: 6 }} lg={{ span: 2, order: 6 }} >
                                                <Space>
                                                    <ButtonView
                                                        onClick={() => this.setState({ modalDetalleVisible: true, venta_id: item._id })}
                                                    />
                                                    <Link to={`/customer/transactions/details/${item._id}`}>
                                                        <Button
                                                            icon={<MenuOutlined />}


                                                        />
                                                    </Link>
                                                </Space>
                                            </Col>
                                        </Row>
                                    </Card>
                                </List.Item>
                            )}
                        />
                    </Content>
                </Spin>
                <ModalVentaDetalles
                    open={this.state.modalDetalleVisible}
                    onClose={(flag) => {
                        this.setState({ modalDetalleVisible: false, venta_id: undefined })
                    }}
                    venta_id={this.state.venta_id}
                />
            </>
        )
    }
}

export default function (props) {
    return <Ventas {...props} navigate={useNavigate()} />
}