import React from 'react'
import { Route, Routes } from "react-router-dom";

import Productos from '../../Components/Admin/Productos/Productos';
import Variantes from '../../Components/Admin/Variantes/Variantes';

/**
 * 
 * @export
 * @function RouterProductos
 * @description Router for Productos routes 
 */
function RouterProductos(props) {
  return (
    <Routes>
      <Route path="" element={<Productos {...props} />} />
      <Route path="/variantes/:producto_id" element={<Variantes {...props} />} />
    </Routes>
  )
}

export default RouterProductos