import { Component } from "react";
import { Card, Col, List, Row, Space, Typography, message, Tag } from "antd";
import '../../../Styles/Modules/Admin/quotations.scss'
import axios from "axios";
import { ButtonView } from "../../Widgets/Buttons";
import dayjs from "dayjs";
import { MoneyFormat, FolioFormat, renderEstatusQuotation } from "../../Utils";
import { useNavigate } from "react-router-dom";
import useSearch from '../../../Hooks/useSearch';

const { Text } = Typography

class QuotationsList extends Component {

    constructor(props) {
        super(props)
        this.state = {
            quotations: {
                data: [],
                limit: 10,
                page: 1,
                total: 0,
                pages: 0,
                filters: []
            },
            loading: false
        }
    }

    componentDidMount() {
        this.props.setShowSearch(true)
        this.get()
    }


    componentDidUpdate(prevProps) {
        if (prevProps.search !== this.props.search) {
            this.get({ page: 1 })
        }
    }

    /**
    *
    * @memberof Quotations
    * @method get
    * @description Obtiene la lista de quotations
    */
    get = async ({
        page = this.state.quotations.page,
        limit = this.state.quotations.limit,
        search = this.props.search,
    } = this.state.quotations) => {

        this.setState({ loading: true })
        axios.get('/cotizaciones', {
            params: {
                limit,
                page,
                search
            }
        })
            .then(({ data }) => {
                this.setState({ quotations: data })
            })
            .catch(res => {
                console.log("res", res);
                message.error(res.response?.data?.message ?? "Error")
            })
            .finally(() => this.setState({ loading: false }))
    }


    renderCosto = (item) => {
        if (item.costo && item.status == 0)
            return `$ ${(item.costo).toMoney(true)} usd`
        if (!item.costo && item.status == 0)
            return 'Pending'
        if (item.costo && item.status == 1)
            return `$ ${(item.costo).toMoney(true)} usd`
        if (item.status == 2)
            return 'N/A'
    }
    render() {
        const { quotations, loading } = this.state

        return (
            <List
                className="component-list responsive"
                itemLayout="horizontal"
                loading={loading}
                locale={{ emptyText: "Quotations not found" }}
                dataSource={quotations.data}
                pagination={{
                    current: quotations.page,
                    pageSize: quotations.limit,
                    total: quotations.total,
                    hideOnSinglePage: false,
                    position: 'bottom',
                    onChange: (page, limit) => this.get({ page, limit })

                }}

                renderItem={item => (
                    console.log('item', item),
                    <List.Item className="component-list-item">
                        <Card className="card-list">
                            <Row className="width-100" gutter={[8, 8]} justify={"space-between"}>
                                <Col xs={{ span: 16, order: 1 }} md={{ span: 3, order: 1 }} lg={{ span: 5, order: 1 }}>
                                    <Text strong ellipsis>{FolioFormat(item.folio)}</Text>
                                </Col>
                                <Col xs={{ span: 24, order: 6 }} md={{ span: 3, order: 2 }} lg={{ span: 3, order: 2 }}>
                                    <Text strong ellipsis>{dayjs(item.fecha).format('MM/DD/YYYY')}</Text>
                                </Col>
                                <Col xs={{ span: 8, order: 5 }} md={{ span: 5, order: 3 }} lg={{ span: 4, order: 3 }} >
                                    <Text ellipsis>{item.servicio_id?.nombre}</Text>
                                </Col>
                                <Col xs={{ span: 24, order: 3 }} md={{ span: 5, order: 4 }} lg={{ span: 5, order: 4 }}>
                                    <Space align="center" direction={"horizontal"} wrap>
                                        {renderEstatusQuotation(item.status)}
                                        <Text ellipsis> {item.venta?.square_payment ? <Tag color='#00cd98'>Paid</Tag> : <Tag color='#F2802E'>Waiting for Payment</Tag>}</Text>
                                    </Space>
                                </Col>
                                <Col xs={{ span: 16, order: 4 }} md={{ span: 5, order: 5 }} lg={{ span: 3, order: 5 }} >
                                    <Text ellipsis>{this.renderCosto(item)}</Text>
                                </Col>

                                <Col xs={{ span: 8, order: 2 }} md={{ span: 2, order: 6 }} lg={{ span: 2, order: 6 }} className="flex-right">
                                    <ButtonView
                                        onClick={() => this.props.navigate(`/admin/quotations/${item._id}`)}
                                    />
                                </Col>
                            </Row>
                        </Card>
                    </List.Item>
                )}
            />
        )
    }
}

export default (props) => {
    const navigate = useNavigate()
    const { search, setShow } = useSearch()

    return <QuotationsList navigate={navigate} {...props}
        setShowSearch={setShow}
        search={search} />
}