import React, { Component } from "react";
import { SelectPais, SelectEstado } from "../../Widgets/Inputs";
import { Row, Col, Button, Modal, Typography, Form, Input, InputNumber, Select, message, Upload, Divider, Spin, Space } from 'antd';

import axios from "axios"
import { renderEstatusVenta } from '../../Utils';

const { Title, Text } = Typography;
const { Option } = Select;
const moment = require('moment');

class ModalVentaDetalles extends Component {


    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            venta: {
                detalles: []
            }
        }
    }

    formRef = React.createRef();

    componentDidMount() {
        if (this.props.venta_id) {
            this.get()
        }
    }

    /**
     * @memberof ModalVentaDetalles
     * @method get
     * @description Obtiene una Cuenta de la DB
     *
     */
    get = () => {
        this.setState({ loading: true })
        axios.get('/customer/venta', {
            params: {
                venta_id: this.props.venta_id
            }
        }).then(({ data }) => {
            console.log("data", data);
            this.setState({ venta: data })
        }).catch(error => {
            message.error('Error getting the sale')
            console.log(error)
        }).finally(() => this.setState({ loading: false }))
    }

    render() {

        let { venta } = this.state;
        let {direccion_id, entrega_local} = venta

        let address = null
        if (direccion_id?.localizacion) address = direccion_id.localizacion.place_name.split(',')
        if (direccion_id?.codigo_postal && !address) address = [direccion_id.nombre, direccion_id.codigo_postal]

        return (
            <Form
                layout="vertical"
                name="form-transaccion"
                id="form-transaccion"
                ref={this.formRef}
                onFinish={() => { }}

            >
                <Spin spinning={this.state.loading}>
                    <Row gutter={[16, 0]} className="mt-1">
                        <Col xs={24}>
                            <Title level={5} className="m-0">Folio: {venta.folio ?? "-"}</Title>
                        </Col>
                        <Col xs={12}>
                            <Text className="text-gray">Date: {moment(venta.createdAt).format("MM/DD/YYYY HH:mm")}</Text>
                        </Col>
                        <Col xs={12} className="flex-right">
                            {renderEstatusVenta(venta.status)}
                        </Col>
                        <Col xs={24} className="flex-left-column mt-1 mb-1">
                            {entrega_local ?
                                <Text>Pick Up</Text>
                                : <Space direction='vertical' size={"small"} >
                                    {
                                        address && address.map((item, index) => (
                                            <Text>{item}</Text>
                                        ))
                                    }
                                </Space>}
                        </Col>
                        <Col xs={24} className="mt-1 mb-1">
                            <Text strong>{venta.detalles?.length ?? 0} ITEMS</Text>
                        </Col>
                        <Col span={24}>
                            {
                                venta.detalles?.map(detalle => {
                                    return <Row className="mb-1">
                                        <Col span={11} className="flex-left-column">
                                            {detalle.variante_id ? <Text>{detalle.variante_id?.nombre}</Text> : null}
                                            {detalle.variante_id ? <Text className="text-gray">$ {detalle.variante_id?.precio?.toMoney(true) ?? 0}</Text> : null}
                                            {detalle.servicio_id ? <Text>{detalle.servicio_id?.nombre}</Text> : null}
                                            {detalle.servicio_id ? <Text className="text-gray">$ {detalle.servicio_id?.precio?.toMoney(true) ?? 0}</Text> : null}
                                        </Col>
                                        <Col span={5} className="center">
                                            <Text>{detalle.cantidad}</Text>
                                        </Col>
                                        <Col span={8} className="flex-right">
                                            <Text>$ {detalle.total?.toMoney(true)}</Text>
                                        </Col>
                                    </Row>
                                })
                            }
                        </Col>
                        <Divider />
                        <Col xs={12} className="flex-right">
                            <Text className="text-gray">Subtotal</Text>
                        </Col>
                        <Col xs={12} className="flex-right">
                            <Text strong>$ {venta.subtotal?.toMoney(true) ?? 0}</Text>
                        </Col>
                        <Col xs={12} className="flex-right">
                            <Text className="text-gray">Tax</Text>
                        </Col>
                        <Col xs={12} className="flex-right">
                            <Text strong>$ {venta.tax?.toMoney(true) ?? 0}</Text>
                        </Col>
                        <Col xs={12} className="flex-right">
                            <Text className="text-gray">Total</Text>
                        </Col>
                        <Col xs={12} className="flex-right">
                            <Text strong>$ {venta.total?.toMoney(true) ?? 0}</Text>
                        </Col>
                    </Row>
                </Spin>
            </Form>
        )
    }
}



export default function (props) {
    const { open = false, onClose = () => { }, transaccion_id } = props

    return <Modal
        open={open}
        onCancel={onClose}
        title={"Sale Details"}
        destroyOnClose={true}
        footer={null}
        okButtonProps={{ form: 'form-transaccion', key: 'submit', htmlType: 'submit' }}
    >
        <ModalVentaDetalles {...props} />
    </Modal>

}