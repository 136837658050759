import React, { useEffect, useState } from 'react';
import { Card, Row, Col, Progress, Typography, Button, Space, Tag, } from 'antd'
import '../../Styles/Global/cards.css'
import './cards.scss'
import { ButtonDelete, ButtonEdit, } from "./Buttons"
import { FaArrowRight } from 'react-icons/fa'
import { CheckOutlined, CloseOutlined, EditOutlined, WalletOutlined } from '@ant-design/icons';
import { FiArrowDownRight, FiArrowUpRight, FiClipboard } from 'react-icons/fi'
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { FolioFormat, renderEstatusQuotation, MoneyFormat, RenderSlot } from '../Utils';
import axios from "axios"
const { Text, Title, Paragraph } = Typography;

dayjs.extend(relativeTime)

/**
 *
 *
 * @const CardStatistics
 * @extends {React.Component}
 * @description Card para estadisticas del dashboard principal
 */
const CardStatistics = ({ monto = 0, icon, color = "#426CB4", title, suffix, onClick, percent }) => {

    let aumento = percent !== undefined && !(percent < 0)

    return <Card size="small" className="card-statistics">
        <Row align={"middle"} className="card-header">
            <Col flex="auto">
                <Text className="card-title">{title}</Text>
            </Col>
            <Col flex="none">
                {
                    onClick && (
                        <Button
                            type="primary"
                            className='btn-primary'
                            icon={<FaArrowRight />}
                            onClick={onClick}
                        />
                    )
                }
            </Col>
        </Row>
        <Row align={"middle"} className="card-content">
            <Col flex="auto">
                <Space size={2}>
                    <Text className="card-monto">{monto}</Text>
                    <Text className="card-suffix">{suffix}</Text>
                </Space>
            </Col>
            <Col flex="none" className="center">
                {
                    icon && (
                        <div
                            className="icon-container"
                            style={{
                                backgroundColor: color + "D9"
                            }}
                        >
                            {
                                typeof icon === 'function' ? icon() : icon
                            }
                        </div>
                    )
                }
            </Col>
        </Row>
        {
            percent !== undefined && (
                <div className={`card-percent ${aumento ? "green" : "red"}`}>
                    <Space align='center'>
                        {
                            aumento ? <FiArrowUpRight className='card-icon' color='#00CD98' /> : <FiArrowDownRight className='card-icon' color='#FF4D4F' />
                        }
                        <Text className="card-monto">{aumento ? "+" : "-"} {Math.abs(percent)}%</Text>
                    </Space>
                </div>
            )
        }
    </Card>
}



/**
 * @const CardSimpleInfo
 * @description Card para mostrar informacion simple o numerica
 * @param {*} step Object con la información del step
 * @param String color Color del step
 */
const CardSimpleInfo = ({ title = "Profit", toolTipText = 'success', info = 0, content }) => {
    return <Card className="card-shadow card-simple">
        <Row>
            <Col span={24} className="flex-left">
                <Text className="title-simple">{title}</Text>
            </Col>
            <Col span={24} className="" >
                {content ?? <Paragraph className="info-simple mb-0">{info}</Paragraph>}
            </Col>
        </Row>
    </Card>
}

const CardAddress = ({
    item,
    onEdit,
    onDelete,
    extra = true,
    hoverable = false }) => {

    let place = item.localizacion?.place_name?.split(',') || []

    return (
        <Card
            title={item.nombre}
            className='card-direcciones'
            hoverable={hoverable}
            extra={extra ? <Space>
                <ButtonEdit onClick={onEdit} />
                <ButtonDelete
                    onConfirm={onDelete}
                />
            </Space> : null}>
            <Space direction='vertical' size={5}>
                {
                    place.length > 0 ?
                        place.map((p, i) => (<Text key={`${item._id}-place-${i}`}>{p}</Text>))
                        : <Text>{item.codigo_postal}</Text>
                }
                <Paragraph className='text-gray card-direcciones-descripcion' ellipsis={{
                    rows: 3
                }}>
                    {item.notas}
                </Paragraph>
            </Space>
        </Card>
    )
}

const CardQuotationStep = ({ color, title, description }) => {

    return (
        <Card className="card-quotation-step">
            <div className="color" style={{ backgroundColor: color }}></div>
            <div className="info">
                <Title level={2} className='title'>
                    {title}
                </Title>
                <Paragraph ellipsis={{ rows: 1 }} className="description">
                    {description}
                </Paragraph>
            </div>
        </Card>
    )
}

const CardQuotation = ({ quotation = {}, color }) => {

    return (
        <Link className="card-quotation-wrapper" to={`/admin/quotations/${quotation._id}`}>
            <Card
                className="card-quotation"
                title={<div className="header">
                    <Title ellipsis={{ rows: 1 }} level={3}>
                        {FolioFormat(quotation.folio)}
                    </Title>
                    <Tag color={color}>
                        {dayjs(quotation.fecha).fromNow(true)}
                    </Tag>
                </div>}
            >
                <Paragraph ellipsis={{ rows: 4 }} className='description'>
                    {quotation.descripcion}
                </Paragraph>
                <div className="tags">
                    <Tag color="#EAE5FF">{quotation.servicio_id?.nombre}</Tag>
                </div>
                <Text className="date">
                    {dayjs(quotation.fecha).format('MMMM D, YYYY')}
                </Text>
            </Card>
        </Link>
    )
}

const CardQuotationInformation = ({ quotation = {}, updateQuotation = () => { }, onConfirm = () => { }, updateCosto = () => { }, loading = false, can_view = false }) => {
    let renderCosto = (item) => {
        if (item.costo && item.status == 0)
            return `$ ${(item.costo).toMoney(true)} usd`
        if (!item.costo && item.status == 0)
            return 'Pending'
        if (item.costo && item.status == 1)
            return `$ ${(item.costo).toMoney(true)} usd`
        if (item.status == 2)
            return 'N/A'
    }

    return <Card className='quotation-card'>
        <Row gutter={[12, 10]}>
            <Col span={12}>
                <Text strong>Quotation</Text>
            </Col>
            <Col span={12}>
                <Text>{FolioFormat(quotation.folio)}</Text>
            </Col>
            <Col span={12}>
                <Text strong>Status</Text>
            </Col>
            <Col span={12}>
                {renderEstatusQuotation(quotation.status)}
                {quotation?.venta?.square_payment ? <Tag color='#00cd98'>Paid</Tag> : <Tag color='#F2802E'>Waiting for Payment</Tag>}
            </Col>
            <Col span={12}>
                <Text strong>Date</Text>
            </Col>
            <Col span={12}>
                <Text>{dayjs(quotation.fecha).format('MMMM D, YYYY')}</Text>
            </Col>
            <Col span={12}>
                <Text strong>Cost</Text>
            </Col>
            <Col span={12}>
                <Space>
                    <Text>{renderCosto(quotation)}</Text>
                    {(quotation.costo != undefined && !quotation.venta?.square_payment) && can_view ? <Button type='link' icon={<EditOutlined style={{ color: 'rgba(0, 0, 0, 0.88)' }} />} onClick={updateCosto}></Button> : null}
                </Space>
            </Col>
            <Col span={12}>
                <Text strong>Services Type</Text>
            </Col>
            <Col span={12}>
                <Text>{quotation.servicio_id?.nombre}</Text>
            </Col>
        </Row>
        {quotation.status === 0 && can_view ? <Row gutter={[8, 8]} style={{marginTop: '1.3rem'}}>
            <Col span={12}>
                <Button icon={<CheckOutlined />} block loading={loading} type="primary" onClick={onConfirm}>
                    Accept
                </Button>
            </Col>
            <Col span={12}>
                <Button icon={<CloseOutlined />} block loading={loading} danger type="primary" onClick={() => updateQuotation(2)}>
                    Refuse
                </Button>
            </Col>
        </Row> : null
        }
    </Card >
}

const CardQuotationProgress = ({ status = 0, onPayment = () => { }, can_pay = false, is_pay = false, venta = {}, cliente = {}, admin = false }) => {



    const setPercentage = () => {
        //si se empezo
        if (status == 0) return 10
        //si se acepto
        if (status == 1) return 50
        //si se rechazo
        if (status == 2 || venta?.status == 2) return 100
        //si se acepto y se genero la venta
        if (status == 1 && venta?.status == 1) return 70
        //si se acepto y se detallo la venta
        if (status == 1 && venta?.status == 3) return 80
        //si se acepto y se empaco la venta
        if (status == 1 && venta?.status == 4) return 90
        //si se acepto y se completo la venta
        if (status == 1 && venta?.status == 5) return 100

        return 0
    }

    return <Card className='quotation-card'>
        <Row>
            <Space size={8} direction='vertical' className='width-100'>
                <Text strong>Progress Quotation</Text>
                <Progress strokeColor={"#D459E8"} percent={setPercentage()} status={(status == 2 || venta?.status == 2) ? 'exception' : 'active'} />
            </Space>
            {admin ?
                <Space size={8} direction='vertical' className='width-100'>
                    <Text strong>Client Information</Text>
                    <Text >{cliente.nombre} </Text>
                    <Text >{cliente.telefono} </Text>
                    <Text >{cliente.email} </Text>
                </Space> : null}
        </Row>
        <Row>
            <Col span={24}>
                <Space size={8} direction='vertical' className='width-100'>
                    {(!is_pay && status == 1) && can_pay ? <Button block type='primary' icon={<WalletOutlined />} onClick={onPayment}>proceed to payment</Button> : null}
                </Space>
            </Col>

        </Row>
    </Card>
}

const CardQuotationAddress = ({ address,
    venta,
    can_track = false,
    onTrack = () => { },
    status,
    collection_date = null,
    time_slot = null,
    entrega_local = false,
    pick_and_deliver = false,
}) => {

    return <Card className="quotation-card quotation-address">
        <Row className='quotations-address-form' gutter={[0, 5]}>
            <Col span={12}>
                <Row>
                    <Text strong>Delivered Address</Text>
                </Row>
                {entrega_local ?
                    <Text>Pick Up</Text>
                    : <Space direction='vertical' size={"small"} >
                        {
                            address && address.map((item, index) => (
                                <Text>{item}</Text>
                            ))
                        }
                    </Space>}
            </Col>
            <Col span={12}>
                <Row align={"middle"} gutter={[0,8]}>
                    <Col span={24} className="flex-left-column">
                        <Text strong>Collection Date </Text>
                        <Text >{collection_date ? dayjs(collection_date).format('MMMM D, YYYY') : null}</Text>
                    </Col>
                    <Col span={24} className="flex-left-column">
                            <Text strong>Time Slot </Text>
                            <Text >{time_slot ? RenderSlot(time_slot) : null}</Text>
                    </Col>
                    <Col span={24} className="flex-left-column">
                            <Text strong>Store Pickup </Text>
                            <Tag color="#f22eef">{(entrega_local == true) ? 'Yes' : 'No'}</Tag>
                    </Col>
                    { pick_and_deliver ? <Col span={24}>
                        <Space size={"small"} >
                            <Tag color="#f22eef"> Pick & Delivery </Tag>
                        </Space>
                    </Col> : null}
                </Row>
            </Col>
        </Row>
        <Row gutter={[8, 8]} align={"bottom"} className="mt-1">
            <Col span={24}>
                {venta?.tracking_number ?
                    <Space direction='horizontal' size={"small"} className='mt-1'>
                        <Text>Tracking Number</Text>
                        <Text strong copyable>{venta.tracking_number}</Text>
                    </Space> : null}
                {(can_track && status == 1 && (venta && !venta?.tracking_number)) && !entrega_local ? <Button block type='primary' icon={<FiClipboard ined />} onClick={onTrack}>Set track Number </Button> : null}
            </Col>
        </Row>
    </Card>
}
export {
    CardStatistics,
    CardSimpleInfo,
    CardAddress,
    CardQuotationStep,
    CardQuotation,
    CardQuotationInformation,
    CardQuotationProgress,
    CardQuotationAddress
}