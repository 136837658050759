
import React, { Component } from 'react'
import { Form, Input, Button, Row, Col, message, Spin, Typography, Modal } from 'antd';
import axios from "axios";

import { PaymentForm, CreditCard } from 'react-square-web-payments-sdk';

const { Title } = Typography;


export default class PaymentModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false
        }
    }


    componentDidMount() {
        console.log("venta", this.props.venta_id)
    }

    /**
     * @methodOf StepPayment
     * @function payment
     * @description Manda el id de la orden y el token de la tarjeta, para realizar el pago de la orden
     */
    payment = (token, verifiedBuyer) => {
        let venta_id = this.props.venta_id;
        console.log("venta", venta_id)
        this.setState({ loading: true })
        axios.post('/square/payment', {
            token,
            orden_id: venta_id,
        }).then(response => {
            message.success("Payment accepted")
            this.props.onClose()
        }).catch(error => {
            console.log("error", error);
            message.error(error?.response?.data?.message ?? "Error processing payment")
        }).finally(() => this.setState({ loading: false }))
    }

    render() {

        const { visible = false, onClose = () => { } } = this.props

        return (

            <Modal
                open={visible}
                title={"PAYMENT"}
                onCancel={onClose}
                closable={true}
                destroyOnClose={true}
                zIndex={1000}
                footer={null}
            >
                <Row>
                    <Col span={24}>
                        <Title level={3} className="mb-2">Enter your card information</Title>
                    </Col>
                </Row>
                <Spin spinning={this.state.loading}>
                    <PaymentForm
                        applicationId={process.env.REACT_APP_SQUARE_APPID}
                        cardTokenizeResponseReceived={this.payment}
                        locationId={process.env.REACT_APP_SQUARE_LOCATIONID}
                    >
                        <CreditCard
                            buttonProps={{
                                css: {
                                    backgroundColor: "#426cb4",
                                    fontSize: "14px",
                                    color: "#fff",
                                    "&:hover": {
                                        backgroundColor: "#9dbcdd",
                                    },
                                },
                            }}
                        />
                    </PaymentForm>
                </Spin>

            </Modal>
        )
    }
}