import { Tag, Modal } from 'antd'

import '../Styles/Utils.css'


/**
 * @param {*} estatus
 * @description Renderiza el Tag con el estatus de la inversion
 */
const renderEstatusCliente = ({ estatus = 0 }) => {

    let steps = {
        0: <Tag className="tag-estatus cancelado">Cancelado</Tag>,
        1: <Tag className="tag-estatus registrado">Registrado</Tag>,
        2: <Tag className="tag-estatus contactado">Contactado</Tag>,
        3: <Tag className="tag-estatus compro">Otro</Tag>,
        4: <Tag className="tag-estatus pagado">Pagado</Tag>,

    }

    return estatus != undefined ? steps[estatus] : 'N/A'

}

/**
 * @param {*} estatus
 * @description Renderiza el Tag con el estatus de la inversion
 */
const renderEstatusQuotation = (estatus) => {

    let steps = {
        0: <Tag color="#0089ED">Pending</Tag>,
        1: <Tag color="#36F905">Accepted</Tag>,
        2: <Tag color="#EFC904">Refused</Tag>,
        3: <Tag color="#FF0000">Cancelled</Tag>

    }

    return estatus != undefined ? steps[estatus] : 'N/A'

}

const renderEstatusVenta = (estatus, obj = false) => {

    let steps = {
        0: <Tag color="#94979c">Pending</Tag>,
        1: <Tag color="#c5e1c7">Order Received</Tag>,
        2: <Tag color="#5465FF">Cleaning in progress</Tag>,
        3: <Tag color="#F2802E">Final Detailing Touches</Tag>,
        4: <Tag color="#c5e1c7">Order Ready</Tag>,
        5: <Tag color="#94979c">Order Completed</Tag>,
    }

    let status = {
        0: { color: "#94979c", name: 'Pending' },
        1: { color: "#c5e1c7", name: 'Order Received' },
        2: { color: "#5465FF", name: 'Cleaning in progress' },
        3: { color: "#F2802E", name: 'Final Detailing Touches' },
        4: { color: "#c5e1c7", name: 'Order Ready' },
        5: { color: "#94979c", name: 'Order Completed' },
    }

    if (obj) return status[estatus] ?? { color: "#979799", name: "N/A" }

    return estatus != undefined ? steps[estatus] : 'N/A'

}

const renderSource = (from_terminal) => {

    let steps = {
        0: <Tag color="#ff4747">Online Purchase</Tag>,
        1: <Tag color="#c7d8e1">In-Store Purchase</Tag>,
    }

    return (from_terminal) ? steps[1] : steps[0]

}

const MoneyFormat = (value = 0) => {
    const money = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
    })

    return money.format(value)
}

const FolioFormat = (value, size = 6) => {
    return "#" + Number(value).toString().padStart(size, '0')
}

/**
 * 
 * @param {*} response 
 * @param {String} si_falla_text 
 * @returns {String}
 */
const getResponseError = (response, si_falla_text) => {

    if (typeof (response?.data) === "string")
        return response.data

    if (typeof (response?.data?.message) === "string")
        return response.data.message

    if (typeof (response?.data?.message?.data) === "string")
        return response.data.message.data

    return si_falla_text;
}

const getResponseErrorAPI = (response, si_falla_text) => {

    if ((Array.isArray(response?.data) && response.data?.length > 0))
        return Modal.info({
            title: 'A error ocurred in process',
            content: (
                response.data.map(error => <div>
                    <p>{error.message}</p>
                </div>)

            ),
            onOk() { },
        })

    if (Array.isArray(response?.data?.errores) && response.data?.errores.length > 0)
        return Modal.info({
            title: 'A error ocurred in process',
            content: (
                response.data.errores.map(error => <div>
                    <p>{error.message}</p>
                </div>)

            ),
            onOk() { },
        })

    return si_falla_text;
}

const renderImage = (id, images, model) => {
    if (images && images.length > 0)
        return `${process.env.REACT_APP_WEB_SERVICE}/${model}/${id}/?imagen=${images[0]?.file}`
    return `${process.env.REACT_APP_WEB_SERVICE}/images/default.jpg`
}

const RenderSlot = (value) => {
    switch (value) {
        case 1:
            return "9:00 am - 11:00 am"
        case 2:
            return "11:00 am - 2:00pm"
        case 3:
            return "03:00 pm - 7:00pm"
        default:
            return 'N/A'
            break;
    }
}


const tiposServiciosVenta = (servicios) => {
    let limpieza = servicios.find( e => e.tipo === 0 )
    let reparacion = servicios.find( e => e.tipo === 1 )

    return <>
        {/* {limpieza ? <Tag color="yellow">Cleanning</Tag> : null} */}
        {reparacion ? <Tag color="red">Repair</Tag> : null}
    </>

}

const paymentStatus = (square_payment) => {
    

    return <>
        {square_payment ? <Tag color="green">Paid</Tag> : <Tag color="orange">Pending</Tag>}
    </>

}


export {
    renderEstatusCliente,
    renderEstatusQuotation,
    renderEstatusVenta,
    getResponseError,
    getResponseErrorAPI,
    renderImage,
    MoneyFormat,
    FolioFormat,
    RenderSlot,
    renderSource,
    tiposServiciosVenta,
    paymentStatus
}