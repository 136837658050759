
import React, { Component } from 'react'
import { Form, Select, message, Spin, Card, Button, Row, Col, Result, Typography, Empty } from 'antd';
import { getResponseError } from '../../../Utils';

import axios from "axios";
const { Text, Title } = Typography

/**
 *
 *
 * @export
 * @class StepCost
 * @extends {Component}
 */
export default class StepCost extends Component {
    constructor(props) {
        super(props)
        this.state = {
            cost: [],
            loading: true

        }
    }
    componentDidMount() {
        this.get()
    }

    get = () => {
        axios.get('/track/rate', {
            params: {
                cotizacion_id: this.props.cotizacion_id,
                carrier_id: this.props.data.carrier.carrier_id,
                service_code: this.props.data.services.service_code,
                package: this.props.data.package,
            }
        })
            .then(({ data }) => {
                console.log(data)
                console.log('this.props.data.label.service_code', this.props.data.carrier.services_code)
                let shipment_cost = data.find(rate => rate.services_code == this.props.data.label.services_code)
                console.log('shipment_cost', shipment_cost)
                if (Object.keys(shipment_cost).length == 0)
                    this.props.setDisabled(true)
                this.setState({ cost: [shipment_cost] })

            })
            .catch(error => {
                console.log('error', error)
            })
            .finally(() => this.setState({ loading: false }))
    }

    render() {
        const { cost } = this.state;

        return (
            <Spin spinning={this.state.loading}>
                <Row justify={"center"}>
                    <Col span={24}>
                        <Title level={4}>The cost displayed is merely an estimate and not the final price for the service requested</Title>
                    </Col>
                    {cost.length > 0 ? cost?.map(rate =>
                        <Col span={8}>
                            <Card title={rate?.service_type}>
                                <Row align={"middle"} justify={"start"}>
                                    <Col span={6}> <Text strong className='text-uppercase'>Shipping Amount:</Text></Col>
                                    <Col span={18}><Text>{rate?.shipping_amount?.amount}</Text></Col>
                                </Row>
                                <Row>
                                    <Col span={6}> <Text strong className='text-uppercase'>Insurance Amount:</Text></Col>
                                    <Col span={18}><Text>{rate?.insurance_amount?.amount}</Text></Col>
                                </Row>
                                <Row>
                                    <Col span={6}> <Text strong className='text-uppercase'>Confirmation Amount:</Text></Col>
                                    <Col span={18}><Text>{rate?.confirmation_amount?.amount}</Text></Col>
                                </Row>
                                <Row>
                                    <Col span={6}> <Text strong className='text-uppercase'>Other Amount:</Text></Col>
                                    <Col span={18}><Text>{rate?.other_amount?.amount}</Text></Col>
                                </Row>
                            </Card >
                        </Col>
                    ) : <Empty description={
                        <span>
                            No costs found for this service code, please select another
                        </span>
                    }></Empty>}
                </Row>
            </Spin>

        )
    }
}