import React, { Component } from "react";
import { Row, Col, Modal, Form, Input, message, Spin, Button, Typography, Switch, InputNumber } from 'antd';
import axios from "axios"

//componentes
import { getResponseError } from "../../Utils";
import { ImagesUploader } from "../../Widgets/Uploaders";
import SelectSizes from "../../Widgets/Inputs/SelectSizes"
import ColorPicker from "../../Widgets/ColorPicker"

const { Text } = Typography


/**
 * @class ModalProductos
 * @description Modal para el CRUD de Productos
 */
class ModalProductos extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,

        }
    }

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token')
        if (this.props.variante_id) {
            this.getVariant()
        }

    }

    formRef = React.createRef();

    /**
    * @method getVariant
    * @description Obtiene la variante
    */
    getVariant = () => {
        this.setState({ loading: true })
        axios.get('/variante/' + this.props.variante_id)
            .then(({ data }) => {
                this.formRef.current.setFieldsValue({
                    ...data,
                    imagenes: data.imagenes ? data.imagenes.map(f => ({
                        uid: f.file,
                        name: f.name,
                        status: 'done',
                        fromDB: true,
                        url: axios.defaults.baseURL + "/variante/" + data._id + "?imagen=" + f.file + "&Authorization=" + sessionStorage.getItem("token")
                    })) : null,
                })
            }).catch(error => {
                message.error('Error obtaining the variant')
            }).finally(() => this.setState({ loading: false }))
    }


    /**
    * @method addVariant
    * @description Añade un nuevo registro de variante
    */
    addVariant = (values) => {

        this.setState({ loading: true })
        axios.post('/variantes', values, {
            headers: {
                "Content-Type": 'multipart/form-data'
            }
        })
            .then(response => {
                message.success('Variant Created!')
                this.props.onClose(true)
            }).catch(error => {
                console.log("error", error);
                message.error(getResponseError(error.response, 'Error creating Variant'))
            }).finally(() => this.setState({ loading: false }))
    }

    /**
    * @method updateVariante
    * @description Actualiza la informacion de una variante
    */
    updateVariante = (values) => {

        this.setState({ loading: true })
        axios.put('/variantes', values, {
            headers: {
                "Content-Type": 'multipart/form-data'
            }
        })
            .then(response => {
                message.success('Updated Variant!')
                this.props.onClose(true)
            }).catch(error => {
                console.log("error", error);
                message.error(getResponseError(error.response, 'Error updating the Variant'))
            }).finally(() => this.setState({ loading: false }))
    }

    /**
   * @method onFinish
   * @description Se ejecuta al hacer submit al formulario
   */
    onFinish = (values) => {

        const formData = new FormData()

        formData.appendMultiple({
            ...values,
            variante_id: this.props.variante_id,
            producto_id: this.props.producto_id,
        })



        if (this.props.variante_id) {
            this.updateVariante(formData)
        } else {
            this.addVariant(formData)
        }
    }

    render() {
        return (
            <Spin spinning={this.state.loading}>
                <Form
                    id="form-producto"
                    layout={"vertical"}
                    onFinish={this.onFinish}
                    ref={this.formRef}
                    className="mt-3"
                    initialValues={{
                        activo: true
                    }}
                >
                    <Row justify="center" className="center">
                        <Col span={20}>
                            <Form.Item
                                name="nombre"
                                label="Name"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Name is required',
                                    }
                                ]}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                name="sku_variante"
                                label="SKU variant"
                                rules={[
                                    {
                                        required: true,
                                        message: 'SKU is required',
                                    }
                                ]}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                name="descripcion"
                                label="Description"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Description is required',
                                    }
                                ]}
                            >
                                <Input.TextArea rows={4} />
                            </Form.Item>

                            <Row gutter={[16, 16]}>
                                <Col xs={24} md={12}>
                                    <Form.Item
                                        name="size_id"
                                        label="Size"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Size is required',
                                            }
                                        ]}
                                    >
                                        <SelectSizes
                                            dropdownRender={true}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={12}>
                                    <Form.Item
                                        name="precio"
                                        label="Price"
                                    >
                                        <InputNumber
                                            min={0.01}
                                            className="width-100"
                                            formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                            parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={24} md={12}>
                                    <Form.Item
                                        name="color"
                                        label="Color"
                                    >
                                        <ColorPicker />
                                    </Form.Item>
                                </Col>

                                <Col xs={24} md={12}>
                                    <Form.Item
                                        name="activo"
                                        label="Active"
                                        valuePropName="checked"
                                    >
                                        <Switch />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        )
    }


}



export default function Modals(props) {

    const { visible = false, onClose = () => { }, variante_id } = props

    return <Modal
        open={visible}
        onCancel={onClose}
        title={variante_id ? "Edit Variant" : "Create Variant"}
        closable={true}
        destroyOnClose={true}
        zIndex={1000}
        cancelText="Cancel"
        okText="Save"
        okButtonProps={{ form: 'form-producto', key: 'submit', htmlType: 'submit' }}
    >
        <ModalProductos  {...props} />
    </Modal>

}