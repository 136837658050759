import React, { Component } from "react";
import { Row, Col, Modal, Typography, Form, Input, Select, message, Spin, } from 'antd';

import { getResponseError } from "../../Utils";
import axios from "axios"

/**
 *
 *
 * @class ModalSetTrackingNumber
 * @extends {Component}
 */
class ModalSetTrackingNumber extends Component {


    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        }
    }

    formRef = React.createRef();


    /**
     * @memberof ModalSetTrackingNumber
     * @method onFinish
     * @description Se ejecuta al dar enter al formulario
     * 
     */
    onFinish = (values) => {
        axios.put(`/envios/track`, {
            ...values,
            cotizacion_id: this.props.cotizacion_id,
        })
            .then(res => {
                message.success("Tracking Number assigned to quotation")
                this.props.update()
            })
            .catch(error => {
                console.log('error', error.response)
                message.error(getResponseError(error.response, "Error saving tracking number"))
            })
    }

    render() {

        return (
            <Form
                layout="vertical"
                name="form-trackin_number"
                id="form-trackin_number"
                ref={this.formRef}
                onFinish={this.onFinish}

            >
                <Spin spinning={this.state.loading}>
                    <Row gutter={[16, 16]}>
                        <Col xs={24}>
                            <Form.Item
                                label="Tracking Number"
                                name="tracking_number"
                                rules={[{
                                    required: true,
                                    message: 'Enter the tracking Number'
                                }]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                </Spin>
            </Form>
        )
    }
}



export default function (props) {
    const { visible = false, onClose = () => { } } = props

    return <Modal
        open={visible}
        onCancel={onClose}
        title={"Tracking Number to Quotation"}
        destroyOnClose={true}
        cancelText="Cancel"
        okText="Save"
        centered={true}
        okButtonProps={{ form: 'form-trackin_number', key: 'submit', htmlType: 'submit' }}
    >
        <ModalSetTrackingNumber {...props} />
    </Modal>

}