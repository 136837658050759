
import React, { Component } from 'react'
import { Form, Input, Button, Row, Col, message, Spin, Typography, List, Divider} from 'antd';
import axios from "axios";

import CardItem from "./CartItem"
import { PaymentForm, CreditCard } from 'react-square-web-payments-sdk';
import { SetCarrito, Carrito } from '../../../Hooks/Carrito';

const { Title } = Typography;


export default class StepPayment extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            carrito: {
                detalles: []
            },
        }
    }

    static contextType = Carrito;

    componentDidMount() {
        this.getCarrito()
    }
    componentDidUpdate(){

    }


    getCarrito = () => {
        const carrito = this.context;
        this.setState({loading: true})
        axios.get('/carrito/' + carrito._id, {
        }).then(({ data }) => {
            this.setState({
                carrito: data
            })
            this.props.setCart(data)
        }).catch((error) => {
            console.log(error)
        }).finally(()=>this.setState({loading: false}))
    }

    /**
     * @methodOf StepPayment
     * @function payment
     * @description Manda el id de la orden y el token de la tarjeta, para realizar el pago de la orden
     */
    payment = (token, verifiedBuyer) => {
        const carrito = this.context;
        this.setState({loading: true})
        axios.post('/square/payment',{
            token,
            orden_id: carrito._id,
        }).then(response => {
            message.success("Payment accepted")
            this.props.setCart({})
            this.props.setCookie("cart", null)
            this.props.close()
        }).catch(error => {
            console.log("error", error);
            message.error(error?.response?.data?.message ?? "Error processing payment")
        }).finally(()=>this.setState({loading: false}))
    }

    render() {
        return (

            <Spin spinning={this.state.loading}>
                <Row>
                    <Col span={24}>
                        <Title level={3} className="mb-2">Enter your card information</Title>
                    </Col>
                </Row>
                <List
                    loading={this.state.loading}
                    className="cart"
                    dataSource={Array.isArray(this.state?.carrito?.detalles) ? this.state?.carrito?.detalles : []}
                    locale={{ emptyText: "The cart is empty" }}
                    header={
                        <>
                            <Row>
                                <Col span={15} className="">Product/Services</Col>
                                <Col span={5}  className="">Cost</Col>
                                <Col span={2} className="center ">Qty</Col>
                                <Col span={4}  className=""></Col>
                            </Row>
                        </>
                    }
                    footer={
                        <>
                            <Row align={"center"} >
                                <Col span={6} className="cart-resume-text">Subtotal</Col>
                                <Col span={18} className="cart-resume-amount">{this.state?.carrito?.subtotal?.toMoney(true)}</Col>
                            </Row>
                            <Row align={"center"} >
                                <Col span={6} className="cart-resume-text">Taxes</Col>
                                <Col span={18} className="cart-resume-amount">{this.state?.carrito?.tax?.toMoney(true)}</Col>
                            </Row>
                            <Row align={"center"} >
                                <Col span={6} className="cart-resume-text">Total</Col>
                                <Col span={18} className="cart-resume-amount">{this.state?.carrito?.total?.toMoney(true)}</Col>
                            </Row>
                        </>
                    }
                    renderItem={item => <List.Item>
                        <CardItem item={item} cantidad={item.cantidad} delete={false} />
                    </List.Item>}
                />
                <Divider/>
                <PaymentForm
                    applicationId={process.env.REACT_APP_SQUARE_APPID}
                    cardTokenizeResponseReceived={this.payment}
                    locationId={process.env.REACT_APP_SQUARE_LOCATIONID}
                >
                    <CreditCard 
                        buttonProps={{
                            css: {
                                backgroundColor: "#426cb4",
                                fontSize: "14px",
                                color: "#fff",
                                "&:hover": {
                                    backgroundColor: "#9dbcdd",
                                },
                            },
                        }}
                    />
                </PaymentForm>
            </Spin>
        )
    }
}