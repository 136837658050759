import React, { Component } from "react";
import { Row, Col, Form, Input, message, Divider } from "antd";
import axios from "axios";

import { getResponseError } from "../../Utils";
import Busqueda from "../../Widgets/Busqueda";

/**
 * @class ModalClientes
 * @description Modal para el CRUD de Clientes
 */
export default class ModalClientes extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  formRef = React.createRef();

  render() {
    let { name, restField, remove, address } = this.props;

    return (
      <Row gutter={[8, 8]}>
        <Col span={24}>
          <Form.Item
            {...restField}
            name={[name, "nombre"]}
            rules={[{ required: true, message: "Name is required" }]}
          >
            <Input placeholder="Name" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            {...restField}
            name={[name, "codigo_postal"]}
            rules={[{ required: true, message: "Zip Code is required" }]}
          >
            <Input placeholder="Zip Code" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item {...restField} name={[name, "localizacion"]}>
            <Busqueda address={address} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item {...restField} name={[name, "notas"]}>
            <Input.TextArea rows={3} placeholder="Specifications" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item {...restField} name={[name, "especificaciones"]}>
            <Input.TextArea rows={2} placeholder="Unit Number" />
          </Form.Item>
        </Col>
        <Divider />
      </Row>
    );
  }
}
