import React, { Component } from "react";
import { Row, Col, Button, Modal, Typography, Form, Input, ColorPicker, Select, message, Upload, Checkbox, Spin, InputNumber } from 'antd';
import { generate, green, presetPalettes, red, blue } from '@ant-design/colors';
import { PlusOutlined, DeleteOutlined, LoadingOutlined } from '@ant-design/icons';

import axios from "axios"
import SelectSizes from "../../Widgets/Inputs/SelectSizes";
import SelectServicio from "../../Widgets/Inputs/SelectServicios";

const { Title } = Typography;
const { Option } = Select;


class ModalServicios extends Component {


    constructor(props) {
        super(props);
        this.state = {
            accionModal: '',
            loading: false,
            datosConsultados: false,
            return: false,
            tipo: 1,
            disablePrice: false,
            presets:[]
        }
    }

    


    refModalCompras = React.createRef();

    componentDidMount() {

        if (this.props.detalle) {
            this.refModalCompras.current.setFieldsValue({
                ...this.props.detalle,
                servicio_id: this.props.detalle.servicio_id?._id,
                size_id: this.props.detalle.size_id?._id,
            })
        }   
        
    }

    genPresets = (presets = presetPalettes) =>
            Object.entries(presets).map(([label, colors]) => ({
                label,
                colors,
    }));


    /**
    * @memberof Transacciones
    * @function onFinish
    * @description Añade un nuevo producto a la orden de compra
    */
    onFinish = (values) => {

        if (this.props.detalle) {
            console.log("update")
            this.updateDetalle(values)
        } else if (this.props.venta_id) {
            console.log("add values", values)
            this.addProducto(values)
        }

        
    }

    /**
   * @memberof Transacciones
   * @function updateDetalle
   * @description Añade un nuevo producto a la orden de compra
   */
    updateDetalle = (values) => {
        this.setState({ loading: true })

        let primary = null
        let secondary = null

        if (typeof values?.primary_color?.toHexString === "function") { 
            primary = values.primary_color.toHexString()
        } else {
            primary = values?.primary_color
        }

        if (typeof values?.secondary_color?.toHexString === "function") { 
            secondary = values.secondary_color.toHexString()
        } else {
            secondary = values?.secondary_color
        }

        axios.put('/detalle/update', {
            venta_id: this.props.venta_id,
            from_admin:true,
            detalles: {
                _id: this.props.detalle._id,
                servicio_id: values?.servicio_id,
                precio: values.precio,
                cantidad: parseInt(values.cantidad), //cantidad de el servicio.
                brand: values?.brand,
                model: values?.model,
                size_id: values?.size_id,
                outsole: values?.outsole,
                type_material: values?.type_material,
                primary_color: primary,
                secondary_color: secondary ?? null,
                notes: values?.notes,
                discount: values.discount,
            }
        }).then(response => {
            message.success("Item added")
            this.props.onClose(true)
        }).catch(error => {
            message.error(error?.response?.data?.message ?? "There was an error adding the item")
        }).finally(() => this.setState({ loading: false }))
    }

    /**
   * @memberof Transacciones
   * @function addProducto
   * @description Añade un nuevo producto a la orden de compra
   */
    addProducto = (values) => {
        this.setState({ loading: true })
        axios.post('/carrito', {
            from_admin:true,
            venta_id: this.props.venta_id,
            detalles: {
                servicio_id: values?.servicio_id,
                precio: values.precio,
                cantidad: parseInt(values.cantidad),
                brand: values?.brand,
                model: values?.model,
                size_id: values?.size_id,
                outsole: values?.outsole,
                type_material: values?.type_material,
                notes: values?.notes,
                primary_color: values?.primary_color?.toHexString(),
                secondary_color: values.secondary_color ? values.secondary_color.toHexString() : null,
                discount: values.discount,
            }
        }).then(response => {
            message.success("Item added")
            this.props.onClose(true)
        }).catch(error => {
            console.log("error", error);
            message.error(error?.response?.data?.message ?? "There was an error adding the item")
        }).finally(() => this.setState({ loading: false }))
    }

    /**
     * @memberof ModalServicios
     * @function onSelectServicio
     * @description Al seleccionar un servicio carga el precio en el formulario
     */
    onSelectServicio = (servicio_id) => {
        this.setState({ loading: true })
        axios.get('/servicio/' + servicio_id).then(({data}) => {
            this.setState({
                disablePrice: data.precio ? true : false,
                precio: data.precio,
            })
            this.refModalCompras.current.setFieldsValue({
                precio: data.precio,
                discount: 0
            })
        }).catch(error => {
            message.error("There's been an error loading the service data")
            console.log(error)
        }).finally(() => this.setState({ loading: false }))
    }




    render() {

       const presets = this.genPresets({
            primary: generate("#1677ff"),
            red,
            green,
          });

        return (
            <Form
                layout="vertical"
                name="form-cuenta"
                id="form-cuenta"
                ref={this.refModalCompras}
                onFinish={this.onFinish}

            >
                <Spin spinning={this.state.loading}>
                    <Row justify="center" className="mt-2" gutter={[12, 0]}>
                        <Col xs={16}>
                            <Form.Item
                                label="Type of service"
                                name="servicio_id"
                                rules={[{
                                    required: true,
                                    message: "Select the service type"
                                }]}
                            >
                                <SelectServicio 
                                    disabled={this.props.detalle} 
                                    params={{active:true}} 
                                    onSelect={e => this.onSelectServicio(e)}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={8}>
                            <Form.Item
                                label="No. Items"
                                name="cantidad"
                                rules={[{
                                    required: true,
                                    message: "Input quantity"
                                }]}
                            >
                                <Input type="number" min={1}/>
                            </Form.Item>
                        </Col>
                        <Col xs={12}>
                            <Form.Item
                                label="Price"
                                name="precio"
                                rules={[{
                                    required: true,
                                    message: "Please input the price for service"
                                }]}
                            >
                                <InputNumber disabled={this.state.disablePrice} className="w-100" />
                            </Form.Item>
                        </Col>
                        <Col xs={12}>
                            <Form.Item
                                label="Discount"
                                name="discount"
                            >
                                <InputNumber className="w-100" max={this.state.precio} min={0}/>
                            </Form.Item>
                        </Col>
                        <Col xs={24}>
                            <Form.Item
                                label="Brand"
                                name="brand"
                                /*rules={[{
                                    required: true,
                                    message: "Specify the brand"
                                }]}*/
                            >
                                <Input placeholder="Brand" ></Input>
                            </Form.Item>
                        </Col>
                        <Col xs={24}>
                            <Form.Item
                                label="Model"
                                name="model"
                                /*rules={[{
                                    required: true,
                                    message: "Specify the model"
                                }]}*/
                            >
                                <Input placeholder="Model" ></Input>
                            </Form.Item>
                        </Col>
                        <Col xs={12}>
                            <Form.Item
                                label="Size"
                                name="size_id"
                                rules={[{
                                   // required: true,
                                    message: "Specify the size"
                                }]}
                            >
                                <SelectSizes dropdownRender={true} />
                            </Form.Item>
                        </Col>
                        
                        <Col xs={12}>
                            <Form.Item
                                label="Outsole"
                                name="outsole"
                                
                            >
                                <Input placeholder="Outsole" />
                            </Form.Item>
                        </Col>
                        <Col xs={24}>
                            <Form.Item
                                label="Type Material"
                                name="type_material"

                            >
                                <Select>
                                    <Option value={1}>Fabric</Option>
                                    <Option value={2}>Leather</Option>
                                    <Option value={3}>Suede</Option>   
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={12}>
                            <Form.Item
                                label="Primary Color"
                                name="primary_color"
                                /*rules={[{
                                    required: true,
                                    message: "Specify the color"
                                }]}*/
                            >
                                <ColorPicker format="hex" presets={presets}   />
                            </Form.Item>
                        </Col>
                        <Col xs={12}>
                            <Form.Item
                                label="Secondary Color"
                                name="secondary_color"

                            >
                                <ColorPicker format="hex" defaultFormat="hex" allowClear/>
                            </Form.Item>
                        </Col>
                        <Col xs={24}>
                            <Form.Item
                                label="Notes"
                                name="notes"
                            >
                                <Input.TextArea placeholder="Notes" rows={4}/>
                            </Form.Item>
                        </Col>

                    </Row>
                </Spin>
            </Form>
        )
    }
}



export default function (props) {
    const { visible = false, onClose = () => { }, detalle_id } = props

    return <Modal
        open={visible}
        onCancel={onClose}
        title={detalle_id ? "Edit item" : "Add item"}
        destroyOnClose={true}
        cancelText="Cancelar"
        okText="Guardar"
        okButtonProps={{ form: 'form-cuenta', key: 'submit', htmlType: 'submit' }}
    >
        <ModalServicios {...props} />
    </Modal>

}