
import React, { Component, useContext } from 'react'
import { Form, Input, Button, Row, Col, message, Spin, Typography, Modal} from 'antd';
import axios from "axios";

import { User, SetUser } from '../../../Hooks/Logged';

const { Title, Text } = Typography;



class FormLogin extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false
        }
    }


    componentDidMount() {
    }

    /**
	 * @memberof Login
	 *
	 * @method handleSubmit
	 * @description  Envia los datos del formulario al Servidor
	 *
	 * @param values (array)
	 * Contiene los campos del formulario para registrar al usuario
	 *
	 * @returns response (array)
	 **/
	handleSubmit = (values) => {
		this.setState({ loading: true })
		axios.post('/login', {
			email: values.email,
			password: values.password
		})
			.then(({ data, headers }) => {
				axios.defaults.headers.post["Authorization"] = headers.authorization;
				sessionStorage.setItem('token', headers.authorization);
				axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

				this.props.setUser(data.user);
				this.goToNextPage()
			})
			.catch((error) => {
				console.log("error", error);

				Modal.error({ title: "Incorrect Credentials" })

				this.setState({ loading: false });
			})
	};


    /**
     * @memberof Cart
     * @method goToNextPage
     * @description indica a que step tiene que ir
     */
    goToNextPage = () => {
        let page = 1

        //veriicamos si tiene productos en el carrito
        let productos = false
        for(let detalle of this.props.cart?.detalles){
            if(detalle.variante_id) productos = true
        }

        if(productos) page = 1
        
        this.props.next(page)
    }

    render() {
        return (
            <Spin spinning={this.state.loading}>
				<Form onFinish={this.handleSubmit} layout={"vertical"} requiredMark={false} className='login-form'>
					<Title level={3} className="h3-title">¡Log in to continue!</Title>
					<Form.Item
						className='input-email'
						name="email"
						label="Email"
						rules={[
							{ required: true, message: 'Please, enter your email' },
							{
								type: 'email',
								message: 'Email is not valid',
							},
						]}>
						<Input placeholder="example@mail.com" size="large" className="input-login" />
					</Form.Item>
					<Form.Item
						label="Password"
						name="password"
						rules={[{ required: true, message: 'Enter your password' }]}>
						<Input.Password placeholder="********" size="large" />
					</Form.Item>
					<Form.Item>
						<Button type="primary" htmlType="submit" block size="large" >Log In</Button>
					</Form.Item>
					
				</Form>
            </Spin>
        )
    }
}


export default function Vista(props) {
    const setUser = useContext(SetUser)

    return <FormLogin {...props} setUser={setUser} />

}