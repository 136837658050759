import React, { useState, createRef } from 'react';
import { Drawer, Button, Space } from 'antd';



import TableCompra from "./TableCompra.js";

/**
 *
 *
 * @memberof Compras
 * @description Retorna la tabla de las Ordenes de Compra.
 * @property onClose Metodo que cierra la tabla
 * @property visible Booleano, visualiza la tabla.
 */
export default function (props) {

    const { onClose, visible } = props;

    
    /**
     * @state Folio de la Orden de Compra
     */
    const [folio, setFolio] = useState("");

    let refTableOrdenCompra = createRef()

    /**
     * @method saveValues 
     * @description Guardamos los valores de la Orden de Compra, hacemos el submit
     */
    const saveValues = async () => {
        await refTableOrdenCompra.current.formTableOrdenCompra.current.submit()
    }

    return <Drawer
        title={"Modify sale " + folio}
        placement={"bottom"}
        height="80vh"
        onClose={onClose}
        open={visible}
        destroyOnClose={true}
        extra={
            <Space>
                <Button type='danger' onClick={onClose}>Cancel</Button>
                <Button type="primary" onClick={saveValues}>
                    Save changes
                </Button>
            </Space>
        }
    >
        <TableCompra
            ref={refTableOrdenCompra}
            {...props}
            folio={folio}
            setFolio={setFolio}
            venta_id={props.venta_id}
            onSave={onClose}
            onClose={onClose}
        />
    </Drawer>
}